import { Navigate, Outlet, useNavigate } from "react-router-dom";
import React, { Component } from "react";
import Login from "./components/Login";
import { ReactSession } from 'react-client-session';

import axios from "axios";

const useAuth = () => {

    const userid = ReactSession.get("userid");
    var logged = false;

    if(userid) {
        logged = true;
    }

    const user = {loggedIn: logged};
    return user && user.loggedIn;
}

const ProtectedRoutes = () => {
    const navigate = useNavigate();
    const isAuth = useAuth();

    return isAuth ? <Outlet/> : <Navigate to="/" />;
}

export default ProtectedRoutes;