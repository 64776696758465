import React, { Component, useState, useEffect, useRef } from "react";
import axios from "axios";
import logo from "../assets/img/auto-logo.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ReactSession } from "react-client-session";
import { useNavigate } from "react-router-dom";
import base_url from "../constants/global";
import { createGlobalState } from "react-hooks-global-state";
import Dropdown from "react-bootstrap/Dropdown";
import enFlag from "../assets/img/united-states.png";
import frFlag from "../assets/img/france.png";

import Snackbar from "./Snackbar";

import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import Language from "./Language";

const { setGlobalState, useGlobalState } = createGlobalState({
  showpassword: false,
  snackbarmsg: "",
  snackbartype: "success",
});

function Header() {
  const { t, i18n } = useTranslation();

  var username = ReactSession.get("username");

  const [isOpen, setisOpen] = useState(false);
  const [isOpen2, setisOpen2] = useState(false);
  const [isOpen3, setisOpen3] = useState(false);
  const [isOpen4, setisOpen4] = useState(false);
  const [isOpen5, setisOpen5] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalHide, setModalHide] = useState(false);

  const logout = () => {
    ReactSession.remove("userid");
    ReactSession.remove("username");
    ReactSession.remove("usertoken");
  };

  const [showModalPassword, setShowModalPassword] =
    useGlobalState("showpassword");
  const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
  const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");

  const toggleModal = () => {
    setShowModalPassword(true);
  };

  const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;
  const menuClass2 = `dropdown-menu${isOpen2 ? " show" : ""}`;
  const menuClass3 = `dropdown-menu${isOpen3 ? " show" : ""}`;
  const menuClass4 = `dropdown-menu${isOpen4 ? " show" : ""}`;
  const menuClass5 = `dropdown-menu${isOpen5 ? " show" : ""}`;

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const PasswordModal = (props) => {
    const username = ReactSession.get("username");

    const [newpass, setNewPass] = useState("");
    const [oldpass, setOldPass] = useState("");
    const [confirmpass, setConfirmPass] = useState("");

    const [messageOldPass, setOldPassMessage] = useState("");
    const [messageNewPass, setNewPassMessage] = useState("");
    const [messageConfirmPass, setConfirmPassMessage] = useState("");

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");

    const myRefnameU = useRef(null);
    const myRefname = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    const handleSubmitPassword = (e) => {
      e.preventDefault();
      const userid = ReactSession.get("userid");
      const usertoken = ReactSession.get("usertoken");

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/update_password",
        data: {
          token: usertoken,
          userid: userid,
          oldpassword: oldpass,
          newpassword: newpass,
          newpassword2: confirmpass,
        },
      })
        .then((response) => {
          if (response.data.Validation == "TRUE") {
            setOldPassMessage("");
            setNewPassMessage("");
            setConfirmPassMessage("");
            myRefnameU.current.click();

            setSnackbarMessage(response.data.Result);

            if (response.data.Inserted == "TRUE") {
              //setSnackbarType("success");
              notifySuccess(response.data.Result);
            } else {
              //setSnackbarType("fail");
              notifySuccess(response.data.Result);
            }
            //snackbarRef.current.show();
            //alert(response.data.Result);
          } else {
            setOldPassMessage(response.data.old_password);
            setNewPassMessage(response.data.new_password);
            setConfirmPassMessage(response.data.confirm_password);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitPassword}>
            <div className="form-group">
              <label>
                Mot de passe<span className="text-danger pl-3">*</span>
              </label>
              <input
                type="password"
                onChange={(e) => setOldPass(e.target.value)}
                className="form-control"
                placeholder="Entrer le mot de passe"
              />
              <div id="old-password-error-u" className="text-danger pt-2">
                {messageOldPass}
              </div>
            </div>

            <div className="form-group">
              <label>
                Nouveau mot de passe<span className="text-danger pl-3">*</span>
              </label>
              <input
                type="password"
                onChange={(e) => setNewPass(e.target.value)}
                className="form-control"
                placeholder="Entrer le nouveau mot de passe"
              />
              <div id="new-password-error-u" className="text-danger pt-2">
                {messageNewPass}
              </div>
            </div>

            <div className="form-group">
              <label>
                Confirmer le nouveau mot de passe
                <span className="text-danger pl-3">*</span>
              </label>
              <input
                type="password"
                onChange={(e) => setConfirmPass(e.target.value)}
                className="form-control"
                placeholder="Confirmer le nouveau de passe"
              />
              <div id="new-password2-error-u" className="text-danger pt-2">
                {messageConfirmPass}
              </div>
            </div>

            <button type="submit" ref={myRefname} hidden className="btn">
              Submit
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button ref={myRefnameU} onClick={props.onHide} variant="default">
            Cancel
          </Button>
          <Button type="submit" onClick={handleClick} variant="danger">
            Change password
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SnackbarType = {
    success: "success",
    fail: "fail",
  };

  const snackbarRef = useRef(null);

  const [permissions, setPermissions] = useState({
    permission: {
      per1: false,
      per2: false,
      per3: false,
      per4: false,
      per5: false,
      per6: false,
      per7: false,
      per8: false,
      per9: false,
      per10: false,
      per11: false,
      per12: false,
      per13: false,
      per14: false,
      per15: false,
      per16: false,
      per17: false,
      per18: false,
      per19: false,
      per20: false,
      per21: false,
      per22: false,
      per23: false,
      per24: false,
      per25: false,
      per26: false,
      per27: false,
      per28: false,
      per29: false,
      per30: false,
      per31: false,
      per32: false,
      per33: false,
      per34: false,
      per35: false,
      per36: false,
    },
  });

  const [permissiondata, setPermissiondata] = useState("");
  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");

  const loadPermissionData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/UserPermissions",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        setPermissiondata(response.data);

        var prevPerm = permissions;

        var json = response.data;

        Object.keys(json).forEach(function (key) {
          if (json[key].usrper_per_id == 1) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per1 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 2) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per2 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 3) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per3 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 4) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per4 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 5) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per5 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 6) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per6 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 7) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per7 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 8) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per8 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 9) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per9 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 10) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per10 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 11) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per11 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 12) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per12 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 13) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per13 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 14) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per14 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 15) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per15 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 16) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per16 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 17) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per17 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 18) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per18 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 19) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per19 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 20) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per20 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 21) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per21 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 22) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per22 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 23) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per23 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 24) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per24 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 25) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per25 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 26) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per26 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 27) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per27 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 28) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per28 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 29) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per29 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 30) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per30 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 31) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per31 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 32) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per32 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 33) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per33 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 34) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per34 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 35) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per35 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 36) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per36 = true;
              setPermissions(prevPerm);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
    //window.location.reload(false);
  }

  useEffect(() => {
    loadPermissionData();
    handleCookie(cookies.i18next);
  }, []);

  if (username != undefined) {
    return (
      <div
        id="kt_header"
        className="header header-fixed bg-white text-dark d-print-none"
      >
        <Toaster position="top-right" reverseOrder={false} />

        <Snackbar
          ref={snackbarRef}
          message={snackbarmessage}
          type={snackbartype}
          show={false}
        />

        <div className=" container  d-flex align-items-stretch justify-content-between py-3">
          <div className="d-flex align-items-stretch mr-3">
            <div className="header-logo">
              <a href={"/home"}>
                <img
                  alt="Logo"
                  src={logo}
                  className="logo-default max-h-40px"
                />
                <img alt="Logo" src={logo} className="logo-sticky max-h-40px" />
              </a>
            </div>

            <div
              className="header-menu-wrapper header-menu-wrapper-left"
              id="kt_header_menu_wrapper"
            >
              <div
                id="kt_header_menu"
                className="header-menu header-menu-left header-menu-mobile  header-menu-layout-default "
              >
                <ul className="menu-nav">
                  <li className="menu-item menu-item-rel">
                    <a href="/home" className="menu-link">
                      <span className="text-dark-75 font-weight-bolder">
                        {t("Header.home")}
                      </span>
                      <i className="menu-arrow pb-2"></i>
                    </a>
                  </li>

                  {permissions.permission.per22 ? (
                    <li className="menu-item menu-item-rel">
                      <a href="/maintenance" className="menu-link w-110px">
                        <span className="text-dark-75 font-weight-bolder">
                          {t("Header.maintenance")}
                        </span>
                      </a>
                    </li>
                  ) : null}

                  <li className="menu-item menu-item-rel">
                    <a href="/follow-up" className="menu-link w-110px">
                      <span className="text-dark-75 font-weight-bolder">
                        {/* {t("Header.maintenance")} */}
                        Follow up
                      </span>
                    </a>
                  </li>

                  {permissions.permission.per14 ||
                  permissions.permission.per17 ? (
                    <li className="menu-item menu-item-rel pt-6">
                      <div className="menu-link">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="dropdown-basic"
                            className="w-55px h-40px text-white pt-0 pb-0"
                          >
                            <a className="menu-link menu-toggle dropdownEl">
                              <span className="text-dark-75 font-weight-bolder dropdownEl">
                                {t("Header.Setting")}
                              </span>
                            </a>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-180px">
                            {permissions.permission.per14 ? (
                              <Dropdown.Item
                                href="/rooms"
                                className="navi-link border-bottom"
                              >
                                <span className="symbol symbol-20 mr-1"></span>
                                <span className="navi-text font-weight-bolder">
                                  {t("Header.room")}
                                </span>
                              </Dropdown.Item>
                            ) : null}

                            {permissions.permission.per17 ? (
                              <Dropdown.Item
                                href="/sites"
                                className="dropdown-item border-bottom"
                              >
                                <span className="symbol symbol-20 mr-1"></span>
                                <span className="navi-text font-weight-bolder">
                                  {t("Header.site")}
                                </span>
                              </Dropdown.Item>
                            ) : null}

                            {permissions.permission.per14 ? (
                              <Dropdown.Item
                                href="/equipment"
                                className="navi-link border-bottom"
                              >
                                <span className="symbol symbol-20 mr-1"></span>
                                <span className="navi-text font-weight-bolder">
                                  {t("Header.equipment")}
                                </span>
                              </Dropdown.Item>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                  ) : null}

                  {permissions.permission.per34 ? (
                    <li className="menu-item menu-item-rel pt-6">
                      <div className="menu-link">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="dropdown-basic"
                            className="w-55px h-40px text-white pt-0 pb-0"
                          >
                            <a className="menu-link menu-toggle dropdownEl">
                              <span className="text-dark-75 font-weight-bolder dropdownEl">
                                {t("Header.administrator")}
                              </span>
                            </a>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-180px">
                            <Dropdown.Item href="/users" className="navi-link">
                              <span className="symbol symbol-20 mr-1"></span>
                              <span className="navi-text font-weight-bolder">
                                {t("Header.users")}
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>

          <div className="topbar">
            <div className="dropdown mr-8 pt-2">
              <div className="menu-link">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dropdown-basic"
                    className="w-55px h-40px text-white pt-0 pb-0"
                  >
                    <div
                      className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
                      id="open-notification"
                    >
                      <i className="far fa-bell text-dark-75 mr-2"></i>
                      <span className="label label-rounded label-danger">
                        <div id="unreadnot">0</div>
                      </span>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-300px">
                    <Dropdown.Item href="#">
                      <form className="p-0">
                        <div className="d-flex align-items-center pt-0 pb-4 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                          <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-15 font-weight-bolder w-200px">
                            {t("Header.notification")}
                          </div>
                        </div>

                        <div className="scroll scroll-push text-center h-100px">
                          <div id="notificationcontent" className="pt-11"></div>
                        </div>

                        <div className="pb-0 pt-4 font-weight-bolder border-top">
                          <a
                            href=""
                            className="fs-13 text-dark-75 text-hover-primary align-items-center"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              {t("Header.allNotification")}
                            </div>
                          </a>
                        </div>
                      </form>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="dropdown pt-2">
              <a className="menu-link">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dropdown-basic"
                    className="w-auto h-40px text-white pt-0 pb-0"
                  >
                    <div
                      className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
                      id="dr_change_language"
                    >
                      <span className="symbol bg-light symbol-35">
                        <span className="symbol-label text-dark-75 font-size-h5 font-weight-bold bg-white-o-30">
                          <Language />
                        </span>
                      </span>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-180px">
                    <Dropdown.Item
                      href="#"
                      onClick={() => handleCookie("en")}
                      className="navi-link border-bottom"
                    >
                      <span className="symbol symbol-20 mr-1">
                        <img src={enFlag} width={20} height={20} />
                      </span>
                      <span className="navi-text font-weight-bolder">EN</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => handleCookie("fr")}
                      className="navi-link"
                    >
                      <span className="symbol symbol-20 mr-1">
                        <img src={frFlag} width={20} height={20} />
                      </span>
                      <span className="navi-text font-weight-bolder">FR</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </a>
            </div>

            <div className="dropdown pt-2">
              {permissions.permission.per34 ? (
                <a className="menu-link">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="dropdown-basic"
                      className="w-auto h-40px text-white pt-0 pb-0"
                    >
                      <span className="text-dark-75 opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
                        {t("Header.hi")}
                      </span>
                      <span className="text-dark-75 opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
                        {username ? username : "DDD"}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-200px">
                      <Dropdown.Item
                        href="#"
                        onClick={() => toggleModal()}
                        className="navi-link border-bottom"
                      >
                        <span className="symbol symbol-20 mr-1"></span>
                        <span className="navi-text font-weight-bolder">
                          {t("Header.changePass")}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href={"/"}
                        onClick={logout}
                        className="navi-link"
                      >
                        <span className="symbol symbol-20 mr-1"></span>
                        <span className="navi-text font-weight-bolder">
                          {t("Header.logout")}
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </a>
              ) : null}
            </div>
          </div>

          {showModalPassword ? (
            <PasswordModal
              show={showModalPassword}
              onHide={() => setShowModalPassword(false)}
            />
          ) : null}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Header;
