
//import $ from 'jquery'; // added
//import Popper from 'popper.js'; // added
import 'bootstrap'; // added
import 'bootstrap/dist/js/bootstrap.js'; // added
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <Suspense fallback={(<div>Loading</div>)}>
      <App />
    </Suspense>
  </CookiesProvider>
);
