import React, { useState, useRef, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ReactSession } from "react-client-session";
import axios from "axios";
import Header_mobile from "./header_mobile";
import Dropdown from "react-bootstrap/Dropdown";
import Header from "./header";
import Footer from "./footer";
import base_url from "../constants/global";
import { createGlobalState } from "react-hooks-global-state";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const { setGlobalState, useGlobalState } = createGlobalState({
  show: false,
  equipmentdata: [],
  snackbarmsg: "",
  snackbartype: "success",
  showsnackbar: false,
});

const notifySuccess = (msg) =>
  toast.success(msg, {
    style: {
      padding: "16px",
      backgroundColor: "rgba(124, 140, 135, 0.70)",
      color: "#FFF",
      maxWidth: "280px",
      textAlign: "left",
      fontSize: "13px",
    },
  });

const notifyError = (msg) =>
  toast.error(msg, {
    style: {
      padding: "16px",
      backgroundColor: "rgba(124, 140, 135, 0.70)",
      color: "#FFF",
      maxWidth: "280px",
      textAlign: "left",
      fontSize: "13px",
    },
  });

function Example(props) {
  const { t, i18n } = useTranslation();
  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");
  const [equipmentnumber, setEquipmentnumber] = useState("");
  const [resident, setResident] = useState("");
  const [site, setSite] = useState("");
  const [equipmentcoid, setEquipmentid] = useState("");
  const [equipmentcode, setEquipmentcode] = useState("");
  const [equipmentdesignation, setEquipmentdesignation] = useState("");
  const [equipmentsite, setEquipmentsite] = useState("");
  const [equipmentmarque, setEquipmentmarque] = useState("");
  const [equipmentpower, setEquipmentpower] = useState("");
  const [equipmentserial_number, setEquipmentserial_number] = useState("");
  const [equipmentcategory, setEquipmentcategory] = useState("");
  const [equipmentaffectation, setEquipmentaffectation] = useState("");

  const [Messageequipmentcode, setMessageEquipmentcode] = useState("");
  const [Messageequipmentdesignation, setMessageEquipmentdesignation] =
    useState("");
  const [Messageequipmentcoid, setMessageEquipmentid] = useState("");
  const [Messageequipmentsite, setMessageEquipmentsite] = useState("");
  const [Messageequipmentmarque, setMessageEquipmentmarque] = useState("");
  const [Messageequipmentpower, setMessageEquipmentpower] = useState("");
  const [Messageequipmentserial_number, setMessageEquipmentserial_number] =
    useState("");
  const [Messageequipmentcategory, setMessageEquipmentcategory] = useState("");
  const [Messageequipmentaffectation, setMessageEquipmentaffectation] =
    useState("");

  const [messageEquipmentnumber, setMessageEquipmentnumber] = useState("");
  const [messageResident, setMessageResident] = useState("");
  const [messageSite, setMessageSite] = useState("");
  const [verif, setVerif] = useState(false);

  const myRefnameU = useRef(null);
  function verif_exist(a) {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/verif_exist",
      data: {
        token: usertoken,
        equipmentcode: a,
      },
    }).then((response) => {
      if (response.data.result === "success") {
        setMessageEquipmentcode("There is a equipment with the same code");
        setVerif(true);
      } else {
        setMessageEquipmentcode("");
        setVerif(false);
      }
    });
  }

  const handleSubmitUser = (e) => {
    let verf = 0;
    if (equipmentcode === "") {
      verf = verf + 1;
      setMessageEquipmentcode("Please enter a valid equipment code");
    } else setMessageEquipmentcode("");

    if (equipmentdesignation === "") {
      verf = verf + 1;
      setMessageEquipmentdesignation("Please enter the equipment description");
    } else setMessageEquipmentdesignation("");

    if (equipmentcategory === "") {
      verf = verf + 1;
      setMessageEquipmentcategory("Please enter the equipment category");
    } else setMessageEquipmentcategory("");

    if (equipmentmarque === "") {
      verf = verf + 1;
      setMessageEquipmentmarque("Please enter the equipment model");
    } else setMessageEquipmentmarque("");

    if (site === "") {
      verf = verf + 1;
      setMessageSite("Please enter the equipment site");
    } else setMessageSite("");

    if (verif === true) {
      verf = verf + 1;
      setMessageEquipmentcode("this code is already used ");
    } else setMessageEquipmentcode("");

    e.preventDefault();

    if (verf === 0) {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/add_equipment",
        data: {
          token: usertoken,
          equipmentcode: equipmentcode,
          description: equipmentdesignation,
          site: site,
          model: equipmentmarque,
          serialnumber: equipmentserial_number,
          category: equipmentcategory,
          affectation: equipmentaffectation,
          power: equipmentpower,
          userid: userid,
        },
      })
        .then((response) => {
          if (response.data.Validation === "FALSE") {
            setMessageEquipmentnumber(response.data.equipment_number);
            setMessageResident(response.data.resident);
            setMessageSite(response.data.site);
          } else {
            setMessageEquipmentnumber("");
            setMessageResident("");
            setMessageSite("");

            if (response.data.Inserted === "TRUE") {
              myRefnameU.current.click();
              loadData();
              notifySuccess(response.data.Result);
            } else {
              notifyError(response.data.Result);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const myRefname = useRef(null);

  const handleClick = () => {
    myRefname.current.click();
  };

  const [sitedata, setSitedata] = useState("");
  const [categoryData, setcategoryData] = useState("");

  const loadcategoryData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetCategory",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setcategoryData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadSiteData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetSites",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setSitedata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ///

  const [equipments, setEquipments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [equipmentsPerPage, setEquipmentsPerPage] = useState(10);

  const loadData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetEquipments",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setEquipments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
  }

  useEffect(() => {
    setLoading(true);
    loadData();
    setLoading(false);
  }, []);

  //Get current users
  const indexOfLastPost = currentPage * equipmentsPerPage;
  const indexOfFirstPost = indexOfLastPost - equipmentsPerPage;
  const currentPosts = equipments.slice(indexOfFirstPost, indexOfLastPost);

  function DropdownMenu({ d }) {
    const handleUpdateModal = () => {
      setShowModal(true);
      setEquipmentData(d);
    };

    const [showModal, setShowModal] = useGlobalState("show");
    const [getequipmentdata, setEquipmentData] =
      useGlobalState("equipmentdata");

    const [permissions, setPermissions] = useState({
      permission: {
        per1: false,
        per2: false,
        per3: false,
        per4: false,
        per5: false,
        per6: false,
        per7: false,
        per8: false,
        per9: false,
        per10: false,
        per11: false,
        per12: false,
        per13: false,
        per14: false,
        per15: false,
        per16: false,
        per17: false,
        per18: false,
        per19: false,
        per20: false,
        per21: false,
        per22: false,
        per23: false,
        per24: false,
        per25: false,
        per26: false,
        per27: false,
        per28: false,
        per29: false,
        per30: false,
        per31: false,
        per32: false,
        per33: false,
        per34: false,
        per35: false,
        per36: false,
      },
    });

    const [permissiondata, setPermissiondata] = useState("");
    const userid = ReactSession.get("userid");

    const loadPermissionData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/UserPermissions",
        data: { token: usertoken, userid: userid },
      })
        .then((response) => {
          setPermissiondata(response.data);

          var prevPerm = permissions;

          var json = response.data;

          Object.keys(json).forEach(function (key) {
            if (json[key].usrper_per_id == 1) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per1 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 2) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per2 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 3) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per3 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 4) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per4 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 5) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per5 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 6) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per6 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 7) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per7 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 8) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per8 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 9) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per9 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 10) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per10 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 11) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per11 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 12) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per12 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 13) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per13 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 14) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per14 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 15) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per15 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 16) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per16 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 17) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per17 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 18) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per18 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 19) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per19 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 20) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per20 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 21) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per21 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 22) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per22 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 23) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per23 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 24) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per24 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 25) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per25 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 26) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per26 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 27) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per27 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 28) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per28 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 29) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per29 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 30) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per30 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 31) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per31 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 32) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per32 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 33) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per33 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 34) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per34 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 35) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per35 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 36) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per36 = true;
                setPermissions(prevPerm);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });

    };
    useEffect(() => {
      loadPermissionData();
    }, []);

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            variant="dropdown-basic"
            className="w-60px text-white pt-0 pb-0 my-0"
          >
            <div className="dropdown dropdown-inline bg-light2 rounded pt-0 pb-0 my-0">
              <a
                type="button"
                className="btn btn-light2 pt-0 pb-0 my-0 btn-icon btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="ki ki-bold-more-hor"></i>
              </a>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {permissions.permission.per15 ? (
              <Dropdown.Item
                href="#"
                onClick={handleUpdateModal}
                className="dropdown-item border-bottom border-top"
              >
                <i className="far fa-edit"></i>{" "}
                <span className="pl-3">{t("Equipment.EditEquipment")}</span>
              </Dropdown.Item>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  function UpdateData(props) {
    const [sdata, setSdata] = useState([]);
    const [sitedata, setSitedata] = useState("");
    const [categoryData, setcategoryData] = useState("");

    const usertoken = ReactSession.get("usertoken");

    const loadcategoryData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetCategory",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setcategoryData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const loadSiteData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetSites",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setSitedata(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const myRefname = useRef(null);
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///

    const [equipmentid, setEquipmentid] = useState("");
    const [equipmentcode, setEquipmentcode] = useState("");
    const [equipmentdesignation, setEquipmentdesignation] = useState("");
    const [equipmentsite, setEquipmentsite] = useState("");
    const [equipmentmarque, setEquipmentmarque] = useState("");
    const [equipmentpower, setEquipmentpower] = useState("");
    const [equipmentserial_number, setEquipmentserial_number] = useState("");
    const [equipmentcategory, setEquipmentcategory] = useState("");

    const [equipmentnumber, setEquipmentnumber] = useState();
    const [equipmentresident, setEquipmentresident] = useState();
    const [site, setSite] = useState("");

    const [messageEquipmentNumber, setMessageEquipmentName] = useState("");
    const [messageEquipmentResident, setMessageEquipmentResident] =
      useState("");
    const [messageSite, setMessageSite] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
      loadSiteData();
      loadcategoryData();
      setEquipmentcode(props.info.eq_code);
      setEquipmentdesignation(props.info.eq_description);
      setSite(props.info.site_id);
      setEquipmentmarque(props.info.eq_model);
      setEquipmentpower(props.info.eq_power);
      setEquipmentserial_number(props.info.eq_serial_number);
      setEquipmentcategory(props.info.eq_category_id);
      setEquipmentid(props.info.eq_id);
    }, []);

    const handleSubmitUEquipment = (e) => {
      e.preventDefault();
      let verf = 0;
      if (equipmentcode === "") {
        verf = verf + 1;
        setMessageEquipmentcode("Please enter a valid equipment code");
      } else setMessageEquipmentcode("");

      if (equipmentdesignation === "") {
        verf = verf + 1;
        setMessageEquipmentdesignation(
          "Please enter the equipment description"
        );
      } else setMessageEquipmentdesignation("");

      if (equipmentcategory === 0) {
        verf = verf + 1;
        setMessageEquipmentcategory("Please enter the equipment category");
      } else setMessageEquipmentcategory("");

      if (equipmentmarque === "") {
        verf = verf + 1;
        setMessageEquipmentmarque("Please enter the equipment model");
      } else setMessageEquipmentmarque("");

      if (site === "") {
        verf = verf + 1;
        setMessageSite("Please enter the equipment site");
      } else setMessageSite("");

      if (verif === true) {
        verf = verf + 1;
        setMessageEquipmentcode("this code is already used ");
      } else setMessageEquipmentcode("");

      e.preventDefault();

      if (verf === 0) {
        axios({
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: base_url + "Users/update_equipment",
          data: {
            token: usertoken,
            equipmentcode: equipmentcode,
            description: equipmentdesignation,
            site: site,
            model: equipmentmarque,
            serialnumber: equipmentserial_number,
            category: equipmentcategory,
            affectation: equipmentaffectation,
            power: equipmentpower,
            eq_id: equipmentid,
            userid: userid,
          },
        })
          .then((response) => {
            if (response.data.Validation === "FALSE") {
              setMessageEquipmentName(response.data.equipment_number);
              setMessageEquipmentResident(response.data.resident);
              setMessageSite(response.data.site);
            } else {
              setMessageEquipmentName("");
              setMessageEquipmentResident("");
              setMessageSite("");

              if (response.data.Inserted === "TRUE") {
                myRefnameU.current.click();
                loadData();
                notifySuccess(response.data.Result);
              } else {
                notifyError(response.data.Result);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Equipment.UpdateEquipment")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitUEquipment}>
              <div className="form-group row mb-3">
                <div className="col-xl-12">
                  <label>
                    Code
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="text"
                    id="equipmentcode"
                    value={equipmentcode}
                    onChange={(e) => {
                      setEquipmentcode(e.target.value);
                    }}
                    disabled
                    name="equipmentcode"
                    className="form-control rounded-0"
                    placeholder=""
                  />
                  <div id="equipmentmnumber-error" className="text-danger pt-2">
                    {Messageequipmentcode}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3">
                <label>
                  Description
                  <span className="text-danger pl-3">*</span>
                </label>
                <input
                  type="text"
                  id="equipmentnumber"
                  value={equipmentdesignation}
                  onChange={(e) => {
                    setEquipmentdesignation(e.target.value);
                  }}
                  name="equipmentnumber"
                  className="form-control rounded-0"
                  placeholder=""
                />
                <div id="site-error" className="text-danger pt-2">
                  {Messageequipmentdesignation}
                </div>
              </div>

              <div className="form-group row mb-3">
                <div className="col-xl-6">
                  <label>
                    Category
                    <span className="text-danger pl-3">*</span>
                  </label>

                  <select
                    id="site"
                    value={equipmentcategory}
                    onChange={(e) => {
                      setEquipmentcategory(e.target.value);
                    }}
                    onClick={loadcategoryData}
                    name="site"
                    className="form-control rounded-0"
                  >
                    <option></option>
                    {categoryData.length
                      ? categoryData.map((d) => (
                        <option key={d.eqq_id} value={d.eqq_id}>
                          {" "}
                          {d.eqq_value}{" "}
                        </option>
                      ))
                      : null}
                  </select>

                  <div id="equipmentmnumber-error" className="text-danger pt-2">
                    {Messageequipmentcategory}
                  </div>
                </div>

                <div className="col-xl-6">
                  <label>
                    Model
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="text"
                    id="resident"
                    value={equipmentmarque}
                    onChange={(e) => {
                      setEquipmentmarque(e.target.value);
                    }}
                    name="resident"
                    className="form-control rounded-0"
                    placeholder=""
                  />
                  <div id="resident-error" className="text-danger pt-2">
                    {Messageequipmentmarque}
                  </div>
                </div>
              </div>

              <div className="form-group row mb-3">
                <div className="col-xl-6">
                  <label>
                    Power
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="text"
                    value={equipmentpower}
                    id="equipmentnumber"
                    onChange={(e) => {
                      setEquipmentpower(e.target.value);
                    }}
                    name="equipmentnumber"
                    className="form-control rounded-0"
                    placeholder=""
                  />
                  <div id="equipmentmnumber-error" className="text-danger pt-2">
                    {Messageequipmentpower}
                  </div>
                </div>

                <div className="col-xl-6">
                  <label>
                    Serial Number
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="text"
                    id="resident"
                    value={equipmentserial_number}
                    onChange={(e) => {
                      setEquipmentserial_number(e.target.value);
                    }}
                    name="resident"
                    className="form-control rounded-0"
                    placeholder=""
                  />
                  <div id="resident-error" className="text-danger pt-2">
                    {Messageequipmentserial_number}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3">
                <label>
                  Site
                  <span className="text-danger pl-3">*</span>
                </label>
                <select
                  id="site"
                  value={site}
                  onChange={(e) => {
                    setSite(e.target.value);
                  }}
                  onClick={loadSiteData}
                  name="site"
                  className="form-control rounded-0"
                >
                  <option></option>
                  {sitedata.length
                    ? sitedata.map((d) => (
                      <option key={d.site_id} value={d.site_id}>
                        {" "}
                        {d.site_name}{" "}
                      </option>
                    ))
                    : null}
                </select>
                <div id="site-error" className="text-danger pt-2">
                  {messageSite}
                </div>
              </div>
              <div className="form-group mb-3">
                <label>
                  Affectation
                  <span className="text-danger pl-3">*</span>
                </label>
                <input
                  type="text"
                  id="resident"
                  value={equipmentaffectation}
                  onChange={(e) => {
                    setEquipmentaffectation(e.target.value);
                  }}
                  name="resident"
                  className="form-control rounded-0"
                  placeholder=""
                />
                <div id="site-error" className="text-danger pt-2">
                  {Messageequipmentaffectation}
                </div>
              </div>
              <button ref={myRefname} hidden className="btn">
                {t("Equipment.Submit")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("Equipment.Cancel")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("Equipment.UpdateEquipment")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const Equipments = ({ data, loading }) => {
    const [showModal, setShowModal] = useGlobalState("show");
    const [getequipmentdata, setEquipmentData] =
      useGlobalState("equipmentdata");

    if (loading) {
      return <h2>Loading...</h2>;
    }

    return (
      <>
        <tbody id="resultdata">
          {currentPosts.length ? (
            currentPosts.map((d, index) => (
              <tr key={index} id='td"+ d[i].equipment_id +"' className="data">
                <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                <td id="tb-equipmentid" hidden>
                  {d.equipment_id}
                </td>
                <td className="text-left">
                  <DropdownMenu key={index} d={d} />
                </td>
                <td id="tb-equipment-number" className="text-left">
                  {d.eq_code}
                </td>
                <td id="tb-equipment-resident" className="text-left">
                  {d.eq_description}
                </td>
                <td id="tb-site" className="text-left">
                  {d.eq_power}
                </td>
                <td id="tb-created-by" className="text-left">
                  {d.eq_model}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.eq_serial_number}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.eqq_value}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.site_name}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.eq_affectation}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8} className="text-center pt-20 fs-16">
                No data found
              </td>
            </tr>
          )}
        </tbody>

        {showModal ? (
          <UpdateData
            show={showModal}
            onHide={() => setShowModal(false)}
            info={getequipmentdata}
          />
        ) : null}
      </>
    );
  };

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage == totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginate(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage == number ? (
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a onClick={() => paginate(number)} href="#" className="">
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a onClick={() => paginate(totalPages)} href="#" className="">
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  ///

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Equipment.AddEquipment")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitUser}>
            <div className="form-group row mb-3">
              <div className="col-xl-12">
                <label>
                  Code
                  <span className="text-danger pl-3">*</span>
                </label>
                <input
                  type="text"
                  id="equipmentcode"
                  onChange={(e) => {
                    setEquipmentcode(e.target.value);
                  }}
                  onBlur={(e) => {
                    verif_exist(e.target.value);
                  }}
                  name="equipmentcode"
                  className="form-control rounded-0"
                  placeholder=""
                />
                <div id="equipmentmnumber-error" className="text-danger pt-2">
                  {Messageequipmentcode}
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label>
                Description
                <span className="text-danger pl-3">*</span>
              </label>
              <input
                type="text"
                id="equipmentnumber"
                onChange={(e) => {
                  setEquipmentdesignation(e.target.value);
                }}
                name="equipmentnumber"
                className="form-control rounded-0"
                placeholder=""
              />
              <div id="site-error" className="text-danger pt-2">
                {Messageequipmentdesignation}
              </div>
            </div>

            <div className="form-group row mb-3">
              <div className="col-xl-6">
                <label>
                  Category
                  <span className="text-danger pl-3">*</span>
                </label>

                <select
                  id="site"
                  onChange={(e) => {
                    setEquipmentcategory(e.target.value);
                  }}
                  onClick={loadcategoryData}
                  name="site"
                  className="form-control rounded-0"
                >
                  <option></option>
                  {categoryData.length
                    ? categoryData.map((d) => (
                      <option key={d.eqq_id} value={d.eqq_id}>
                        {" "}
                        {d.eqq_value}{" "}
                      </option>
                    ))
                    : null}
                </select>

                <div id="equipmentmnumber-error" className="text-danger pt-2">
                  {Messageequipmentcategory}
                </div>
              </div>

              <div className="col-xl-6">
                <label>
                  Model
                  <span className="text-danger pl-3">*</span>
                </label>
                <input
                  type="text"
                  id="resident"
                  onChange={(e) => {
                    setEquipmentmarque(e.target.value);
                  }}
                  name="resident"
                  className="form-control rounded-0"
                  placeholder=""
                />
                <div id="resident-error" className="text-danger pt-2">
                  {Messageequipmentmarque}
                </div>
              </div>
            </div>

            <div className="form-group row mb-3">
              <div className="col-xl-6">
                <label>
                  Power
                  <span className="text-danger pl-3">*</span>
                </label>
                <input
                  type="text"
                  id="equipmentnumber"
                  onChange={(e) => {
                    setEquipmentpower(e.target.value);
                  }}
                  name="equipmentnumber"
                  className="form-control rounded-0"
                  placeholder=""
                />
                <div id="equipmentmnumber-error" className="text-danger pt-2">
                  {Messageequipmentpower}
                </div>
              </div>

              <div className="col-xl-6">
                <label>
                  Serial Number
                  <span className="text-danger pl-3">*</span>
                </label>
                <input
                  type="text"
                  id="resident"
                  onChange={(e) => {
                    setEquipmentserial_number(e.target.value);
                  }}
                  name="resident"
                  className="form-control rounded-0"
                  placeholder=""
                />
                <div id="resident-error" className="text-danger pt-2">
                  {Messageequipmentserial_number}
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label>
                Site
                <span className="text-danger pl-3">*</span>
              </label>
              <select
                id="site"
                onChange={(e) => {
                  setSite(e.target.value);
                }}
                onClick={loadSiteData}
                name="site"
                className="form-control rounded-0"
              >
                <option></option>
                {sitedata.length
                  ? sitedata.map((d) => (
                    <option key={d.site_id} value={d.site_id}>
                      {" "}
                      {d.site_name}{" "}
                    </option>
                  ))
                  : null}
              </select>
              <div id="site-error" className="text-danger pt-2">
                {messageSite}
              </div>
            </div>
            <div className="form-group mb-3">
              <label>
                Affectation
                <span className="text-danger pl-3">*</span>
              </label>
              <input
                type="text"
                id="resident"
                onChange={(e) => {
                  setEquipmentaffectation(e.target.value);
                }}
                name="resident"
                className="form-control rounded-0"
                placeholder=""
              />
              <div id="site-error" className="text-danger pt-2">
                {Messageequipmentaffectation}
              </div>
            </div>
            <button ref={myRefname} hidden className="btn">
              {t("Equipment.Submit")}
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button ref={myRefnameU} onClick={props.onHide} variant="default">
            {t("Equipment.Cancel")}
          </Button>
          <Button type="submit" onClick={handleClick} variant="danger">
            {t("Equipment.AddEquipment")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card-body pb-0">
        <div className="table-responsive">
          <table id="result" className="table min-h-300px">
            <thead>
              <tr>
                <th scope="col" className="w-25px">
                  #
                </th>
                <th scope="col" className="w-25px"></th>

                <th scope="col" className="text-left">
                  Code
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketsubjectModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  Description
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketdescModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>

                <th scope="col" className="text-left">
                  Puissance
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterassignedtoModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>

                <th scope="col" className="text-left">
                  Modele
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  Numéro de Série
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  Catégorie
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  Site
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  Affectation
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
              </tr>
            </thead>

            <Equipments data={equipments} loading={loading} />
          </table>
        </div>

        <div className="card-footer pt-2 pb-0">
          <center>
            <div className="d-flex justify-content-center">
              <Pagination
                postsPerPage={equipmentsPerPage}
                totalPosts={equipments.length}
                paginate={paginate}
              />
            </div>
          </center>
        </div>
      </div>
    </>
  );
}

function Equipments() {
  const [modalShow, setModalShow] = React.useState(false);

  const snackbarRef = useRef(null);

  const [permissions, setPermissions] = useState({
    permission: {
      per1: false,
      per2: false,
      per3: false,
      per4: false,
      per5: false,
      per6: false,
      per7: false,
      per8: false,
      per9: false,
      per10: false,
      per11: false,
      per12: false,
      per13: false,
      per14: false,
      per15: false,
      per16: false,
      per17: false,
      per18: false,
      per19: false,
      per20: false,
      per21: false,
      per22: false,
      per23: false,
      per24: false,
      per25: false,
      per26: false,
      per27: false,
      per28: false,
      per29: false,
      per30: false,
      per31: false,
      per32: false,
      per33: false,
      per34: false,
      per35: false,
      per36: false,
    },
  });

  const [permissiondata, setPermissiondata] = useState("");
  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");

  const loadPermissionData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/UserPermissions",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        setPermissiondata(response.data);

        var prevPerm = permissions;

        var json = response.data;

        Object.keys(json).forEach(function (key) {
          if (json[key].usrper_per_id == 1) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per1 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 2) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per2 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 3) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per3 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 4) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per4 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 5) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per5 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 6) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per6 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 7) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per7 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 8) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per8 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 9) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per9 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 10) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per10 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 11) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per11 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 12) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per12 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 13) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per13 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 14) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per14 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 15) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per15 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 16) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per16 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 17) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per17 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 18) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per18 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 19) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per19 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 20) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per20 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 21) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per21 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 22) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per22 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 23) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per23 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 24) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per24 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 25) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per25 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 26) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per26 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 27) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per27 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 28) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per28 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 29) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per29 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 30) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per30 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 31) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per31 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 32) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per32 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 33) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per33 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 34) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per34 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 35) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per35 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 36) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per36 = true;
              setPermissions(prevPerm);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });

  };
  const { t, i18n } = useTranslation();

  useEffect(() => {
    loadPermissionData();
  }, []);

  return (
    <>
      <Header_mobile />
      <Header />

      <div
        className="d-flex flex-column flex-column-fluid"
        style={{ minHeight: "85vh" }}
      >
        <div className="d-flex flex-column-fluid">
          <div className=" container" id="pageBlocking">
            <div className="d-print-none mt-20"></div>

            <div className="row">
              <div className="col-md-1"></div>

              <div className="col-md-10">
                <div
                  className="card card-custom d-print-none"
                  id="kt_blockui_card"
                >
                  <div className="card-header">
                    <div className="card-title">
                      <h3 className="card-label">
                        {t("Equipment.ListEquipments")}
                      </h3>
                      <button
                        id="btn-refrech"
                        className="btn btn-sm bg-light2 ml-4 w-45px pl-4"
                      >
                        <i className="la la-sync fs-17"></i>
                      </button>
                    </div>
                    <div className="card-toolbar">
                      <button
                        id="clearfilter"
                        className="btn btn-light font-weight-bolder border mr-3"
                      >
                        {t("Equipment.clearFilter")}
                      </button>
                      {permissions.permission.per16 ? (
                        <button
                          onClick={() => setModalShow(true)}
                          id="crticket-btn"
                          className="btn btn-defaut text-white bg-red font-weight-bold"
                          data-toggle="modal"
                          data-target="#addticketModal"
                        >
                          {t("Equipment.AddEquipment")}
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <Example
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>

                <div className="mt-10 d-print-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Equipments;
