import React, {useState, forwardRef, useImperativeHandle, useEffect} from "react";

const Snackbar = forwardRef((props, ref) => {

    const divClass = props.type == "success" ? "bg-success min-h-60px w-260px px-6 py-3 text-white fs-13 rounded" : "bg-danger h-60px w-250px px-6 py-3 text-white fs-13 rounded";

    const [showSnackbar, setShowSnackbar] = useState(false);
    
    const displaySnackbar = showSnackbar ? "d-flex show-snack " : "d-none show-snack ";

    useEffect(() => {      
        //alert(props.show)
        if(props.show == true) {
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
        }
    }, []);

    useImperativeHandle(ref, () => ({
        show() {
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
        }
    }))

    return (
        <>
        <div className={displaySnackbar + divClass} style={{position: "absolute", top: "100px", zIndex: 999, opacity: "0.8", right: "100px", animation: "fadeIn 0.8s"}} >
            <div className="symbol">
                {
                    props.type == "success" ? 
                    <div className="fs-17"><i className="fa fa-check text-white" aria-hidden="true"></i></div> : 
                    <div className="fs-17"><i className="fa fa-times text-white" aria-hidden="true"></i></div>
                }
            </div>
            <div className="message text-left pl-5 pt-1 pb-2">{props.message}</div>
        </div>
        </>
    )
})

export default Snackbar;