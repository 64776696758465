import React, { Component, withRouter, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactSession } from 'react-client-session';
import base_url from "../constants/global";
import logo from '../assets/img/auto-logo-circle.png';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleSubmit = e => {

        e.preventDefault();

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Login/check_user',
            data: { username: username, password: password },
        })
            .then(response => {

                if (response.data.result == "failed") {
                    setMessage(response.data.message);
                } else {
                    setMessage("");
                    ReactSession.set("userid", response.data.userid);
                    ReactSession.set("username", response.data.username);
                    ReactSession.set("userrole", response.data.userrole);
                    ReactSession.set("usertoken", response.data.usertoken);
                    ReactSession.set("userlang", response.data.userlang);

                    navigate('/home');
                }
            })
            .catch(error => {
                console.log(error)
            })
    };

    return (

        <div className="mt-auto bg-white" style={{ minHeight: "100vh", position: "relative" }}>

            <div className="d-flex flex-column w-100 flex-root" style={{ margin: 0, position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                <div className="login login-2 d-flex flex-row-fluid" id="kt_login">
                    <div className="d-flex flex-center flex-row-fluid p-0">
                        <div className="login-form text-center position-relative overflow-hidden">
                            <div className="d-flex flex-center mb-3">
                                <a href="">
                                    <img src={logo} className="max-h-150px" alt="" />
                                </a>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="login-signin pb-5 pt-3">
                                    <div className="mb-3">
                                        <h2>{t("Login.signIn")}</h2>
                                    </div>
                                    <div className="mb-8 mt-5">
                                        <div className="text-muted fs-11-5">{t("Login.msgFill")}</div>
                                    </div>
                                    <div id="login-form">
                                        <div className="form-group mb-5">
                                            <input type="text" name="username" onChange={(e) => { setUsername(e.target.value) }} id="username" className="form-control form-control-solid h-auto rounded-0 border py-4 px-8 fs-14-5 min-w-100" required placeholder={t("Login.plUsername")} autoComplete="off" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input type="password" name="password" onChange={(e) => { setPassword(e.target.value) }} id="password" className="form-control form-control-solid h-auto rounded-0 border py-4 px-8 fs-14-5" placeholder={t("Login.plPassword")} autoComplete="off" required />
                                        </div>
                                        <p id="error-text" className="text-danger pt-0">{message}</p>
                                        <div className="form-group d-flex justify-content-between align-items-center mt-6">
                                            <div className="checkbox-inline mr-20 pl-0">
                                                <label className="checkbox m-0 text-muted min-w-250px">
                                                    <input type="checkbox" name="remember" id="remember-me" />
                                                    <span></span>
                                                    {t("Login.rememberMe")}
                                                </label>
                                            </div>
                                            <span></span>
                                        </div>
                                        <div className="align-items-center mb-8">
                                            <a href="#" id="" className="text-muted text-hover-primary">{t("Login.forgetPass")}</a>
                                        </div>
                                        <button type="submit" id="check-user" className="btn btn-lg text-white rounded-0 btn-block fs-15-5 bg-red" name="btn-connexion">{t("Login.signIn")}</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default Login;