import React, { Component, useState, useEffect, useRef } from "react";
import { ReactSession } from 'react-client-session';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Header from "./header";
import Header_mobile from "./header_mobile";
import Footer from "./footer";
import base_url from "../constants/global";
import { createGlobalState } from "react-hooks-global-state";
import Moment from 'moment';
import { useTranslation } from 'react-i18next';

import Snackbar from "./Snackbar";

const { setGlobalState, useGlobalState } = createGlobalState({
    datefrom: '',
    dateto: '',
});

function Home() {
    const { t, i18n } = useTranslation();

    const [totmaintenances, setTotMaintenances] = useState([]);
    const [totopenmaintenances, setTotOpenMaintenances] = useState([]);
    const [totopenassignedmaintenances, setTotOpenAssignedMaintenances] = useState([]);
    const [totassignedmaintenances, setTotAssignedMaintenances] = useState([]);
    const [timelinedata, setTimelineData] = useState([]);

    const usertoken = ReactSession.get("usertoken");

    const loadData = () => {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Users/GetTotMaintenances',
            data: { userid: userid, token: usertoken },
        })
            .then(response => {
                if (response.data.error) return null
                setTotMaintenances(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const loadData2 = () => {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Users/GetTotOpenMaintenances',
            data: { userid: userid, token: usertoken },
        })
            .then(response => {
                if (response.data.error) return null
                setTotOpenMaintenances(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const loadData3 = () => {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Users/GetTotOpenAssignedMaintenances',
            data: { userid: userid, token: usertoken },
        })
            .then(response => {
                if (response.data.error) return null
                setTotOpenAssignedMaintenances(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const loadData4 = () => {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Users/GetTotAssignedMaintenances',
            data: { userid: userid, token: usertoken },
        })
            .then(response => {
                if (response.data.error) return null
                setTotAssignedMaintenances(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const userid = ReactSession.get("userid");
    const [datefrom, setDateFrom] = useGlobalState("datefrom");
    const [dateto, setDateTo] = useGlobalState("dateto");

    const loadDataTimeline = (datefrom, dateto) => {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Users/GetTickets_from_to',
            data: { token: usertoken, userid: userid, date_from: datefrom, date_to: dateto },
        })
            .then(response => {
                if (response.data.error) return null
                setTimelineData(response.data)
                setDateFrom('');
                setDateTo('');
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        loadData();
        loadData2();
        loadData3();
        loadData4();
        loadDataTimeline(datefrom, dateto);
    }, []);

    const [modalShow, setModalShow] = React.useState(false);

    function FilterDate(props) {

        const myRefname2 = useRef(null);
        const myRefname = useRef(null);

        const handleSubmitTimelineModal = e => {
            e.preventDefault();
            myRefname2.current.click();
            //alert(modaldateFrom + " - " + modaldateto)
            setDateFromGlobal(modaldateFrom);
            setDateToGlobal(modaldateto)
            loadDataTimeline(modaldateFrom, modaldateto);
        };

        const handleClick = () => {
            myRefname.current.click();
            //handleClose();
        }

        const [modaldateFrom, setModalDateFrom] = useState('');
        const [modaldateto, setModalDateTo] = useState('');
        const [dateFromGlobal, setDateFromGlobal] = useGlobalState("datefrom");
        const [datetoGlobal, setDateToGlobal] = useGlobalState("dateto");

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("Home.filterBydate")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitTimelineModal}>
                        <div class="form-group">
                            <label>{t("Home.from")} : <span class="text-danger pl-3">*</span></label>
                            <input type="date" onChange={(e) => setModalDateFrom(e.target.value)} id="filter-date-from" class="form-control" />

                            <label className="mt-5">{t("Home.to")} : <span class="text-danger pl-3">*</span></label>
                            <input type="date" onChange={(e) => setModalDateTo(e.target.value)} id="filter-date-from" class="form-control" />
                        </div>
                        <button ref={myRefname} hidden className="btn">Submit</button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button ref={myRefname2} onClick={props.onHide} variant="default">{t("Home.cancel")}</Button>
                    <Button type="submit" onClick={handleClick} variant="danger">{t("Home.search")}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div>

            <Header_mobile />
            <Header />

            <div className="d-flex flex-column flex-column-fluid" style={{ minHeight: "85vh" }}>
                <div className=" container mt-20 mb-20">

                    <div className="row mb-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">

                            <div className="row" >
                                <div className="col-xl-3 col-md-4" id="tck1">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="pr-2 mb-0 pt-3">
                                                    <h1 id="totTicket-number" className="text-dark-75 text-left">{totmaintenances}</h1>
                                                    <div className="fs-17 text-left h-80px">{t("Home.totalMain")}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-xl-3 col-md-4">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="pr-2 mb-0 pt-3">
                                                    <h1 id="openedTicket-number-assign" className="text-dark-75 text-left">{totopenmaintenances}</h1>
                                                    <div className="fs-17 h-80px text-left">{t("Home.totalOpenMain")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-4">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="pr-2 mb-0 pt-3">
                                                    <h1 id="openedTicket-assign" className="text-dark-75 text-left">{totopenassignedmaintenances}</h1>
                                                    <div className="fs-17 h-80px text-left">{t("Home.openAssignedMain")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-4" id="tck3">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="pr-2 mb-0 pt-3">
                                                    <h1 id="number-tickets-on-wait" className="text-dark-75 text-left">{totassignedmaintenances}</h1>
                                                    <div className="fs-17 h-80px text-left">{t("Home.assignedMain")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>

                    <div className="row">

                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">
                            <div className="card card-custom gutter-b">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label">{t("Home.timeline")}</h3>
                                    </div>
                                    <div className="card-title pt-1">

                                        <FilterDate show={modalShow} onHide={() => setModalShow(false)} />

                                        <button onClick={() => loadDataTimeline('', '')} className="btn btn-sm btn-light border fs-13 mr-8 font-weight-bold">{t("Home.clear")}</button>
                                        <a onClick={() => setModalShow(true)} href={"#"} data-toggle="modal" data-target="#dateModal"><i className="la la-filter fs-22"></i></a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="pb-8 pt-5 pl-5 pr-5">
                                        <div id="timelinecontent">

                                            {
                                                timelinedata.length ?
                                                    timelinedata.map((d, index) =>
                                                        <div key={index} className='timeline timeline-6 pb-2 pt-2'>
                                                            <div className='timeline-item align-items-start'>
                                                                <div className='timeline-label'>{Moment(d.m_created_date).format('HH:mm')}</div>
                                                                <div className='timeline-badge pt-7'>
                                                                    <i className='fa fa-genderless text-danger'></i>
                                                                </div>
                                                                <div className='timeline-content bg-light2 pl-3 ml-1 rounded pt-2 pb-2'>
                                                                    <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                        <div className='mr-2'>
                                                                            <span className='text-dark-75 font-weight-bolder fs-13'>
                                                                                {d.m_subject}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <p className='p-0 mb-3 text-left'>
                                                                        {d.m_description}
                                                                    </p>
                                                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                                                        <div className='mr-2'>
                                                                            <span className='text-muted'>
                                                                                <span className='text-dark-50 font-weight-bold'>Maintenance status : </span>

                                                                                {
                                                                                    d.m_status == 1 || d.m_status == 4 ?
                                                                                        <span className="available py-1 px-2 text-white rounded">{d.mst_name}</span> :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    d.m_status == 2 || d.m_status == 5 ?
                                                                                        <span className="on-job py-1 px-2 text-white rounded">{d.mst_name}</span> :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    d.m_status == 3 || d.m_status == 7 ?
                                                                                        <span className="done py-1 px-2 text-white rounded">{d.mst_name}</span> :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    d.m_status == 6 ?
                                                                                        <span className="assigned py-1 px-2 text-white rounded">{d.mst_name}</span> :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    d.m_status == 8 ?
                                                                                        <span className="canceled py-1 px-2 text-white rounded">{d.mst_name}</span> :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    d.m_status == 11 ?
                                                                                        <span className="suspended py-1 px-2 text-white rounded">{d.mst_name}</span> :
                                                                                        null
                                                                                }

                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='d-flex mb-1'>
                                                                        <div className='mr-2 pt-1'>
                                                                            <span className=''>
                                                                                <span className='text-dark-50 font-weight-bold'>Date of submission : </span>
                                                                                <span className="py-4 px-2 font-weight-bold">{Moment(d.m_created_date).format('D MMM. YYYY')}</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    <div className='timeline pb-2 pt-2'>
                                                        <div className='timeline-item align-items-start text-left'>
                                                            <div className='timeline-content bg-light2 px-8 ml-1 rounded pt-5 pb-2'>
                                                                <p className='p-0 mb-3'> {t("Home.timelineEmpty")} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <Footer />

        </div>
    );

}

export default Home;