import React, { useState, useRef, useEffect } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ReactSession } from 'react-client-session';
import axios from "axios";
import Header_mobile from "./header_mobile";
import Header from "./header";
import Footer from "./footer";
import base_url from "../constants/global";
import Dropdown from 'react-bootstrap/Dropdown';
import { createGlobalState } from "react-hooks-global-state";
import Snackbar from "./Snackbar";
import toast from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

const { setGlobalState, useGlobalState } = createGlobalState({
    show: false,
    sitedata: [],
    snackbarmsg: '',
    snackbartype: 'success',
    showsnackbar: false,
});

const notifySuccess = (msg) => toast.success(msg, {
    style: {
        padding: '16px',
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: '#FFF',
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
    }
});

const notifyError = (msg) => toast.error(msg, {
    style: {
        padding: '16px',
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: '#FFF',
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
    }
});

function Example(props) {

    const { t, i18n } = useTranslation();
    const userid = ReactSession.get("userid");
    const usertoken = ReactSession.get("usertoken");
    const [site, setSite] = useState('');
    const [message, setMessage] = useState('');

    const myRefname2 = useRef(null);

    const handleSubmitSite = e => {

        e.preventDefault();

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Users/add_site',
            data: { token: usertoken, userid: userid, site: site },
        })
            .then(response => {
                if (response.data.Validation === "FALSE") {
                    setMessage(response.data.site_name);
                } else {
                    if (response.data.Inserted === "TRUE") {
                        setMessage("");
                        myRefname2.current.click();
                        loadData();
                        notifySuccess(response.data.Result)
                    } else {
                        notifyError(response.data.Result)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    };

    const myRefname = useRef(null);

    const handleClick = () => {
        myRefname.current.click();
    }

    ///

    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sitesPerPage, setRoomsPerPage] = useState(10);

    const loadData = () => {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Users/GetSites2',
            data: { token: usertoken },
        })
            .then(response => {
                if (response.data.error) return null
                setSites(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const [cookies, setCookie, removeCookie] = useCookies(['i18next']);

    function handleCookie(e) {
        setCookie("i18next", e);
        i18n.changeLanguage(e);
    }

    useEffect(() => {
        setLoading(true);
        loadData();
        setLoading(false);
    }, []);

    //Get current users
    const indexOfLastPost = currentPage * sitesPerPage;
    const indexOfFirstPost = indexOfLastPost - sitesPerPage;
    const currentPosts = sites.slice(indexOfFirstPost, indexOfLastPost);

    function DropdownMenu({ d }) {

        const handleUpdateModal = () => {
            setShowModal(true);
            setSiteData(d);
        }

        const [modalShow, setModalShow] = React.useState(false);
        const [showModal, setShowModal] = useGlobalState("show");
        const [getsitedata, setSiteData] = useGlobalState("sitedata");

        const [permissions, setPermissions] = useState(
            {
                permission: {
                    "per1": false,
                    "per2": false,
                    "per3": false,
                    "per4": false,
                    "per5": false,
                    "per6": false,
                    "per7": false,
                    "per8": false,
                    "per9": false,
                    "per10": false,
                    "per11": false,
                    "per12": false,
                    "per13": false,
                    "per14": false,
                    "per15": false,
                    "per16": false,
                    "per17": false,
                    "per18": false,
                    "per19": false,
                    "per20": false,
                    "per21": false,
                    "per22": false,
                    "per23": false,
                    "per24": false,
                    "per25": false,
                    "per26": false,
                    "per27": false,
                    "per28": false,
                    "per29": false,
                    "per30": false,
                    "per31": false,
                    "per32": false,
                    "per33": false,
                    "per34": false,
                    "per35": false,
                    "per36": false
                }
            }
        );

        const [permissiondata, setPermissiondata] = useState("");
        const userid = ReactSession.get("userid");
        const usertoken = ReactSession.get("usertoken");

        const loadPermissionData = () => {
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                url: base_url + 'Users/UserPermissions',
                data: { token: usertoken, userid: userid },
            })
                .then(response => {
                    setPermissiondata(response.data);

                    var prevPerm = permissions;

                    var json = response.data;

                    Object.keys(json).forEach(function (key) {
                        if (json[key].usrper_per_id == 1) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per1 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 2) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per2 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 3) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per3 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 4) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per4 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 5) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per5 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 6) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per6 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 7) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per7 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 8) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per8 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 9) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per9 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 10) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per10 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 11) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per11 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 12) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per12 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 13) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per13 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 14) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per14 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 15) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per15 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 16) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per16 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 17) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per17 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 18) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per18 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 19) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per19 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 20) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per20 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 21) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per21 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 22) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per22 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 23) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per23 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 24) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per24 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 25) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per25 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 26) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per26 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 27) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per27 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 28) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per28 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 29) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per29 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 30) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per30 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 31) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per31 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 32) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per32 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 33) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per33 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 34) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per34 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 35) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per35 = true;
                                setPermissions(prevPerm);
                            }
                        }

                        if (json[key].usrper_per_id == 36) {
                            if (json[key].usrper_val == 1) {
                                prevPerm.permission.per36 = true;
                                setPermissions(prevPerm);
                            }
                        }

                    });

                })
                .catch(error => {
                    console.log(error)
                })
        }

        useEffect(() => {
            loadPermissionData();
        }, []);

        return (
            <>

                <Dropdown>
                    <Dropdown.Toggle variant="dropdown-basic" className="w-60px text-white py-0">
                        <div className='dropdown dropdown-inline bg-light2 py-0 rounded'>
                            <a type='button' className='btn btn-light2 btn-icon py-0 btn-sm' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                <i className='ki ki-bold-more-hor'></i>
                            </a>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            permissions.permission.per18 ?
                                <Dropdown.Item href="#" onClick={handleUpdateModal} className="dropdown-item border-bottom border-top">
                                    <i className='far fa-edit'></i> <span className="pl-3">{t("Sites.EditSite")}</span>
                                </Dropdown.Item> :
                                null
                        }
                    </Dropdown.Menu>
                </Dropdown>

            </>
        );
    }

    function UpdateData(props) {

        const [sdata, setSdata] = useState([]);
        const [sitedata, setSitedata] = useState("");

        const usertoken = ReactSession.get("usertoken");

        const loadSiteData = () => {
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                url: base_url + 'Users/GetSites',
                data: { token: usertoken },
            })
                .then(response => {
                    if (response.data.error) return null
                    setSitedata(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }

        const myRefname = useRef(null);
        const myRefnameU = useRef(null);

        const handleClick = () => {
            myRefname.current.click();
        }

        ///

        const [siteid, setSiteid] = useState('');
        const [site, setSite] = useState();

        const [messageSite, setMessageSite] = useState('');
        const [message, setMessage] = useState('');

        useEffect(() => {
            loadSiteData();
            setSiteid(props.info.site_id);
            setSite(props.info.site_name);
        }, []);

        const handleSubmitUSite = e => {

            e.preventDefault();

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                url: base_url + 'Users/update_site',
                data: { token: usertoken, siteid: siteid, site: site },
            })
                .then(response => {
                    if (response.data.Validation == "FALSE") {
                        setMessageSite(response.data.site);
                    } else {
                        setMessageSite("");

                        if (response.data.Inserted == "TRUE") {
                            myRefnameU.current.click();
                            loadData();
                            notifySuccess(response.data.Result)
                        } else {
                            notifyError(response.data.Result)
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })

        };

        return (
            <>

                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        {t("Sites.UpdateSite")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <form onSubmit={handleSubmitUSite}>

                            <div className="form-group mb-3">
                                <label>{t("Sites.SiteName")}<span className="text-danger pl-3">*</span></label>
                                <input type="text" id="site" onChange={(e) => { setSite(e.target.value) }} value={site} name="site" className="form-control rounded-0" placeholder="" />
                                <div id="site-error" className="text-danger pt-2">{message}</div>
                            </div>

                            <button ref={myRefname} hidden className="btn">{t("Sites.Submit")}</button>

                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button ref={myRefnameU} onClick={props.onHide} variant="default">{t("Sites.Cancel")}</Button>
                        <Button type="submit" onClick={handleClick} variant="danger">{t("Sites.UpdateSite")}</Button>
                    </Modal.Footer>
                </Modal>

            </>
        )

    }

    const Sites = ({ data, loading }) => {

        const [showModal, setShowModal] = useGlobalState("show");
        const [getsitedata, setSiteData] = useGlobalState("sitedata");

        if (loading) {
            return <h2>Loading...</h2>
        }

        return (
            <>
                <tbody id="resultdata">
                    {
                        currentPosts.length ?
                            currentPosts.map(
                                (d, index) =>
                                    <tr key={index} id='td"+ d[i].site_id +"' className='data'>
                                        <td>{((currentPage - 1) * 10) + (index + 1)}</td>
                                        <td id='tb-siteid' hidden>{d.site_id}</td>
                                        <td>
                                            <DropdownMenu key={index} d={d} />
                                        </td>
                                        <td id='tb-site-name' className="text-left">{d.site_name}</td>
                                        <td id='tb-created-by' className="text-left">{d.user_name}</td>
                                        <td id='tb-created-date' className="text-left">{d.site_created_date}</td>
                                    </tr>
                            ) :
                            <tr><td colSpan={6} className="text-center pt-20 fs-16">No data found</td></tr>
                    }
                </tbody>

                {showModal ? <UpdateData show={showModal} onHide={() => setShowModal(false)} info={getsitedata} /> : null}
            </>
        )
    }

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {

        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if ((currentPage - 2) > 1 && (currentPage + 2 <= totalPages)) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= (currentPage + 2); i++) {
                pageNumbers.push(i);
            }
        } else if ((currentPage - 2) > 1 && (currentPage + 1 <= totalPages)) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= (currentPage + 1); i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0)
                startlink = currentPage - 4;
            else if (currentPage - 4 >= 0)
                startlink = currentPage - 3;
            else if (currentPage - 3 >= 0)
                startlink = currentPage - 2;
            else if (currentPage - 2 >= 0)
                startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages)
                pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav>
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="" >{"«"}</a>
                            }
                        </li>

                        <li className="page-item">
                            {
                                currentPage == 1 ?
                                    <a href="#" className="" disabled>{"<"}</a> :
                                    <a onClick={() => paginate((currentPage - 1))} href="#" className="" >{"<"}</a>
                            }
                        </li>

                        {pageNumbers.map(number => (
                            <li key={number} className="page-item">
                                {
                                    currentPage == number ?
                                        <a onClick={() => paginate(number)} href="#" className="bg-red" >{number}</a> :
                                        <a onClick={() => paginate(number)} href="#" className="" >{number}</a>
                                }
                            </li>
                        ))}

                        <li className="page-item">
                            {
                                currentPage == totalPages ?
                                    <a href="#" className="" disabled>{">"}</a> :
                                    <a onClick={() => paginate((currentPage + 1))} href="#" className="" >{">"}</a>
                            }
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="" >{"»"}</a>
                            }
                        </li>

                    </ul>
                </nav>
            </>
        )
    }

    // Change page 
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    ///

    return (
        <>

            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("Sites.AddSite")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitSite}>
                        <div className="form-group mb-3">
                            <label>{t("Sites.SiteName")}<span className="text-danger pl-3">*</span></label>
                            <input type="text" id="site" onChange={(e) => { setSite(e.target.value) }} name="site" className="form-control rounded-0" placeholder="" />
                            <div id="site-error" className="text-danger pt-2">{message}</div>
                        </div>
                        <button ref={myRefname} hidden className="btn">{t("Sites.Submit")}</button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button ref={myRefname2} onClick={props.onHide} variant="default">{t("Sites.Cancel")}</Button>
                    <Button type="submit" onClick={handleClick} variant="danger">{t("Sites.AddSite")}</Button>
                </Modal.Footer>
            </Modal>

            <div className="card-body pb-0">
                <div className="table-responsive">
                    <table id="result" className="table min-h-300px">
                        <thead>
                            <tr>
                                <th scope="col" className="w-25px">#</th>
                                <th scope="col" className="w-100px"></th>
                                <th scope="col" className="text-left">
                                    {t("Sites.SITENAME")}
                                    <a href="#" data-toggle="modal" data-target="#ticketidModal"><i className="la la-filter"></i></a>
                                </th>
                                <th scope="col" className="text-left">
                                    {t("Room.CREATEDBY")}
                                    <a href="#" data-toggle="modal" data-target="#filterassignedtoModal"><i className="la la-filter"></i></a>
                                </th>
                                <th scope="col" className="text-left">
                                    {t("Room.CREATEDDATE")}
                                    <a href="#" data-toggle="modal" data-target="#filterdateModal"><i className="la la-filter"></i></a>
                                </th>
                            </tr>
                        </thead>
                        <Sites data={sites} loading={loading} />
                    </table>
                </div>
            </div>
            <div className="card-footer pt-2 pb-2">
                <div className="d-flex justify-content-center">
                    <Pagination
                        postsPerPage={sitesPerPage}
                        totalPosts={sites.length}
                        paginate={paginate}
                    />
                </div>
            </div>

        </>
    );
}

function Sites() {

    const [modalShow, setModalShow] = React.useState(false);

    const snackbarRef = useRef(null);

    const [permissions, setPermissions] = useState(
        {
            permission: {
                "per1": false,
                "per2": false,
                "per3": false,
                "per4": false,
                "per5": false,
                "per6": false,
                "per7": false,
                "per8": false,
                "per9": false,
                "per10": false,
                "per11": false,
                "per12": false,
                "per13": false,
                "per14": false,
                "per15": false,
                "per16": false,
                "per17": false,
                "per18": false,
                "per19": false,
                "per20": false,
                "per21": false,
                "per22": false,
                "per23": false,
                "per24": false,
                "per25": false,
                "per26": false,
                "per27": false,
                "per28": false,
                "per29": false,
                "per30": false,
                "per31": false,
                "per32": false,
                "per33": false,
                "per34": false,
                "per35": false,
                "per36": false
            }
        }
    );

    const [permissiondata, setPermissiondata] = useState("");
    const userid = ReactSession.get("userid");
    const usertoken = ReactSession.get("usertoken");
    const { t, i18n } = useTranslation();

    const loadPermissionData = () => {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: base_url + 'Users/UserPermissions',
            data: { token: usertoken, userid: userid },
        })
            .then(response => {
                setPermissiondata(response.data);

                var prevPerm = permissions;

                var json = response.data;

                Object.keys(json).forEach(function (key) {
                    if (json[key].usrper_per_id == 1) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per1 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 2) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per2 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 3) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per3 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 4) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per4 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 5) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per5 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 6) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per6 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 7) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per7 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 8) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per8 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 9) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per9 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 10) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per10 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 11) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per11 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 12) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per12 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 13) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per13 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 14) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per14 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 15) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per15 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 16) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per16 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 17) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per17 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 18) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per18 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 19) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per19 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 20) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per20 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 21) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per21 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 22) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per22 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 23) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per23 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 24) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per24 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 25) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per25 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 26) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per26 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 27) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per27 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 28) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per28 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 29) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per29 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 30) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per30 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 31) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per31 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 32) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per32 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 33) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per33 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 34) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per34 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 35) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per35 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].usrper_per_id == 36) {
                        if (json[key].usrper_val == 1) {
                            prevPerm.permission.per36 = true;
                            setPermissions(prevPerm);
                        }
                    }

                });

            })
            .catch(error => {
                console.log(error)
            })
    }
    const [cookies, setCookie, removeCookie] = useCookies(['i18next']);

    function handleCookie(e) {
        setCookie("i18next", e);
        i18n.changeLanguage(e);
    }

    useEffect(() => {
        loadPermissionData();
    }, []);

    return (

        <>

            <Header_mobile />
            <Header />

            <div className="d-flex flex-column flex-column-fluid " style={{ minHeight: "85vh" }}>

                <div className="d-flex flex-column-fluid">
                    <div className=" container" id="pageBlocking">

                        <div className="d-print-none mt-20"></div>

                        <div className="row">

                            <div className="col-md-2"> </div>

                            <div className="col-md-8">

                                <div className="card card-custom d-print-none" id="kt_blockui_card">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="card-label">
                                            {t("Sites.ListOfAffectationSites")}
                                            </h3>
                                            <button id="btn-refrech" className="btn btn-sm bg-light2 ml-4 w-45px pl-4"><i className="la la-sync fs-17"></i></button>
                                        </div>
                                        <div className="card-toolbar">
                                            <button id="clearfilter" className="btn btn-light font-weight-bolder border mr-3">{t("Room.clearFilter")}</button>
                                            {
                                                permissions.permission.per19 ?
                                                    <button onClick={() => setModalShow(true)} id="crticket-btn" className="btn btn-defaut text-white bg-red font-weight-bold" data-toggle="modal" data-target="#addticketModal">
                                                       {t("Sites.AddSiteb")}
                                                    </button> :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <Example
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />

                                </div>

                                <div className="mt-10 d-print-none"></div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <Footer />

        </>
    );

}

export default Sites;