import React, { useState, useRef, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ReactSession } from "react-client-session";
import axios from "axios";
import Header_mobile from "./header_mobile";
import Dropdown from "react-bootstrap/Dropdown";
import Header from "./header";
import Footer from "./footer";
import { createGlobalState } from "react-hooks-global-state";
import base_url from "../constants/global";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Snackbar from "./Snackbar";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const { setGlobalState, useGlobalState } = createGlobalState({
  show: false,
  showreset: false,
  userdata: [],
  snackbarmsg: "",
  snackbartype: "success",
  showsnackbar: false,
});

const notifySuccess = (msg) =>
  toast.success(msg, {
    style: {
      padding: "16px",
      backgroundColor: "rgba(124, 140, 135, 0.70)",
      color: "#FFF",
      maxWidth: "280px",
      textAlign: "left",
      fontSize: "13px",
    },
  });

const notifyError = (msg) =>
  toast.error(msg, {
    style: {
      padding: "16px",
      backgroundColor: "rgba(124, 140, 135, 0.70)",
      color: "#FFF",
      maxWidth: "280px",
      textAlign: "left",
      fontSize: "13px",
    },
  });

function Example(props) {
  const { t, i18n } = useTranslation();
  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userfullname, setUserfullname] = useState("");
  const [email, setUseremail] = useState("");
  const [role, setRole] = useState("");

  const [messageUsername, setMessageUsername] = useState("");
  const [messagePassword, setMessagePassword] = useState("");
  const [messageFullname, setMessageFullname] = useState("");
  const [messageEmail, setMessageEmail] = useState("");
  const [messageRole, setMessageRole] = useState("");
  const [departement, setDepartement] = useState(0);
  const [message, setMessage] = useState("");
  const [messageDepartement, setMessageDepartement] = useState("");

  const myRefnameU = useRef(null);

  const handleSubmitUser = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/Insert",
      data: {
        token: usertoken,
        username: username,
        password: password,
        fullname: userfullname,
        useremail: email,
        role: role,
        userid: userid,
        departement: departement,
      },
    })
      .then((response) => {
        if (response.data.Validation == "FALSE") {
          setMessageUsername(response.data.username);
          setMessagePassword(response.data.user_password);
          setMessageFullname(response.data.fullname);
          setMessageEmail(response.data.user_email);
          setMessageRole(response.data.user_role);
          setMessageDepartement(response.data.departement);
        } else {
          setMessageUsername("");
          setMessagePassword("");
          setMessageFullname("");
          setMessageEmail("");
          setMessageRole("");
          setMessageDepartement("");

          if (response.data.Inserted == "TRUE") {
            myRefnameU.current.click();
            loadData();
            notifySuccess(response.data.Result);
          } else {
            notifyError(response.data.Result);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const myRefname = useRef(null);

  const handleClick = () => {
    myRefname.current.click();
  };

  const [roledata, setRoledata] = useState("");
  const [departementdata, setDepartementdata] = useState("");

  const loadRoleData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetRoles",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setRoledata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const loadData1 = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetDpartement",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setDepartementdata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userPerPage, setUserPerPage] = useState(10);

  const loadData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetUsers",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        if (response.data.error) return null;
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
  }

  useEffect(() => {
    setLoading(true);
    loadData();
    loadData1();
    loadRoleData();
    setLoading(false);
  }, []);

  //Get current users
  const indexOfLastPost = currentPage * userPerPage;
  const indexOfFirstPost = indexOfLastPost - userPerPage;
  const currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);

  function DropdownMenu({ d }) {
    const handleUpdateModal = () => {
      setShowModal(true);
      setUserData(d);
    };

    const handleResetModal = () => {
      setShowModalReset(true);
      setUserData(d);
    };

    const [modalShow, setModalShow] = React.useState(false);
    const [showModal, setShowModal] = useGlobalState("show");
    const [showModalReset, setShowModalReset] = useGlobalState("showreset");
    const [getuserdata, setUserData] = useGlobalState("userdata");

    const [permissions, setPermissions] = useState({
      permission: {
        per1: false,
        per2: false,
        per3: false,
        per4: false,
        per5: false,
        per6: false,
        per7: false,
        per8: false,
        per9: false,
        per10: false,
        per11: false,
        per12: false,
        per13: false,
        per14: false,
        per15: false,
        per16: false,
        per17: false,
        per18: false,
        per19: false,
        per20: false,
        per21: false,
        per22: false,
        per23: false,
        per24: false,
        per25: false,
        per26: false,
        per27: false,
        per28: false,
        per29: false,
        per30: false,
        per31: false,
        per32: false,
        per33: false,
        per34: false,
        per35: false,
        per36: false,
      },
    });

    const [permissiondata, setPermissiondata] = useState("");
    const userid = ReactSession.get("userid");
    const usertoken = ReactSession.get("usertoken");

    const loadPermissionDatadd = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/UserPermissions",
        data: { token: usertoken, userid: userid },
      })
        .then((response) => {
          setPermissiondata(response.data);

          var prevPerm = permissions;

          var json = response.data;

          Object.keys(json).forEach(function (key) {
            if (json[key].usrper_per_id == 1) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per1 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 2) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per2 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 3) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per3 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 4) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per4 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 5) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per5 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 6) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per6 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 7) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per7 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 8) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per8 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 9) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per9 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 10) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per10 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 11) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per11 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 12) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per12 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 13) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per13 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 14) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per14 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 15) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per15 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 16) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per16 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 17) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per17 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 18) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per18 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 19) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per19 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 20) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per20 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 21) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per21 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 22) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per22 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 23) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per23 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 24) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per24 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 25) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per25 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 26) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per26 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 27) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per27 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 28) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per28 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 29) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per29 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 30) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per30 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 31) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per31 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 32) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per32 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 33) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per33 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 34) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per34 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 35) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per35 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 36) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per36 = true;
                setPermissions(prevPerm);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

    function handleCookie(e) {
      setCookie("i18next", e);
      i18n.changeLanguage(e);
    }

    useEffect(() => {
      loadPermissionDatadd();
    }, []);

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            variant="dropdown-basic"
            className="w-60px text-white pt-0 pb-0"
          >
            <div className="dropdown dropdown-inline bg-light2 rounded pt-0 pb-0">
              <a
                type="button"
                className="btn btn-light2 pt-0 pb-0 btn-icon btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="ki ki-bold-more-hor"></i>
              </a>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {permissions.permission.per26 ? (
              <Dropdown.Item
                href="#"
                onClick={handleUpdateModal}
                className="dropdown-item border-bottom border-top"
              >
                <i className="far fa-edit"></i>{" "}
                <span className="pl-3">{t("Users.EditUser")}</span>
              </Dropdown.Item>
            ) : null}

            {permissions.permission.per27 ? (
              <Dropdown.Item
                href="#"
                onClick={handleResetModal}
                className="dropdown-item border-bottom"
              >
                <i className="far fa-list-alt"></i>{" "}
                <span className="pl-3">{t("Users.ResetPassword")}</span>
              </Dropdown.Item>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  function UpdateData(props) {
    const [sdata, setSdata] = useState([]);
    const [roledata, setRoledata] = useState("");
    const [departementdata, setDepartementdata] = useState("");
    const [departement, setDepartement] = useState(0);

    const [permissiondata, setPermissiondata] = useState("");

    const usertoken = ReactSession.get("usertoken");

    const loadRoleData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetRoles",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setRoledata(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const loadRoleData1 = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetDpartement",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setDepartementdata(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const loadPermissionData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetUserPermissions",
        data: { token: usertoken, userid: props.info.user_id },
      })
        .then((response) => {
          setPermissiondata(response.data);

          var prevPerm = permissions;

          var json = response.data;

          Object.keys(json).forEach(function (key) {
            if (json[key].usrper_per_id == 1) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per1 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 2) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per2 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 3) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per3 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 4) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per4 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 5) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per5 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 6) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per6 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 7) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per7 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 8) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per8 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 9) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per9 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 10) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per10 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 11) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per11 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 13) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per13 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 14) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per14 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 15) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per15 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 16) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per16 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 17) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per17 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 18) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per18 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 19) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per19 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 20) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per20 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 21) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per21 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 22) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per22 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 24) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per24 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 25) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per25 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 26) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per26 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 27) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per27 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 28) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per28 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 29) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per29 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 30) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per30 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 33) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per33 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 34) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per34 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 35) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per35 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 36) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per36 = true;
                setPermissions(prevPerm);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const myRefname = useRef(null);
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///

    const [userid, setUserid] = useState("");
    const [username, setUsername] = useState();
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");

    const [messageUsernameu, setMessageUsername] = useState("");
    const [messageFullnameu, setMessageFullname] = useState("");
    const [messageEmailu, setMessageEmail] = useState("");
    const [messageRoleu, setMessageRole] = useState("");
    const [messageDepartement, setMessageDepartement] = useState("");

    useEffect(() => {
      loadRoleData();
      loadRoleData1();
      setUserid(props.info.user_id);
      setUsername(props.info.user_name);
      setFullname(props.info.user_full_name);
      setEmail(props.info.user_email);
      setRole(props.info.user_role);
      setDepartement(props.info.user_departement);
      loadPermissionData();
    }, []);

    const handleSubmitUUser = (e) => {
      e.preventDefault();

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/update_user",
        data: {
          token: usertoken,
          userid: userid,
          username: username,
          fullname: fullname,
          email: email,
          role: role,
          departement: departement,
          permission1: permissions.permission.per1,
          permission2: permissions.permission.per2,
          permission3: permissions.permission.per3,
          permission4: permissions.permission.per4,
          permission5: permissions.permission.per5,
          permission6: permissions.permission.per6,
          permission7: permissions.permission.per7,
          permission8: permissions.permission.per8,
          permission9: permissions.permission.per9,
          permission10: permissions.permission.per10,
          permission11: permissions.permission.per11,
          permission13: permissions.permission.per13,
          permission14: permissions.permission.per14,
          permission15: permissions.permission.per15,
          permission16: permissions.permission.per16,
          permission17: permissions.permission.per17,
          permission18: permissions.permission.per18,
          permission19: permissions.permission.per19,
          permission20: permissions.permission.per20,
          permission21: permissions.permission.per21,
          permission22: permissions.permission.per22,
          permission24: permissions.permission.per24,
          permission25: permissions.permission.per25,
          permission26: permissions.permission.per26,
          permission27: permissions.permission.per27,
          permission28: permissions.permission.per28,
          permission29: permissions.permission.per29,
          permission30: permissions.permission.per30,
          permission33: permissions.permission.per33,
          permission34: permissions.permission.per34,
          permission35: permissions.permission.per35,
          permission36: permissions.permission.per36,
        },
      })
        .then((response) => {
          if (response.data.Validation == "FALSE") {
            setMessageUsername(response.data.username);
            setMessageFullname(response.data.fullname);
            setMessageEmail(response.data.email);
            setMessageRole(response.data.role);
            setMessageDepartement(response.data.departement);
          } else {
            setMessageUsername("");
            setMessageFullname("");
            setMessageEmail("");
            setMessageDepartement("");
            setMessageRole("");

            if (response.data.Inserted == "TRUE") {
              myRefnameU.current.click();
              notifySuccess(response.data.Result);
              loadData();
            } else {
              notifyError(response.data.Result);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const [permissions, setPermissions] = useState({
      permission: {
        per1: false,
        per2: false,
        per3: false,
        per4: false,
        per5: false,
        per6: false,
        per7: false,
        per8: false,
        per9: false,
        per10: false,
        per11: false,
        per12: false,
        per13: false,
        per14: false,
        per15: false,
        per16: false,
        per17: false,
        per18: false,
        per19: false,
        per20: false,
        per21: false,
        per22: false,
        per23: false,
        per24: false,
        per25: false,
        per26: false,
        per27: false,
        per28: false,
        per29: false,
        per30: false,
        per31: false,
        per32: false,
        per33: false,
        per34: false,
        per35: false,
        per36: false,
      },
    });

    const handlePermChange = (d, value) => {
      var prevPerm = permissions;

      if (d.usrper_per_id == 1) {
        prevPerm.permission.per1 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 2) {
        prevPerm.permission.per2 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 3) {
        prevPerm.permission.per3 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 4) {
        prevPerm.permission.per4 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 5) {
        prevPerm.permission.per5 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 6) {
        prevPerm.permission.per6 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 7) {
        prevPerm.permission.per7 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 8) {
        prevPerm.permission.per8 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 9) {
        prevPerm.permission.per9 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 10) {
        prevPerm.permission.per10 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 11) {
        prevPerm.permission.per11 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 12) {
        prevPerm.permission.per12 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 13) {
        prevPerm.permission.per13 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 14) {
        prevPerm.permission.per14 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 15) {
        prevPerm.permission.per15 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 16) {
        prevPerm.permission.per16 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 17) {
        prevPerm.permission.per17 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 18) {
        prevPerm.permission.per18 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 19) {
        prevPerm.permission.per19 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 20) {
        prevPerm.permission.per20 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 21) {
        prevPerm.permission.per21 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 22) {
        prevPerm.permission.per22 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 23) {
        prevPerm.permission.per23 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 24) {
        prevPerm.permission.per24 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 25) {
        prevPerm.permission.per25 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 26) {
        prevPerm.permission.per26 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 27) {
        prevPerm.permission.per27 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 28) {
        prevPerm.permission.per28 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 29) {
        prevPerm.permission.per29 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 30) {
        prevPerm.permission.per30 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 31) {
        prevPerm.permission.per31 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 32) {
        prevPerm.permission.per32 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 33) {
        prevPerm.permission.per33 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 34) {
        prevPerm.permission.per34 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 35) {
        prevPerm.permission.per35 = value;
        setPermissions(prevPerm);
      }

      if (d.usrper_per_id == 36) {
        prevPerm.permission.per36 = value;
        setPermissions(prevPerm);
      }
    };

    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Users.UpdateUser")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitUUser}>
              <Tabs
                defaultActiveKey="information"
                id="tab-user"
                className="mb-3"
              >
                <Tab
                  eventKey="information"
                  title="User information"
                  className="nav nav-danger nav-light-danger nav-pills nav-item"
                >
                  <div className="pt-8">
                    <div className="form-group row mb-3">
                      <div className="col-xl-6">
                        <label>
                          {t("Users.Username")}
                          <span className="text-danger pl-3">*</span>
                        </label>
                        <input
                          type="text"
                          id="username"
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          value={username}
                          name="username"
                          className="form-control rounded-0"
                          placeholder=""
                        />
                        <div id="username-error" className="text-danger pt-2">
                          {messageUsernameu}
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <label>
                          {t("Users.UserFullname")}
                          <span className="text-danger pl-3">*</span>
                        </label>
                        <input
                          type="text"
                          id="fullname"
                          onChange={(e) => {
                            setFullname(e.target.value);
                          }}
                          value={fullname}
                          name="fullname"
                          className="form-control rounded-0"
                          placeholder=""
                        />
                        <div id="fullname-error" className="text-danger pt-2">
                          {messageFullnameu}
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label>
                        {t("Users.Email")}
                        <span className="text-danger pl-3">*</span>
                      </label>
                      <input
                        type="email"
                        id="useremail"
                        name="useremail"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        className="form-control rounded-0"
                        placeholder=""
                      />
                      <div id="email-error" className="text-danger pt-2">
                        {messageEmailu}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label>
                        {t("Users.Role")}
                        <span className="text-danger pl-3">*</span>
                      </label>
                      <select
                        id="user-role"
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                        value={role}
                        name="role"
                        className="form-control rounded-0"
                      >
                        <option></option>
                        {roledata.length
                          ? roledata.map((d) => (
                              <option key={d.r_user_id} value={d.r_user_id}>
                                {" "}
                                {d.r_user_role}{" "}
                              </option>
                            ))
                          : null}
                      </select>
                      <div id="role-error" className="text-danger pt-2">
                        {messageRoleu}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label>
                        Departement
                        <span className="text-danger pl-3">*</span>
                      </label>
                      <select
                        id="user-role"
                        onChange={(e) => {
                          setDepartement(e.target.value);
                        }}
                        value={departement}
                        name="dep"
                        className="form-control rounded-0"
                      >
                        <option></option>
                        {departementdata.length
                          ? departementdata.map((d) => (
                              <option key={d.dep_id} value={d.dep_id}>
                                {" "}
                                {d.dep_name}{" "}
                              </option>
                            ))
                          : null}
                      </select>
                      <div id="role-error" className="text-danger pt-2">
                        {messageDepartement}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="permission" title="User permissions">
                  <div
                    className="pt-8"
                    style={{ height: "268px", overflowY: "scroll" }}
                  >
                    <div className="form-group">
                      <div className="col-12 col-form-label">
                        {permissiondata.length
                          ? permissiondata.map((d, index) => (
                              <>
                                <div className="form-group pb-0">
                                  <div className="checkbox-inline">
                                    <label className="checkbox checkbox-success">
                                      {d.usrper_per_id == 1 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per1
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 2 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per2
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 3 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per3
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 4 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per4
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 5 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per5
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 6 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per6
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 7 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per7
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 8 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per8
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 9 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per9
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 10 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per10
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 11 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per11
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 12 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per12
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 13 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per13
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 14 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per14
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 15 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per15
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 16 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per16
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 17 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per17
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 18 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per18
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 19 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per19
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 20 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per20
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 21 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per21
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 22 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per22
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 23 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per23
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 24 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per24
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 25 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per25
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 26 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per26
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 27 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per27
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 28 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per28
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 29 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per29
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 30 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per30
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 31 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per31
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 32 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per32
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 33 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per33
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 34 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per34
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 35 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per35
                                          }
                                        />
                                      ) : null}

                                      {d.usrper_per_id == 36 ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handlePermChange(
                                              d,
                                              e.target.checked
                                            );
                                          }}
                                          defaultChecked={
                                            permissions.permission.per36
                                          }
                                        />
                                      ) : null}

                                      <span></span>
                                      {d.per_name}
                                    </label>
                                  </div>
                                </div>
                              </>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>

              <button ref={myRefname} hidden className="btn">
                {t("Users.Submit")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("Users.Cancel")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("Users.UpdateUser")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function Resetpassword(props) {
    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitUPassword = (e) => {
      e.preventDefault();

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/ResetPassword",
        data: { token: usertoken, userid: props.info.user_id },
      })
        .then((response) => {
          if (response.data.Validation == "TRUE") {
            setPasswordMessage("New password : " + response.data.password);
            setGenerated(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    ///

    const myRefname = useRef(null);
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///

    const [userid, setUserid] = useState("");
    const [message, setMessage] = useState("");

    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);

    useEffect(() => {
      setUserid(props.info.user_id);
    }, []);

    const [passwordmessage, setPasswordMessage] = useState(
      "Do you want to reset the password ?"
    );
    const [generated, setGenerated] = useState(false);

    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Reset password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-3">
            <form onSubmit={handleSubmitUPassword}>
              <div className="form-group text-center">
                <div className="fs-17 font-weight-bolder pt-5 pb-0">
                  {passwordmessage}
                </div>
              </div>

              <button ref={myRefname} hidden className="btn">
                Submit
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            {generated ? (
              <Button ref={myRefnameU} onClick={props.onHide} variant="default">
                Close
              </Button>
            ) : (
              <Button ref={myRefnameU} onClick={props.onHide} variant="default">
                Cancel
              </Button>
            )}

            {generated ? null : (
              <Button type="submit" onClick={handleClick} variant="danger">
                Confirm
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const Users = ({ data, loading }) => {
    const [showModal, setShowModal] = useGlobalState("show");
    const [showModalReset, setShowModalReset] = useGlobalState("showreset");
    const [getuserdata, setUserData] = useGlobalState("userdata");

    if (loading) {
      return <h2>Loading...</h2>;
    }

    return (
      <>
        <tbody id="resultdata">
          {currentPosts.length ? (
            currentPosts.map((d, index) => (
              <tr key={index} id='td"+ d[i].user_id +"' className="data">
                <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                <td id="tb-userid" hidden className="text-left">
                  {d.user_id}
                </td>
                <td>
                  <DropdownMenu key={index} d={d} />
                </td>
                <td id="tb-username" className="text-left">
                  {d.user_name}
                </td>
                <td id="tb-userfullname" className="text-left">
                  {d.user_full_name}
                </td>
                <td id="tb-useremail" className="text-left">
                  {d.user_email}
                </td>
                <td id="tb-role" className="text-left">
                  {d.r_user_role}
                </td>
                <td id="tb-created-by" className="text-left">
                  {d.username}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.user_created_date}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9} className="text-center pt-20 fs-16">
                No data found
              </td>
            </tr>
          )}
        </tbody>

        {showModal ? (
          <UpdateData
            show={showModal}
            onHide={() => setShowModal(false)}
            info={getuserdata}
          />
        ) : null}
        {showModalReset ? (
          <Resetpassword
            show={showModalReset}
            onHide={() => setShowModalReset(false)}
            info={getuserdata}
          />
        ) : null}
      </>
    );
  };

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage == totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginate(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage == number ? (
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a onClick={() => paginate(number)} href="#" className="">
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a onClick={() => paginate(totalPages)} href="#" className="">
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-center"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Users.AddUser")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitUser}>
            <div className="form-group row mb-3">
              <div className="col-xl-6">
                <label>
                  {t("Users.Username")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <input
                  type="text"
                  id="username"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  name="username"
                  className="form-control rounded-0"
                  placeholder=""
                />
                <div id="username-error" className="text-danger pt-2">
                  {messageUsername}
                </div>
              </div>

              <div className="col-xl-6">
                <label>
                  {t("Users.Password")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <input
                  type="password"
                  id="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  name="password"
                  className="form-control rounded-0"
                  placeholder=""
                />
                <div id="password-error" className="text-danger pt-2">
                  {messagePassword}
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label>
                {t("Users.UserFullname")}
                <span className="text-danger pl-3">*</span>
              </label>
              <input
                type="text"
                id="fullname"
                onChange={(e) => {
                  setUserfullname(e.target.value);
                }}
                name="fullname"
                className="form-control rounded-0"
                placeholder=""
              />
              <div id="fullname-error" className="text-danger pt-2">
                {messageFullname}
              </div>
            </div>

            <div className="form-group mb-3">
              <label>
                {t("Users.Email")}
                <span className="text-danger pl-3">*</span>
              </label>
              <input
                type="email"
                id="useremail"
                name="useremail"
                onChange={(e) => {
                  setUseremail(e.target.value);
                }}
                className="form-control rounded-0"
                placeholder=""
              />
              <div id="email-error" className="text-danger pt-2">
                {messageEmail}
              </div>
            </div>

            <div className="form-group mb-3">
              <label>
                {t("Users.Role")}
                <span className="text-danger pl-3">*</span>
              </label>
              <select
                id="user-role"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                name="role"
                className="form-control rounded-0"
              >
                <option></option>
                {roledata.length
                  ? roledata.map((d) => (
                      <option key={d.r_user_id} value={d.r_user_id}>
                        {" "}
                        {d.r_user_role}{" "}
                      </option>
                    ))
                  : null}
              </select>
              <div id="role-error" className="text-danger pt-2">
                {messageRole}
              </div>
            </div>

            <div className="form-group mb-3">
              <label>Departement</label>
              <select
                id="user-role"
                onChange={(e) => {
                  setDepartement(e.target.value);
                }}
                name="role"
                className="form-control rounded-0"
                value={departement}
              >
                <option></option>
                {departementdata.length
                  ? departementdata.map((d) => (
                      <option key={d.dep_id} value={d.dep_id}>
                        {" "}
                        {d.dep_name}
                      </option>
                    ))
                  : null}
              </select>
              <div id="role-error" className="text-danger pt-2">
                {messageDepartement}
              </div>
            </div>
            <button ref={myRefname} hidden className="btn">
              {t("Users.Submit")}
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button ref={myRefnameU} onClick={props.onHide} variant="default">
            {t("Users.Cancel")}
          </Button>
          <Button type="submit" onClick={handleClick} variant="danger">
            {t("Users.AddUser")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card-body pb-0">
        <div className="table-responsive">
          <table id="result" className="table min-h-300px">
            <thead>
              <tr>
                <th scope="col" className="w-25px">
                  #
                </th>
                <th scope="col" className="w-100px"></th>
                <th scope="col" className="text-left">
                  {t("Users.USERNAME")}
                  <a href="#" data-toggle="modal" data-target="#ticketidModal">
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("Users.FULLNAME")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterassignedtoModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("Users.EMAIL")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterassignedtoModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("Users.Role").toUpperCase()}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterassignedtoModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("Users.CREATEDBY")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("Users.CREATEDDATE")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
              </tr>
            </thead>
            <Users data={users} loading={loading} />
          </table>
        </div>
      </div>
      <div className="card-footer pt-2 pb-2">
        <div className="d-flex justify-content-center">
          <Pagination
            postsPerPage={userPerPage}
            totalPosts={users.length}
            paginate={paginate}
          />
        </div>
      </div>
    </div>
  );
}

function Example2(props) {
  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");
  const [role, setRole] = useState("");
  const [messageR, setMessageR] = useState("");

  const myRefname2 = useRef(null);

  const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
  const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
  const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

  const handleSubmitRole = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/add_role",
      data: { token: usertoken, userid: userid, role: role },
    })
      .then((response) => {
        if (response.data.Validation == "FALSE") {
          setMessageR(response.data.user_role);
        } else {
          if (response.data.Inserted == "TRUE") {
            setMessageR("");
            myRefname2.current.click();
            //setSnackbarType("success");
            notifySuccess(response.data.Result);
          } else {
            setSnackbarType("fail");
            //setMessageR(response.data.Result);
            notifyError(response.data.Result);
          }
          //setSnackbarMessage(response.data.Result);
          //setShowsnackbar(true);
          //setTimeout(() => {
          //    setShowsnackbar(false);
          //}, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const { t, i18n } = useTranslation();

  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
  }

  const myRefname = useRef(null);

  const handleClick = () => {
    myRefname.current.click();
    //handleClose();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Users.AddRole")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmitRole}>
          <div className="form-group mb-3">
            <label>
              {t("Users.Role")}
              <span className="text-danger pl-3">*</span>
            </label>
            <input
              type="text"
              id="role"
              onChange={(e) => {
                setRole(e.target.value);
              }}
              name="role"
              className="form-control rounded-0"
              placeholder=""
            />
            <div id="role-error" className="text-danger pt-2">
              {messageR}
            </div>
          </div>
          <button ref={myRefname} hidden className="btn">
            {t("Users.Submit")}
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button ref={myRefname2} onClick={props.onHide} variant="default">
          {t("Users.Cancel")}
        </Button>
        <Button type="submit" onClick={handleClick} variant="danger">
          {t("Users.Role")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function Example3(props) {
  const [dep, setDep] = useState("");
  const [messageDep, setMessageDep] = useState("");

  const handleSubmitDep = (e) => {
    e.preventDefault();
    if (dep === "") {
      setMessageDep("Please enter the name");
    } else {
      setMessageDep("");
    }
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/add_departement",
      data: { departement: dep },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.Inserted) {
          notifySuccess(response.data.Result);
          //setModalShowDep(false);
          props.onHide();
        } else {
          notifyError(response.data.Result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const { t, i18n } = useTranslation();

  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add departement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group mb-3">
            <label>
              Departement name
              <span className="text-danger pl-3">*</span>
            </label>
            <input
              type="text"
              id="role"
              onChange={(e) => {
                setDep(e.target.value);
              }}
              name="dep"
              className="form-control rounded-0"
              placeholder=""
            />
            <div id="role-error" className="text-danger pt-2">
              {messageDep}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button ref={null} onClick={props.onHide} variant="default">
          {t("Users.Cancel")}
        </Button>
        <Button type="submit" onClick={handleSubmitDep} variant="danger">
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Users() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShowdep, setModalShowDep] = React.useState(false);

  const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
  const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
  const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

  const snackbarRef = useRef(null);

  const [permissions, setPermissions] = useState({
    permission: {
      per1: false,
      per2: false,
      per3: false,
      per4: false,
      per5: false,
      per6: false,
      per7: false,
      per8: false,
      per9: false,
      per10: false,
      per11: false,
      per12: false,
      per13: false,
      per14: false,
      per15: false,
      per16: false,
      per17: false,
      per18: false,
      per19: false,
      per20: false,
      per21: false,
      per22: false,
      per23: false,
      per24: false,
      per25: false,
      per26: false,
      per27: false,
      per28: false,
      per29: false,
      per30: false,
      per31: false,
      per32: false,
      per33: false,
      per34: false,
      per35: false,
      per36: false,
    },
  });

  const [permissiondata, setPermissiondata] = useState("");
  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");

  const loadPermissionDatabd = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/UserPermissions",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        setPermissiondata(response.data);

        var prevPerm = permissions;

        var json = response.data;

        Object.keys(json).forEach(function (key) {
          if (json[key].usrper_per_id == 1) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per1 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 2) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per2 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 3) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per3 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 4) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per4 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 5) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per5 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 6) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per6 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 7) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per7 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 8) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per8 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 9) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per9 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 10) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per10 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 11) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per11 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 12) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per12 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 13) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per13 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 14) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per14 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 15) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per15 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 16) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per16 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 17) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per17 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 18) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per18 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 19) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per19 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 20) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per20 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 21) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per21 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 22) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per22 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 23) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per23 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 24) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per24 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 25) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per25 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 26) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per26 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 27) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per27 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 28) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per28 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 29) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per29 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 30) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per30 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 31) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per31 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 32) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per32 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 33) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per33 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 34) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per34 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 35) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per35 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 36) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per36 = true;
              setPermissions(prevPerm);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const { t, i18n } = useTranslation();

  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
  }

  useEffect(() => {
    loadPermissionDatabd();
  }, []);

  return (
    <>
      <Header_mobile />
      <Header />

      <div
        className="d-flex flex-column flex-column-fluid"
        style={{ minHeight: "85vh" }}
      >
        {showsnackbar == true ? (
          <Snackbar
            ref={snackbarRef}
            message={snackbarmessage}
            type={snackbartype}
            show={showsnackbar}
          />
        ) : null}

        <div className="d-flex flex-column-fluid">
          <div className=" container" id="pageBlocking">
            <div className="d-print-none mt-20"></div>

            <div className="row">
              <div className="col-md-12">
                <div
                  className="card card-custom d-print-none"
                  id="kt_blockui_card"
                >
                  <div className="card-header">
                    <div className="card-title">
                      <h3 className="card-label">{t("Users.ListOfUsers")}</h3>
                      <button
                        id="btn-refrech"
                        className="btn btn-sm bg-light2 ml-4 w-45px pl-4"
                      >
                        <i className="la la-sync fs-17"></i>
                      </button>
                    </div>
                    <div className="card-toolbar">
                      <Example2
                        show={modalShow2}
                        onHide={() => setModalShow2(false)}
                      />

                      <button
                        id="clearfilter"
                        className="btn btn-light font-weight-bolder border mr-3"
                      >
                        {t("Room.clearFilter")}
                      </button>
                      {permissions.permission.per28 ? (
                        <button
                          onClick={() => setModalShow2(true)}
                          id="clearfilter"
                          className="btn btn-light font-weight-bolder border mr-3"
                        >
                          {t("Users.AddRole")}
                        </button>
                      ) : null}
                      <button
                        onClick={() => setModalShowDep(true)}
                        id="crticket-btn"
                        className="btn btn-defaut text-white bg-info font-weight-bold mr-3"
                        data-toggle="modal"
                        data-target="#addticketModal"
                      >
                        Add departement
                      </button>
                      {permissions.permission.per25 ? (
                        <button
                          onClick={() => setModalShow(true)}
                          id="crticket-btn"
                          className="btn btn-defaut text-white bg-red font-weight-bold"
                          data-toggle="modal"
                          data-target="#addticketModal"
                        >
                          {t("Users.AddUser")}
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <Example3
                    show={modalShowdep}
                    onHide={() => setModalShowDep(false)}
                  />

                  <Example
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>

                <div className="mt-10 d-print-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Users;
