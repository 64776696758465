import React, { Component, useContext, useState, useEffect, useLayoutEffect } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../assets/img/auto-logo.jpg";
import comIcon from "../assets/custom/media/svg/icons/Communication/Contact1.svg";
import { useTranslation } from "react-i18next";
import Language from "./Language";
import { ReactSession } from "react-client-session";
import toast, { Toaster } from "react-hot-toast";
import { useAsyncError, useNavigate } from "react-router-dom";
import * as Axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useCookies } from "react-cookie";
import baseurl from "../constants/global";
import enFlag from "../assets/img/united-states.png";
import frFlag from "../assets/img/france.png";
import { IoEyeSharp } from "react-icons/io5";
import base_url from "../constants/global";

import { ImHome } from "react-icons/im";
import { FaTools } from "react-icons/fa";
import { MdMeetingRoom } from "react-icons/md";
import { RiMapPinFill } from "react-icons/ri";
import { FaToolbox } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { RiLogoutBoxRFill } from "react-icons/ri";

import { MDBCheckbox } from "mdb-react-ui-kit";

var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

function Header_mobile() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [oldpassword, setOldpassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const [confirmpassword, setConfirmpassword] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [Messageoldpassword, setMessageOldpassword] = useState();
  const [Messagenewpassword, setMessageNewpassword] = useState();
  const [vuepassword, setVuepassword] = useState(false);
  const [Messageconfirmpassword, setMessageConfirmpassword] = useState();
  const [displayMenu, setDisplayMenu] = useState("d-none");
  const [displayRightMenu, setDisplayRightMenu] = useState("d-none");

  const handleLogout = () => {
    ReactSession.remove("id");
    ReactSession.remove("username");
    localStorage.removeItem("cachedData");
    navigate("/");
  };
  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
    //window.location.reload(false);
  }

  const check_verif = () => {
    if (vuepassword === false) {
      setVuepassword(true);
    } else {
      setVuepassword(false);
    }
  };
  const [permissions, setPermissions] = useState({
    permission: {
      per1: false,
      per2: false,
      per3: false,
      per4: false,
      per5: false,
      per6: false,
      per7: false,
      per8: false,
      per9: false,
      per10: false,
      per11: false,
      per12: false,
      per13: false,
      per14: false,
      per15: false,
      per16: false,
      per17: false,
      per18: false,
      per19: false,
      per20: false,
      per21: false,
      per22: false,
      per23: false,
      per24: false,
      per25: false,
      per26: false,
      per27: false,
      per28: false,
      per29: false,
      per30: false,
      per31: false,
      per32: false,
      per33: false,
      per34: false,
      per35: false,
      per36: false,
    },
  });

  const [permissiondata, setPermissiondata] = useState("");
  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");
  const loadPermissionData = () => {
    const userid = ReactSession.get("userid");
    const usertoken = ReactSession.get("usertoken");
    Axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/UserPermissions",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        setPermissiondata(response.data);

        var prevPerm = permissions;

        var json = response.data;

        Object.keys(json).forEach(function (key) {
          if (json[key].usrper_per_id === 1) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per1 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 2) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per2 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 3) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per3 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 4) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per4 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 5) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per5 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 6) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per6 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 7) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per7 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 8) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per8 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 9) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per9 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 10) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per10 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 11) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per11 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 12) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per12 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 13) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per13 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 14) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per14 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 15) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per15 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 16) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per16 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 17) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per17 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 18) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per18 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 19) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per19 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 20) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per20 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 21) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per21 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 22) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per22 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 23) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per23 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 24) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per24 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 25) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per25 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 26) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per26 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 27) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per27 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 28) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per28 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 29) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per29 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 30) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per30 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 31) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per31 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 32) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per32 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 33) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per33 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 34) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per34 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 35) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per35 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id === 36) {
            if (json[key].usrper_val === 1) {
              prevPerm.permission.per36 = true;
              setPermissions(prevPerm);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleCookie(cookies.i18next);
    loadPermissionData();
  }, []);
  const handleUser = () => {
    navigate("/users");
  };

  const handleNature = () => {
    navigate("/nature");
  };
  const handleSanction = () => {
    navigate("/sanction");
  };

  const change_password = () => {
    let verif = 0;
    if (oldpassword === "") {
      verif = 1;
      setMessageOldpassword("Old password is empty");
    }
    if (newpassword === "") {
      setMessageNewpassword("New password is empty");
      verif = 1;
    }
    if (confirmpassword === "") {
      verif = 1;
      setMessageConfirmpassword("You must confirm the new password");
    }

    if (verif === 0) {
      if (newpassword === confirmpassword) {
        Axios.post(baseurl + "/loginuser", {
          username: ReactSession.get("username"),
        }).then((response) => {
          if (response.data.result) {
            if (bcrypt.compareSync(oldpassword, response.data.ref)) {
              var hash = bcrypt.hashSync(newpassword, salt);
              Axios.post(baseurl + "/change_password", {
                username: ReactSession.get("username"),
                new_password: hash,
                id: ReactSession.get("id"),
              }).then((response) => {
                if (response.data.type_action === true) {
                  notifySuccess("Password has been changed");
                  setShowChangePassword(false);
                } else {
                  notifyError(response.data.erromsg);
                }
              });
            } else {
              notifyError("Actual password is incorrect");
            }
          }
        });
      } else {
        setMessageConfirmpassword(
          "The first password and the second password do not match"
        );
      }
    }
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const displayMenuHandle = () => {
    if (displayMenu == "d-none") {
      setDisplayMenu("d-flex");
    } else {
      setDisplayMenu("d-none");
    }
  };

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const handleModelClosechangePassword = () => {
    setShowChangePassword(false);
    setOldpassword("");
    setNewpassword("");
    setConfirmpassword("");
    setMessageOldpassword("");
    setMessageNewpassword("");
    setMessageConfirmpassword("");
  }

  useLayoutEffect(() => {
    if (!localStorage.getItem("cachedData"))
      navigate('/login')
  }, [])

  return (
    <>

      <Modal
        show={showChangePassword}
        onHide={handleModelClosechangePassword}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                Old password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="old-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setOldpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messageoldpassword}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                New password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="new-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setNewpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messagenewpassword}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                Confirm password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="old-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setConfirmpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messageconfirmpassword}
              </div>
            </div>
            <MDBCheckbox
              className="ml-30"
              name="flexCheck"
              onChange={check_verif}
              value={vuepassword}
              id="shwpssw"
              label="Show password"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModelClosechangePassword}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={change_password}>
            Change password
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        id="kt_header_mobile"
        className={"header-mobile bg-white d-print-none "}
      >
        <div className={"dropdown pt-0 mr-1"}>
          <Dropdown className="">
            <Dropdown.Toggle
              variant="dropdown-basic"
              className="w-auto h-40px text-white pt-0 pb-0"
            >
              <div
                className="btn p-0 ml-4"
                id="kt_header_mobile_toggle"
                onClick={() => displayMenuHandle()}
              >
                <span>
                  <i className="fas fa-bars"></i>
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-180px">
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  <ImHome className="align-madle mb-1 mr-2" />
                  Home
                </span>
                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => handleUser()}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  <FaTools className="align-madle mb-1 mr-2" />
                  Maintenance
                </span>
                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => handleSanction()}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  <MdMeetingRoom className="align-madle mb-1 mr-2" />
                  Chambre
                </span>
                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => handleNature("en")}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  <RiMapPinFill className="align-madle mb-1 mr-2" />
                  Site
                </span>
                <i className="menu-arrow pb-2"></i>

                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => handleNature("en")}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  <FaToolbox className="align-madle mb-1 mr-2" />
                  equipment
                </span>
                <i className="menu-arrow pb-2"></i>

                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => handleNature("en")}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  <RiAdminFill className="align-madle mb-1 mr-2" />
                  Administrator
                </span>
                <i className="menu-arrow pb-2"></i>

                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>

              <Dropdown.Item
                href="#"
                className="navi-link"
                onClick={() => handleLogout("fr")}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  <RiLogoutBoxRFill className="align-madle mb-1 mr-2" />
                  Logout
                </span>
                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <a href="/home">
          <img alt="Logo" src={logo} className="logo-default max-h-30px" />
        </a>

        <div className="d-flex">
          <div className="dropdown pt-0">
            <a className="menu-link">
              <Dropdown>
                <Dropdown.Toggle
                  variant="dropdown-basic"
                  className="w-auto h-40px text-white pt-0 pb-0"
                >
                  <div
                    className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
                    id="dr_change_language"
                  >
                    <span className="symbol bg-light symbol-35">
                      <span className="symbol-label text-dark-75 font-size-h5 font-weight-bold bg-white-o-30">
                        <Language />
                      </span>
                    </span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-180px">
                  <Dropdown.Item
                    href="#"
                    onClick={() => { }}
                    className="navi-link border-bottom"
                  >
                    <span className="symbol symbol-20 mr-1">
                      <img src={enFlag} width={20} height={20} />
                    </span>
                    <span className="navi-text font-weight-bolder">EN</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => handleCookie("fr")}
                    className="navi-link"
                  >
                    <span className="symbol symbol-20 mr-1">
                      <img src={frFlag} width={20} height={20} />
                    </span>
                    <span className="navi-text font-weight-bolder">FR</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </a>
          </div>

          <div className="w-full dropdown mr-0 pt-0">
            <a className="menu-link">
              <Dropdown>
                <Dropdown.Toggle
                  variant="dropdown-basic"
                  className="w-100 h-40px text-white p-0 m-0 pt-0 pb-0"
                >
                  <div
                    className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
                    id="open-notification"
                  >
                    <i className="far fa-bell text-dark-75 mr-2"></i>
                    <span className="label label-rounded label-danger">
                      <div id="unreadnot">0</div>
                    </span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-full">
                  <Dropdown.Item href="#">
                    <form className="p-0">
                      <div className="d-flex pt-0 pb-4 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                        <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-15 font-weight-bolder w-200px">
                          {"Notifications"}
                        </div>
                      </div>

                      <div className="scroll scroll-push text-center h-100px">
                        <div id="notificationcontent" className="pt-11"></div>
                      </div>
                    </form>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header_mobile;
