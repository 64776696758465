import React, { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Footer from "./footer";
import Header from "./header";
import Header_mobile from "./header_mobile";
import base_url from "../constants/global";
import { useTranslation } from "react-i18next";
import { createGlobalState } from "react-hooks-global-state";
import { useCookies } from "react-cookie";

const { setGlobalState, useGlobalState } = createGlobalState({
  show: false,
  showview: false,
  maintenancedata: [],
});

function Follow_up() {
  const { t, i18n } = useTranslation();
  const [maintenances, setMaintenances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maintenancesPerPage, setMaintenancesPerPage] = useState(10);

  var userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");
  var userrole = ReactSession.get("userrole");

  const loadData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/getregister",
      data: { token: usertoken, userid: userid, userrole: userrole },
    })
      .then((response) => {
        if (response.data.error) return null;
        setMaintenances(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    loadData();
    setLoading(false);
  }, []);
  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
    //window.location.reload(false);
  }

  const indexOfLastPost = currentPage * maintenancesPerPage;
  const indexOfFirstPost = indexOfLastPost - maintenancesPerPage;
  const currentPosts = maintenances.slice(indexOfFirstPost, indexOfLastPost);

  function ViewMaintenance(props) {
    ///

    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Follow_up.ViewMaintenance")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-4 border-right pt-3">
                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.FollowUpID")} :{" "}
                  </label>
                  <p id="followupid-v" className="pl-2">
                    {props.info.fur_id}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.MaintenanceID")}:{" "}
                  </label>
                  <p id="ticketid-v" className="pl-2">
                    {props.info.m_id}
                  </p>
                  <p id="ticketid-printdata" className="pl-2"></p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.Applicant")} :{" "}
                  </label>
                  <p id="createdby-v" className="pl-2">
                    {props.info.Sent_by}
                  </p>
                </div>

                <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DateOfDemand")}:{" "}
                  </label>
                  <p id="createddate-v" className="pb-2">
                    {props.info.m_created_date}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.Site")} :{" "}
                  </label>
                  <p id="equipementsite-v" className="pb-2">
                    {props.info.site_name}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.Room")}:{" "}
                  </label>
                  <p id="equipementdesc-v" className="pb-2">
                    {props.info.room_number}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 pt-3">
                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DescriptionOfComplaint")} :{" "}
                  </label>
                  <p id="ticketdesc-v" className="pb-2">
                    {props.info.m_description}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DiagnosisResult")}:{" "}
                  </label>
                  <p id="diagnosisdesc-v" className="pb-2">
                    {props.info.msup_diagnosis_description}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DescriptionWorkToBeDone")} :{" "}
                  </label>
                  <p id="workdesc-v" className="pb-2">
                    {props.info.msup_work_to_be_done}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.AssistantType")}:{" "}
                  </label>
                  <p id="supportType-v" className="pl-2">
                    {props.info.msup_support_type}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.Assistant")}:{" "}
                  </label>
                  <p id="assignedto-v" className="pl-2">
                    {props.info.Assigned_to}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.WorkStartDate")}:{" "}
                  </label>
                  <p id="assignationdate-v" className="pb-2">
                    {props.info.msup_intervention_date}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.ExpectedDateOfEndOfWork")}:{" "}
                  </label>
                  <p id="resolveddate-v" className="pb-2">
                    {props.info.msup_expected_ended_date}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 border-left pt-3">
                <div className="form-group  mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.CurrentStatusOTheIntervention")} :{" "}
                  </label>
                  <p id="status-v" className="pb-2">
                    {props.info.mst_name}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.RealStartDate")} :{" "}
                  </label>
                  <p id="starteddate-v" className="pb-2">
                    {props.info.msup_real_started_time}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DescriptionOfTheWorkDone")}:{" "}
                  </label>
                  <p id="workdonedesc-v" className="pb-2">
                    {props.info.msup_work_done}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.TotalCostOfTheIntervention")} :{" "}
                  </label>
                  <p id="ticketcost-v" className="pb-2">
                    {props.info.msup_total_intervention_cost}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.RemarkAndObservations")} :{" "}
                  </label>
                  <p id="ticketremark-v" className="pb-2">
                    {props.info.msup_remark}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.RealEndDate")}:{" "}
                  </label>
                  <p id="starteddate-v" className="pb-2">
                    {props.info.msup_resolved_date}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} variant="default">
              {t("Follow_up.Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const Maintenances = ({ data, loading }) => {
    const [showModal, setShowModal] = useGlobalState("show");
    const [showModalView, setShowModalView] = useGlobalState("showview");
    const [getmaintenancedata, setMaintenanceData] =
      useGlobalState("maintenancedata");

    const handleViewModal = (d) => {
      setShowModalView(true);
      setMaintenanceData(d);
    };

    if (loading) {
      return <h2>Loading...</h2>;
    }

    return (
      <>
        <tbody id="resultdata">
          {currentPosts.length ? (
            currentPosts.map((d, index) => (
              <tr key={index} id='td"+ d[i].m_id +"' className="data">
                <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                <td>
                  <button
                    onClick={() => {
                      handleViewModal(d);
                    }}
                    className="btn pt-0 pb-0 w-15px mr-3 py-0 ml-1"
                  >
                    <i className="far fa-list-alt"></i>
                  </button>
                </td>
                <td id="tb-maintenanceid" className="text-left">
                  {d.msup_intervention_date}
                </td>
                <td id="tb-subject" className="text-left">
                  {d.F_Created_by}
                </td>
                <td id="tb-description" className="text-left">
                  {"-"}
                </td>

                <td id="tb-assigned-to" className="text-left">
                  {d.eq_model}
                </td>
                <td id="tb-sentby" className="text-left">
                  {d.eq_description}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.site_name}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.eqq_value}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.msup_work_done}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.Assigned_to}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.msup_id}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.msup_assignation_date - d.msup_expected_ended_date}
                </td>
                <td id="tb-created-date" className="text-left">
                  {"-"}
                </td>

                <td id="tb-created-date" className="text-left">
                  {d.msup_intervention_date}
                </td>

                <td id="tb-status" className="text-left">
                  {d.m_status == 1 || d.m_status == 4 ? (
                    <span className="available py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 2 || d.m_status == 5 ? (
                    <span className="on-job py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 3 || d.m_status == 7 ? (
                    <span className="done py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 6 ? (
                    <span className="assigned py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 8 ? (
                    <span className="canceled py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 11 ? (
                    <span className="suspended py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9} className="text-center pt-20 fs-16">
                No data found
              </td>
            </tr>
          )}
        </tbody>

        {showModalView ? (
          <ViewMaintenance
            show={showModalView}
            onHide={() => setShowModalView(false)}
            info={getmaintenancedata}
          />
        ) : null}
      </>
    );
  };

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage == totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    //alert(currentPage)

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginate(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage == number ? (
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a onClick={() => paginate(number)} href="#" className="">
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a onClick={() => paginate(totalPages)} href="#" className="">
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //////////////////////

  return (
    <>
      <Header_mobile />
      <Header />

      <div
        className="d-flex flex-column flex-column-fluid "
        style={{ minHeight: "85vh" }}
      >
        <div className="d-flex flex-column-fluid">
          <div className=" container" id="pageBlocking">
            <div className="d-print-none mt-20"></div>

            <div className="row">
              <div className="col-xl-12">
                <div
                  className="card card-custom d-print-none"
                  id="kt_blockui_card"
                >
                  <div className="card-header">
                    <div className="card-title">
                      <h3 className="card-label">
                        {t("Follow_up.FollowUpRegister")}
                      </h3>
                      <button
                        id="btn-refrech"
                        className="btn btn-sm bg-light2 ml-4 w-45px pl-4 title='Refresh'"
                      >
                        <i className="la la-sync fs-17"></i>
                      </button>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="dropdown dropdown-inline mr-4"
                        data-toggle="tooltip"
                      >
                        <button
                          className="btn btn-light font-weight-bolder border"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {t("Follow_up.Export")}
                        </button>
                        <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right w-150px">
                          <ul className="navi navi-hover py-5">
                            <li className="navi-item">
                              <a
                                href="#"
                                id="export-excel"
                                className="navi-link"
                              >
                                <span className="navi-icon">
                                  <i className="la la-file-excel-o"></i>
                                </span>
                                <span className="navi-text">Excel</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <button
                        id="clearfilter"
                        className="btn btn-light font-weight-bolder border mr-3"
                      >
                        {t("Follow_up.ClearFilter")}
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table id="result" className="table min-h-300px">
                        <thead>
                          <tr>
                            <th scope="col" className="w-25px">
                              #
                            </th>
                            <th scope="col" className="w-100px"></th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.INTERVENTIONDATE").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketidModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.CREATEDBY").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketsubjectModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.1").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketdescModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.2").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#priorityModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.3").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#statusModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.4").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filtercreatedModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.5").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.6").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.7").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.8").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.9").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.10").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.11").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("Follow_up.STATUS").toUpperCase()}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                          </tr>
                        </thead>

                        <Maintenances data={maintenances} loading={loading} />
                      </table>
                    </div>
                  </div>

                  <div className="card-footer pt-2 pb-2">
                    <div className="d-flex justify-content-center">
                      <Pagination
                        postsPerPage={maintenancesPerPage}
                        totalPosts={maintenances.length}
                        paginate={paginate}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10 d-print-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Follow_up;
