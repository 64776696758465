import React, { Component, useState, useRef, useEffect } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Header from "./header";
import Header_mobile from "./header_mobile";
import Footer from "./footer";
import base_url from "../constants/global";
import { createGlobalState } from "react-hooks-global-state";
import Snackbar from "./Snackbar";
import toast, { Toaster } from "react-hot-toast";
import logo from "../assets/img/auto-logo.jpg";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Container, Row, Col } from "react-bootstrap";
import Toggle from "./toggle";
import download from "downloadjs";
import { hover } from "@testing-library/user-event/dist/hover";

const { setGlobalState, useGlobalState } = createGlobalState({
  highlevel: "",
  show: false,
  showcancel: false,
  showclose: false,
  showview: false,
  showseen: false,
  showModalSign: false,
  showassign: false,
  showeditassign: false,
  maintenancedata: [],
  snackbarmsg: "",
  snackbartype: "success",
  showsnackbar: false,
  reqRef: "",
  roomCode: "",
  duration: "",
  observation: "",
  formRef: "",
  reqSupport: "",
  reqStartDate: "",
  reqSupportType: "",
  reqRole: "",
  reqDate: "",
  reqDiagnosis: "",
  reqBy: "",
  roomNum: "",
  roomSite: "",
  reqDesc: "",
  reqSubject: "",

  reqPrio: "",
  reqWorkToBeDone: "",
  reqEndDate: "",
  reqWorkDone: "",
  reqUseMaterials: "",
  maintenanceForm: "",
  supportForm: "",
  external_supplier: "",
  reqDep: "",
  eq_code: "",
  reqtype: "",
  eq_description: "",
  roomresident: "",
});
const notifySuccess = (msg) =>
  toast.success(msg, {
    style: {
      padding: "16px",
      backgroundColor: "rgba(124, 140, 135, 0.70)",
      color: "#FFF",
      maxWidth: "280px",
      textAlign: "left",
      fontSize: "13px",
    },
  });

const notifyError = (msg) =>
  toast.error(msg, {
    style: {
      padding: "16px",
      backgroundColor: "rgba(124, 140, 135, 0.70)",
      color: "#FFF",
      maxWidth: "280px",
      textAlign: "left",
      fontSize: "13px",
    },
  });

function Example(props) {
  const { t, i18n } = useTranslation();

  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");
  const [disabled, setDisabled] = useState("");
  const [priority, setPriority] = useState("0");
  const [mt_type, setMt_type] = useState(-1);
  const [room, setRoom] = useState("");
  const [site, setSite] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [commentpriority, setcommentpriority] = useState("");

  const [messagePriority, setMessagePriority] = useState("");
  const [messageType, setMessageType] = useState("");
  const [messageRoom, setMessageRoom] = useState("");
  const [messageSite, setMessageSite] = useState("");
  const [messageSubject, setMessageSubject] = useState("");
  const [messageDescription, setMessageDescription] = useState("");
  const [viewImages, setViewImages] = useState(false);
  const [message, setMessage] = useState("");

  const snackbarRef = useRef(null);

  const [showModal, setShowModal] = React.useState(false);

  const myRefnameU = useRef(null);

  const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
  const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");

  const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

  const sendImagesToBackend = async (id, selectedImages) => {
    try {
      const formData = new FormData();
      formData.append("id", id);

      for (let i = 0; i < selectedImages.length; i++) {
        formData.append("images[]", selectedImages[i]);
      }

      const response = await axios.post(
        base_url + "Users/add_images",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImages([]);
      setImagesS([]);
      setViewImages(false);
    } catch (error) {
      console.error("Erreur lors de l'envoi des images :", error);
    }
  };

  const handleSubmitMaintenance = (e) => {
    e.preventDefault();

    if (mt_type === "1") {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/create_maintenance",
        data: {
          token: usertoken,
          priority: priority,
          equipment: 0,
          room: room,
          site: site,
          subject: subject,
          description: description,
          userid: userid,
          m_type: mt_type,
          messageHigth: commentpriority,
        },
      })
        .then((response) => {
          if (response.data.Validation == "FALSE") {
            setMessagePriority(response.data.priority);

            setMessageRoom(response.data.room);
            setMessageSite(response.data.site);
            setMessageSubject(response.data.subject);
            setMessageDescription(response.data.description);
          } else {
            setMessagePriority("");
            setMessageType("");
            setMessageRoom("");
            setMessageSite("");
            setMessageSubject("");
            setMessageDescription("");
            setSite("");
            console.log(images);
            sendImagesToBackend(response.data.InsertedId, imagesS);

            if (response.data.Inserted == "TRUE") {
              myRefnameU.current.click();
              loadData();
              notifySuccess(response.data.Result);
            } else {
              notifyError(response.data.Result);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (mt_type === "2") {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/create_maintenance",
        data: {
          token: usertoken,
          priority: priority,
          equipment: equipment,
          site: site,
          subject: subject,
          description: description,
          userid: userid,
          m_type: mt_type,
          room: 0,
          messageHigth: commentpriority,
        },
      })
        .then((response) => {
          if (response.data.Validation == "FALSE") {
            setMessagePriority(response.data.priority);

            setMessageRoom(response.data.room);
            setMessageSite(response.data.site);
            setMessageSubject(response.data.subject);
            setMessageDescription(response.data.description);
          } else {
            setMessagePriority("");
            setMessageType("");
            setMessageRoom("");
            setMessageSite("");
            setMessageSubject("");
            setMessageDescription("");
            setSite("");

            if (response.data.Inserted == "TRUE") {
              myRefnameU.current.click();
              //setRoom = '';
              loadData();
              notifySuccess(response.data.Result);
            } else {
              notifyError(response.data.Result);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const myRefname = useRef(null);

  const handleClick = () => {
    myRefname.current.click();
    //handleClose();
  };

  const [maintenances, setMaintenances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maintenancesPerPage, setMaintenancesPerPage] = useState(10);

  const loadData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetMaintenances",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        if (response.data.error) return null;
        setMaintenances(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);
  function handleCookie(e) {
    setCookie("i18next", e);
    i18n.changeLanguage(e);
    //window.location.reload(false);
  }
  useEffect(() => {
    setLoading(true);
    loadData();
    setLoading(false);
  }, []);

  //Get current users
  const indexOfLastPost = currentPage * maintenancesPerPage;
  const indexOfFirstPost = indexOfLastPost - maintenancesPerPage;
  const currentPosts = maintenances.slice(indexOfFirstPost, indexOfLastPost);

  function DropdownMenu({ d }) {
    const myRefname = useRef(null);

    const handleUpdateModal = () => {
      setShowModal(true);
      setMaintenanceData(d);
    };

    const handleEditAssign = () => {
      setShowModalEditAssign(true);
      setMaintenanceData(d);
    };

    const handleCancelModal = () => {
      setShowModalCancel(true);
      setMaintenanceData(d);
    };

    const handleViewModal = () => {
      setShowModalView(true);
      setMaintenanceData(d);
    };

    const [modalShow, setModalShow] = React.useState(false);
    const [showModal, setShowModal] = useGlobalState("show");
    const [showModalCancel, setShowModalCancel] = useGlobalState("showcancel");
    const [showModalEditAssign, setShowModalEditAssign] =
      useGlobalState("showeditassign");
    const [showModalView, setShowModalView] = useGlobalState("showview");
    const [showModalSeen, setShowModalSeen] = useGlobalState("showseen");
    const [showModalAssign, setShowModalAssign] = useGlobalState("showassign");
    const [getmaintenancedata, setMaintenanceData] =
      useGlobalState("maintenancedata");

    const [permissions, setPermissions] = useState({
      permission: {
        per1: false,
        per2: false,
        per3: false,
        per4: false,
        per5: false,
        per6: false,
        per7: false,
        per8: false,
        per9: false,
        per10: false,
        per11: false,
        per12: false,
        per13: false,
        per14: false,
        per15: false,
        per16: false,
        per17: false,
        per18: false,
        per19: false,
        per20: false,
        per21: false,
        per22: false,
        per23: false,
        per24: false,
        per25: false,
        per26: false,
        per27: false,
        per28: false,
        per29: false,
        per30: false,
        per31: false,
        per32: false,
        per33: false,
        per34: false,
        per35: false,
        per36: false,
      },
    });

    const [permissiondata, setPermissiondata] = useState("");
    const userid = ReactSession.get("userid");
    const usertoken = ReactSession.get("usertoken");

    const loadPermissionData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/UserPermissions",
        data: { token: usertoken, userid: userid },
      })
        .then((response) => {
          setPermissiondata(response.data);

          var prevPerm = permissions;

          var json = response.data;

          Object.keys(json).forEach(function (key) {
            if (json[key].usrper_per_id == 1) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per1 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 2) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per2 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 3) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per3 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 4) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per4 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 5) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per5 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 6) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per6 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 7) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per7 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 8) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per8 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 9) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per9 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 10) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per10 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 11) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per11 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 12) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per12 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 13) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per13 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 14) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per14 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 15) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per15 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 16) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per16 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 17) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per17 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 18) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per18 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 19) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per19 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 20) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per20 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 21) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per21 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 22) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per22 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 23) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per23 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 24) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per24 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 25) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per25 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 26) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per26 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 27) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per27 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 28) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per28 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 29) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per29 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 30) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per30 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 31) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per31 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 32) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per32 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 33) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per33 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 34) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per34 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 35) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per35 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 36) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per36 = true;
                setPermissions(prevPerm);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      loadPermissionData();
    }, []);

    return (
      <>
        <Dropdown className="dropdown">
          <Dropdown.Toggle
            variant="dropdown-basic"
            className="w-60px py-0 text-white p-0 m-0"
          >
            <div
              className="dropdown dropdown-inline bg-light2 py-0 rounded"
              data-toggle="dropdown"
            >
              <a type="button" className="btn btn-light2 btn-icon py-0 btn-sm">
                <i className="ki ki-bold-more-hor"></i>
              </a>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="">
            {d.msup_status != 7 &&
            d.msup_status != 3 &&
            d.msup_status != 8 &&
            permissions.permission.per2 == true ? (
              <Dropdown.Item
                href="#"
                onClick={handleUpdateModal}
                className="dropdown-item border-bottom border-top"
              >
                <i className="far fa-edit"></i>{" "}
                <span className="pl-3">
                  {t("ListMaintenance.EditMaintenance")}
                </span>
              </Dropdown.Item>
            ) : null}

            {d.msup_status != 7 &&
            d.msup_status != 3 &&
            d.msup_status != 8 &&
            permissions.permission.per3 == true ? (
              <Dropdown.Item
                href="#"
                onClick={handleCancelModal}
                className="dropdown-item border-bottom"
              >
                <i className="la la-remove"></i>{" "}
                <span className="pl-3">
                  {t("ListMaintenance.CancelMaintenance")}
                </span>
              </Dropdown.Item>
            ) : null}

            {d.m_status == 6 && permissions.permission.per5 == true ? (
              <Dropdown.Item
                href="#"
                onClick={handleEditAssign}
                className="dropdown-item border-bottom border-top"
              >
                <i className="far fa-edit"></i>{" "}
                <span className="pl-3">{t("ListMaintenance.EditAssign")}</span>
              </Dropdown.Item>
            ) : null}

            {permissions.permission.per7 == true ? (
              <Dropdown.Item
                href="#"
                onClick={handleViewModal}
                className="dropdown-item border-bottom"
              >
                <i className="far fa-list-alt"></i>{" "}
                <span className="pl-3">
                  {t("ListMaintenance.ViewMaintenance")}
                </span>
              </Dropdown.Item>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  const [priorityu, setPriorityU] = useState("");
  const [siteu, setSiteU] = useState("");
  const [subjectU, setSubjectU] = useState("");
  const [descriptionu, setDescriptionU] = useState("");

  //  onClick={()=>{Trigger(props.info)}}

  function UpdateData(props) {
    const [sitedata, setSitedata] = useState([]);
    const [prioritydata, setPrioritydata] = useState([]);
    const [sdata, setSdata] = useState([]);
    const [subjectData, setsubjectData] = useState([]);

    const usertoken = ReactSession.get("usertoken");

    const loadSiteData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetSites",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setSitedata(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const snackbarRef = useRef(null);

    const loadPriorityData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetPriorities",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setPrioritydata(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // const loadTypeData = () => {
    //   axios({
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     url: base_url + "Users/GetType",
    //     data: { token: usertoken },
    //   })
    //     .then((response) => {
    //       if (response.data.error) return null;
    //       setMaintenace_Type(response.data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // };

    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///

    const [mid, setMid] = useState("");
    const [prio, setPrio] = useState("");
    const [mroom, setMRoom] = useState("");
    const [afsite, setAfSite] = useState("");
    const [msubject, setMSubject] = useState("");
    const [mdesc, setMDesc] = useState("");
    const [eq_id, setEq_id] = useState("");

    const [messagePriorityu, setMessagePriorityU] = useState("");
    const [messageRoomu, setMessageRoomU] = useState("");
    const [messageSiteu, setMessageSiteU] = useState("");
    const [messageSubjectu, setMessageSubjectU] = useState("");
    const [messageDescriptionu, setMessageDescriptionU] = useState("");
    const [messageU, setMessageU] = useState("");

    const [roomsdatau, setRoomsdatau] = useState("");
    const [roomdatau, setRoomdatau] = useState("");
    const [roomsitedatau, setRoomSitedatau] = useState("");

    const loadRoomsDataU = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetRooms",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setRoomsdatau(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const loadSubjectU = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetSubject",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setsubjectData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const loadRoomSiteDatau = (e) => {
      e.preventDefault();

      setRoomdatau(e.target.value);
      setMRoom(e.target.value);
      setDisabled("");

      if (e.target.value.length > 0) {
        setMessageRoomU("");
        setMessageSiteU("");

        axios({
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: base_url + "Users/GetRoomData",
          data: { token: usertoken, roomid: e.target.value },
        })
          .then((response) => {
            setRoomSitedatau(response.data.room_site);
            setAfSite(response.data.room_site);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setRoomSitedatau("");
        setAfSite("");
      }
    };

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    const handleSubmitUMaintenance = (e) => {
      e.preventDefault();

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/update_maintenance",
        data: {
          token: usertoken,
          mid: mid,
          priority: prio,
          room: mroom,
          site: afsite,
          subject: msubject,
          description: mdesc,
          equipment: eq_id,
        },
      })
        .then((response) => {
          if (response.data.Validation == "FALSE") {
            setMessagePriorityU(response.data.priority);
            setMessageRoomU(response.data.room);
            setMessageSiteU(response.data.site);
            setMessageSubjectU(response.data.subject);
            setMessageDescriptionU(response.data.description);
          } else {
            setMessagePriorityU("");
            setMessageRoomU("");
            setMessageSiteU("");
            setMessageSubjectU("");
            setMessageDescriptionU("");

            if (response.data.Inserted == "TRUE") {
              //setMessageU(response.data.Result);
              myRefnameU.current.click();
              loadData();
              //setSnackbarType("success");
              notifySuccess(response.data.Result);
            } else {
              //setSnackbarType("fail");
              //setMessageU(response.data.Result);
              notifyError(response.data.Result);
            }

            //setSnackbarMessage(response.data.Result);
            //setShowsnackbar(true);
            //setTimeout(() => {
            //    setShowsnackbar(false);
            //}, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const [images, setImages] = useState([]);

    const handleDrop = (e) => {
      e.preventDefault();
      const newImages = [...images];

      if (e.dataTransfer.files) {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          newImages.push(e.dataTransfer.files[i]);
          console.log(e.dataTransfer.files[i]);
        }
      }

      setImages(newImages);
    };

    const handleClick1 = (e) => {
      const newImages = [...images];
      newImages.push(URL.createObjectURL(e.target.files[0]));
      setImages(newImages);
    };

    useEffect(() => {
      loadRoomsDataU();
      loadSiteData();
      loadPriorityData();
      loadSubjectU();

      setMid(props.info.m_id);
      setPrio(props.info.m_priority);
      setMRoom(props.info.m_room);
      setAfSite(props.info.m_site);
      setMSubject(props.info.m_subject);
      setMDesc(props.info.m_description);
      setMt_type(props.info.m_type);
      setEq_id(props.info.eq_id);
    }, []);

    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("ListMaintenance.update_maintenance")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitUMaintenance}>
              <div className="form-group mb-4 mt-3">
                <label className="font-weight-bold">
                  {t("ListMaintenance.Maintenance_type")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <select
                  id="ticket-type"
                  onChange={(e) => {
                    setMt_type(e.target.value);
                    setRoom("");
                    setEquipment("");
                    setSite("");
                  }}
                  name="priority"
                  className="form-control rounded-0"
                  value={mt_type}
                  disabled
                >
                  {}
                  <option> </option>
                  {maintenace_Type.length
                    ? maintenace_Type.map((d) => (
                        <option key={d.mt_id} value={d.mt_id}>
                          {" "}
                          {cookies.i18next === "fr"
                            ? d.mt_value
                            : d.mt_value_eng}{" "}
                        </option>
                      ))
                    : null}
                </select>
                <div id="ticketprio-error" className="text-danger pt-2">
                  {messageType}
                </div>
              </div>
              <div className="form-group mb-4 mt-3">
                <label className="font-weight-bold">
                  {t("ListMaintenance.priority")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <select
                  id="ticket-priority"
                  onChange={(e) => {
                    setPrio(e.target.value);
                  }}
                  value={prio}
                  name="priority"
                  className="form-control rounded-0"
                >
                  <option> </option>
                  {prioritydata.length
                    ? prioritydata.map((d) => (
                        <option key={d.mprio_id} value={d.mprio_id}>
                          {" "}
                          {d.mprio_description}{" "}
                        </option>
                      ))
                    : null}
                </select>
                <div id="ticketprio-error" className="text-danger pt-2">
                  {messagePriorityu}
                </div>
              </div>

              {mt_type === "1" ? (
                <div className="form-group mb-4">
                  <label className="font-weight-bold">
                    {t("ListMaintenance.rooms")}
                    <span className="text-danger pl-3"></span>
                  </label>
                  <select
                    id="room"
                    onChange={loadRoomSiteData}
                    name="room"
                    className="form-control bg-white rounded-0"
                    value={mroom}
                  >
                    <option> </option>
                    {roomsdata.length
                      ? roomsdata.map((d) => (
                          <option key={d.room_id} value={d.room_id}>
                            {" "}
                            {d.room_number}{" "}
                          </option>
                        ))
                      : null}
                  </select>
                  <div id="site-error" className="text-danger pt-2">
                    {messageRoom}
                  </div>
                </div>
              ) : mt_type === "2" ? (
                <div className="form-group mb-4">
                  <label className="font-weight-bold">
                    {t("Equipment.EquipmentCode")}
                    <span className="text-danger pl-3"></span>
                  </label>
                  <select
                    id="room"
                    onChange={loadEquipmentSiteData_eq}
                    name="room"
                    className="form-control bg-white rounded-0"
                    value={eq_id}
                  >
                    <option></option>
                    {equipmentData.length
                      ? equipmentData.map((d) => (
                          <option key={d.eq_id} value={d.eq_id}>
                            {" "}
                            {d.eq_description}{" "}
                          </option>
                        ))
                      : null}
                  </select>
                  <div id="site-error" className="text-danger pt-2">
                    {messageRoom}
                  </div>
                </div>
              ) : null}

              <div className="form-group mb-4">
                <label className="font-weight-bold">
                  {t("ListMaintenance.Sites")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <select
                  id="site"
                  disabled={!disabled}
                  value={afsite}
                  name="site"
                  className="form-control bg-white rounded-0"
                >
                  <option> </option>
                  {sitedata.length
                    ? sitedata.map((d) => (
                        <option key={d.site_id} value={d.site_id} selected>
                          {" "}
                          {d.site_name}{" "}
                        </option>
                      ))
                    : null}
                </select>
                <div id="site-error" className="text-danger pt-2">
                  {messageSiteu}
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="font-weight-bold">
                  {t("ListMaintenance.Subject")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <select
                  id="subjest"
                  name="subjest"
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  value={msubject}
                  className="form-control bg-white rounded-0"
                >
                  <option> </option>
                  {subjectData.length
                    ? subjectData.map((d) =>
                        mt_type === "1" ? (
                          d.nature_groupe === "1" ? (
                            <option key={d.nature_id} value={d.nature_id}>
                              {" "}
                              {d.nature_name}{" "}
                            </option>
                          ) : null
                        ) : mt_type === "2" ? (
                          d.nature_groupe === "2" ? (
                            <option key={d.nature_id} value={d.nature_id}>
                              {" "}
                              {d.nature_name}{" "}
                            </option>
                          ) : null
                        ) : null
                      )
                    : null}
                </select>
                <div id="ticketsubject-error" className="text-danger pt-2">
                  {messageSubjectu}
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="font-weight-bold">
                  {t("ListMaintenance.MaintenanceDescription")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <textarea
                  id="ticketdesc"
                  onChange={(e) => setMDesc(e.target.value)}
                  name="description"
                  value={mdesc}
                  className="form-control rounded-0"
                  rows={4}
                ></textarea>
                <div id="ticketdesc-error" className="text-danger pt-2">
                  {messageDescriptionu}
                </div>
              </div>

              <button type="submit" ref={myRefname} hidden className="btn">
                {t("ListMaintenance.cancel")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("ListMaintenance.submit")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("ListMaintenance.EditMaintenance")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function CancelMaintenance(props) {
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///

    const [mid, setMid] = useState("");
    const [messageU, setMessageU] = useState("");

    useEffect(() => {
      setMid(props.info.m_id);
    }, []);

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitUMaintenance = (e) => {
      e.preventDefault();

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/cancel_maintenance",
        data: { token: usertoken, mid: mid },
      })
        .then((response) => {
          if (response.data.Inserted == "TRUE") {
            //setMessageU(response.data.Result);
            myRefnameU.current.click();
            loadData();
            //setSnackbarType("success");
            notifySuccess(response.data.Result);
          } else {
            //setSnackbarType("fail");
            //setMessageU(response.data.Result);
            notifyError(response.data.Result);
          }
          //setSnackbarMessage(response.data.Result)
          //setShowsnackbar(true);
          //setTimeout(() => {
          //    setShowsnackbar(false);
          //}, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("ListMaintenance.submit")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitUMaintenance}>
              <div className="form-group mb-4 mt-3">
                <div className="text-center fs-17">
                  {t("ListMaintenance.cancelMessage")}
                  {props.info.m_id} ?
                </div>
              </div>
              <button type="submit" ref={myRefname} hidden className="btn">
                {t("ListMaintenance.submit")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("ListMaintenance.cancel")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("ListMaintenance.confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function SeenMaintenance(props) {
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    const snackbarRef = useRef(null);

    ///

    const [mid, setMid] = useState("");
    const [messageU, setMessageU] = useState("");

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    useEffect(() => {
      setMid(props.info.m_id);
    }, []);

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitSMaintenance = (e) => {
      e.preventDefault();

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/seen_maintenance",
        data: { token: usertoken, mid: mid },
      })
        .then((response) => {
          if (response.data.Inserted == "TRUE") {
            //setMessageU(response.data.Result);
            myRefnameU.current.click();
            loadData();
            //setSnackbarType("success");
            notifySuccess(response.data.Result);
          } else {
            //setSnackbarType("fail");
            //setMessageU(response.data.Result);
            notifyError(response.data.Result);
          }
          //setSnackbarMessage(response.data.Result);
          //setShowsnackbar(true);
          //setTimeout(() => {
          //    setShowsnackbar(false);
          //}, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Seen Maintenance
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitSMaintenance}>
              <div className="form-group mb-4 mt-3">
                <div className="text-center font-weight-bold fs-17">
                  {t("ListMaintenance.statusMessage")} {props.info.m_id} ?
                </div>
              </div>
              <button type="submit" ref={myRefname} hidden className="btn">
                {t("ListMaintenance.submit")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("ListMaintenance.cancel")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("ListMaintenance.confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function SignMaintenance(props) {
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    const snackbarRef = useRef(null);

    ///

    const [mid, setMid] = useState("");
    const [messageU, setMessageU] = useState("");

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    useEffect(() => {
      setMid(props.info.m_id);
    }, []);

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitSMaintenance = (e) => {
      e.preventDefault();

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/sign_maintenance",
        data: { token: usertoken, mid: mid },
      })
        .then((response) => {
          if (response.data.Inserted == "TRUE") {
            //setMessageU(response.data.Result);
            myRefnameU.current.click();
            loadData();
            //setSnackbarType("success");
            notifySuccess(response.data.Result);
          } else {
            //setSnackbarType("fail");
            //setMessageU(response.data.Result);
            notifyError(response.data.Result);
          }
          //setSnackbarMessage(response.data.Result);
          //setShowsnackbar(true);
          //setTimeout(() => {
          //    setShowsnackbar(false);
          //}, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Sign Maintenance
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitSMaintenance}>
              <div className="form-group mb-4 mt-3">
                <div className="text-center font-weight-bold fs-17">
                  Do you want to sign maintenance of : {props.info.m_id} ?
                </div>
              </div>
              <button type="submit" ref={myRefname} hidden className="btn">
                {t("ListMaintenance.submit")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("ListMaintenance.cancel")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("ListMaintenance.confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function AssignMaintenance(props) {
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///
    const [supportdata, setSupportData] = useState([]);

    const [assigningtime, setAssigningTime] = useState("");
    const [enddate, setEndDate] = useState("");
    const [supporttype, setSupportType] = useState("Internal");
    const [support, setSupport] = useState(0);
    const [diagnosis, setDiagnosis] = useState("");
    const [work, setWork] = useState("");
    const [messageAssTime, setMessageAssTime] = useState("");
    const [messageEndDate, setMessageEndDate] = useState("");
    const [messageSupType, setMessageSupType] = useState("");
    const [messageSup, setMessageSup] = useState("");
    const [messageDiagnosis, setMessageDiagnosis] = useState("");
    const [messageWork, setMessageWork] = useState("");
    const [messageA, setMessageA] = useState("");
    const [supplier_name, setSupplier_name] = useState("");

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitAMaintenance = (e) => {
      e.preventDefault();

      const userid = ReactSession.get("userid");
      if (supporttype === "External") {
        setSupport(-1);
      }
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/assign",
        data: {
          token: usertoken,
          mid: props.info.m_id,
          userid: userid,
          assigningtime: assigningtime,
          enddate: enddate,
          supporttype: supporttype,
          support: support,
          diagnosis: diagnosis,
          work: work,
          supplier_name: supplier_name,
        },
      })
        .then((response) => {
          if (response.data.Validation == "TRUE") {
            setMessageAssTime("");
            setMessageEndDate("");
            setMessageSupType("");
            setMessageSup("");

            if (response.data.Inserted == "TRUE") {
              //setSnackbarType("success");
              notifySuccess(response.data.Result);
            } else {
              //setSnackbarType("fail");
              notifyError(response.data.Result);
            }
            myRefnameU.current.click();
            loadData();
            //setSnackbarMessage(response.data.Result)
            //setShowsnackbar(true);
            //setTimeout(() => {
            //    setShowsnackbar(false);
            //}, 3000);
          } else {
            setMessageAssTime(response.data.start_date);
            setMessageEndDate(response.data.end_date);
            setMessageSupType(response.data.support_type);
            setMessageSup(response.data.support);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const loadSupportData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/display_supports",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setSupportData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      loadSupportData();
    }, []);

    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("ListMaintenance.AssignSupport")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitAMaintenance}>
              <div className="form-group row">
                <div className="col-lg-12">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.assigning_time")}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={(e) => setAssigningTime(e.target.value)}
                    id="supp-startdate"
                    className="form-control rounded-0"
                  />
                  <div id="startdate-error" className="text-danger pt-2">
                    {messageAssTime}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.support_type")}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      setSupportType(e.target.value);
                      if (e.target.value === "External") setSupport(-1);
                      else setSupport(0);
                    }}
                    id="type-support"
                    className="form-control rounded-0"
                  >
                    <option value="Internal">
                      {t("ListMaintenance.Internal")}
                    </option>
                    <option value="External">
                      {t("ListMaintenance.External")}
                    </option>
                  </select>
                  <div id="supporttype-error" className="text-danger pt-2">
                    {messageSupType}
                  </div>
                </div>
                {supporttype === "Internal" ? (
                  <div className="col-lg-6">
                    <label className="font-weight-bolder">
                      {t("ListMaintenance.Select_user")}
                      <span className="text-danger pl-3">*</span>
                    </label>
                    <select
                      onChange={(e) => {
                        setSupport(e.target.value);
                      }}
                      id="assign-support"
                      className="form-control rounded-0"
                    >
                      <option></option>
                      {supportdata.length
                        ? supportdata.map((d) => (
                            <option key={d.user_id} value={d.user_id}>
                              {" "}
                              {d.user_name}{" "}
                            </option>
                          ))
                        : null}
                    </select>
                    <div id="support-error" className="text-danger pt-2">
                      {messageSup}
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6">
                    <label className="font-weight-bolder">
                      Supplier name<span className="text-danger pl-3">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-0"
                      onChange={(e) => setSupplier_name(e.target.value)}
                    />
                    <div id="support-error" className="text-danger pt-2">
                      {messageSup}
                    </div>
                  </div>
                )}
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.diagnosis_desc")}
                  </label>
                  <textarea
                    onChange={(e) => setDiagnosis(e.target.value)}
                    id="diagnostic-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="diagnosis-error" className="text-danger pt-2">
                    {messageDiagnosis}
                  </div>
                </div>

                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DescriptionWorkToBeDone")}
                  </label>
                  <textarea
                    onChange={(e) => setWork(e.target.value)}
                    id="task-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="task-error" className="text-danger pt-2">
                    {messageWork}
                  </div>
                </div>
              </div>

              <button type="submit" ref={myRefname} hidden className="btn">
                Submit
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              Cancel
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              Assign
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function EditAssignMaintenance(props) {
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///
    const [supportdata, setSupportData] = useState([]);

    const [assigningtime, setAssigningTime] = useState("");
    const [enddate, setEndDate] = useState("");
    const [supporttype, setSupportType] = useState("Internal");
    const [support, setSupport] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [work, setWork] = useState("");
    const [messageAssTime, setMessageAssTime] = useState("");
    const [messageEndDate, setMessageEndDate] = useState("");
    const [messageSupType, setMessageSupType] = useState("");
    const [messageSup, setMessageSup] = useState("");
    const [messageDiagnosis, setMessageDiagnosis] = useState("");
    const [messageWork, setMessageWork] = useState("");
    const [messageA, setMessageA] = useState("");

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitEditAssign = (e) => {
      e.preventDefault();

      const userid = ReactSession.get("userid");

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/editassign",
        data: {
          token: usertoken,
          mid: props.info.m_id,
          userid: userid,
          assigningtime: assigningtime,
          enddate: enddate,
          supporttype: supporttype,
          support: support,
          diagnosis: diagnosis,
          work: work,
        },
      })
        .then((response) => {
          if (response.data.Validation == "TRUE") {
            setMessageAssTime("");
            setMessageEndDate("");
            setMessageSupType("");
            setMessageSup("");

            if (response.data.Inserted == "TRUE") {
              //setSnackbarType("success");
              notifySuccess(response.data.Result);
            } else {
              //setSnackbarType("fail");
              notifySuccess(response.data.Result);
            }
            myRefnameU.current.click();
            loadData();
            //setSnackbarMessage(response.data.Result)
            //setShowsnackbar(true);
            //setTimeout(() => {
            //    setShowsnackbar(false);
            //}, 3000);
          } else {
            setMessageAssTime(response.data.start_date);
            setMessageEndDate(response.data.end_date);
            setMessageSupType(response.data.support_type);
            setMessageSup(response.data.support);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const loadSupportData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/display_supports",
        data: { token: usertoken },
      })
        .then((response) => {
          if (response.data.error) return null;
          setSupportData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      loadSupportData();
      setAssigningTime(props.info.msup_intervention_date);
      setEndDate(props.info.msup_expected_ended_date);
      setSupportType(props.info.msup_support_type);
      setSupport(props.info.msup_assigned_to);
      setDiagnosis(props.info.msup_diagnosis_description);
      setWork(props.info.msup_work_to_be_done);
    }, []);

    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("ListMaintenance.edit_assign")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitEditAssign}>
              <div className="form-group row">
                <div className="col-lg-12">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.assigning_time")}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={(e) => setAssigningTime(e.target.value)}
                    value={assigningtime}
                    id="supp-startdate"
                    className="form-control rounded-0"
                  />
                  <div id="startdate-error" className="text-danger pt-2">
                    {messageAssTime}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.support_type")}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <select
                    value={supporttype}
                    onChange={(e) => setSupportType(e.target.value)}
                    id="type-support"
                    className="form-control rounded-0"
                  >
                    <option value="Internal">
                      {t("ListMaintenance.Internal")}
                    </option>
                    <option value="External">
                      {t("ListMaintenance.External")}
                    </option>
                  </select>
                  <div id="supporttype-error" className="text-danger pt-2">
                    {messageSupType}
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.Select_user")}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <select
                    value={support}
                    onChange={(e) => setSupport(e.target.value)}
                    id="assign-support"
                    className="form-control rounded-0"
                  >
                    <option></option>
                    {supportdata.length
                      ? supportdata.map((d) => (
                          <option key={d.user_id} value={d.user_id}>
                            {" "}
                            {d.user_name}{" "}
                          </option>
                        ))
                      : null}
                  </select>
                  <div id="support-error" className="text-danger pt-2">
                    {messageSup}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.diagnosis_desc")}
                  </label>
                  <textarea
                    value={diagnosis}
                    onChange={(e) => setDiagnosis(e.target.value)}
                    id="diagnostic-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="diagnosis-error" className="text-danger pt-2">
                    {messageDiagnosis}
                  </div>
                </div>

                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.desc_work_done")}
                  </label>
                  <textarea
                    value={work}
                    onChange={(e) => setWork(e.target.value)}
                    id="task-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="task-error" className="text-danger pt-2">
                    {messageWork}
                  </div>
                </div>
              </div>

              <button type="submit" ref={myRefname} hidden className="btn">
                Submit
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              Cancel
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              Edit Assignation
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const startPrinting = () => {
    setTimeout(() => {
      window.print();
    }, 100);
  };

  function ViewMaintenance(props) {
    ///

    const [permissions, setPermissions] = useState({
      permission: {
        per1: false,
        per2: false,
        per3: false,
        per4: false,
        per5: false,
        per6: false,
        per7: false,
        per8: false,
        per9: false,
        per10: false,
        per11: false,
        per12: false,
        per13: false,
        per14: false,
        per15: false,
        per16: false,
        per17: false,
        per18: false,
        per19: false,
        per20: false,
        per21: false,
        per22: false,
        per23: false,
        per24: false,
        per25: false,
        per26: false,
        per27: false,
        per28: false,
        per29: false,
        per30: false,
        per31: false,
        per32: false,
        per33: false,
        per34: false,
        per35: false,
        per36: false,
      },
    });
    const [allimages, sestallimages] = useState([]);

    const [permissiondata, setPermissiondata] = useState("");
    const userid = ReactSession.get("userid");

    const usertoken = ReactSession.get("usertoken");

    const loadPermissionData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/UserPermissions",
        data: { token: usertoken, userid: userid },
      })
        .then((response) => {
          setPermissiondata(response.data);

          var prevPerm = permissions;

          var json = response.data;

          Object.keys(json).forEach(function (key) {
            if (json[key].usrper_per_id == 1) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per1 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 2) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per2 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 3) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per3 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 4) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per4 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 5) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per5 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 6) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per6 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 7) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per7 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 8) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per8 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 9) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per9 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 10) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per10 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 11) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per11 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 12) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per12 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 13) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per13 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 14) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per14 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 15) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per15 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 16) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per16 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 17) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per17 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 18) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per18 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 19) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per19 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 20) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per20 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 21) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per21 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 22) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per22 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 23) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per23 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 24) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per24 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 25) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per25 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 26) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per26 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 27) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per27 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 28) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per28 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 29) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per29 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 30) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per30 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 31) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per31 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 32) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per32 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 33) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per33 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 34) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per34 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 35) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per35 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 36) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per36 = true;
                setPermissions(prevPerm);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const printRefname = useRef(null);

    const printMaintenance = () => {
      console.log(props.info);
      printRefname.current.click();
      setFormRef(props.info.m_id);
      setReqDate(props.info.m_created_date);
      setReqRole(props.info.r_user_role);
      setReqBy(props.info.Created_by);
      setRoomNum(props.info.room_number);
      setRoomSite(props.info.site_name);
      setReqDesc(props.info.m_description);
      setReqPrio(props.info.mprio_description);
      setReqSubject(props.info.nature_name);
      setRoomCode(props.info.room_number);
      setRoomresident(props.info.room_resident);
      setEq_code(props.info.eq_code);
      setReqtype(props.info.m_type);
      seteq_description(props.info.eq_description);
      sethighlevel(props.info.hightcomment);

      setReqDep(props.info.dep_name);
      setSupportForm("print-container2");
      setMaintenanceForm("print-container");
      startPrinting();
    };
    //PRINT SUPPORT

    const printSupport = () => {
      printRefname.current.click();
      setReqRef(props.info.msup_id);
      setRoomCode(props.info.room_number);
      setEq_code(props.info.eq_code);
      setReqtype(props.info.m_type);
      setEq_code(props.info.eq_code);
      setReqtype(props.info.m_type);
      seteq_description(props.info.eq_description);
      setRoomresident(props.info.room_resident);
      sethighlevel(props.info.hightcomment);

      if (props.info.msup_status === "7") {
        var date1 = new Date(props.info.msup_assignation_date);
        var date2 = new Date(props.info.msup_expected_ended_date);
        var Difference_In_Time =
          (date2.getTime() - date1.getTime()) / (1000 * 3600);

        SetDuration(Difference_In_Time);
      } else {
        SetDuration("-");
      }
      setObservation(props.info.msup_remark);

      setRoomNum(props.info.room_number);

      setFormRef(props.info.m_id);
      setReqDate(props.info.m_created_date);
      setReqBy(props.info.Created_by);
      setReqDesc(props.info.m_description);
      setReqSubject(props.info.nature_name);
      setDiagnosis(props.info.msup_diagnosis_description);
      setReqSupportType(props.info.msup_support_type);
      setReqStartDate(props.info.msup_intervention_date);
      setSupport(props.info.Assigned_to);
      setWorkDone(props.info.msup_work_done);
      setUseMaterials(props.info.msup_material_used);
      setExternal_supplier(props.info.msup_external_supplier_name);

      setRoomSite(props.info.site_name);
      setReqPrio(props.info.mprio_description);

      setWorkToBeDone(props.info.msup_work_to_be_done);
      setEndDate(props.info.msup_resolved_date);
      setDiagnosis(props.info.msup_work_to_be_done);

      setSupportForm("print-container");
      setMaintenanceForm("print-container2");

      startPrinting();
    };

    const [formRef, setFormRef] = useGlobalState("formRef");
    const [reqDate, setReqDate] = useGlobalState("reqDate");
    const [reqRole, setReqRole] = useGlobalState("reqRole");
    const [reqDep, setReqDep] = useGlobalState("reqDep");

    const [reqBy, setReqBy] = useGlobalState("reqBy");
    const [roomNum, setRoomNum] = useGlobalState("roomNum");
    const [roomSite, setRoomSite] = useGlobalState("roomSite");
    const [reqDesc, setReqDesc] = useGlobalState("reqDesc");
    const [reqSubject, setReqSubject] = useGlobalState("reqSubject");

    const [reqPrio, setReqPrio] = useGlobalState("reqPrio");

    const [reqRef, setReqRef] = useGlobalState("reqRef");
    const [roomresident, setRoomresident] = useGlobalState("roomresident");
    const [highlevel, sethighlevel] = useGlobalState("highlevel");
    const [roomCode, setRoomCode] = useGlobalState("roomCode");
    const [reqtype, setReqtype] = useGlobalState("reqtype");
    const [eq_code, setEq_code] = useGlobalState("eq_code");
    const [duration, SetDuration] = useGlobalState("duration");
    const [observation, setObservation] = useGlobalState("observation");

    const [reqStartDate, setReqStartDate] = useGlobalState("reqStartDate");
    const [reqSupportType, setReqSupportType] =
      useGlobalState("reqSupportType");
    const [reqSupport, setSupport] = useGlobalState("reqSupport");
    const [reqDiagnosis, setDiagnosis] = useGlobalState("reqDiagnosis");
    const [reqWorkToBeDone, setWorkToBeDone] =
      useGlobalState("reqWorkToBeDone");
    const [reqEndDate, setEndDate] = useGlobalState("reqEndDate");
    const [reqWorkDone, setWorkDone] = useGlobalState("reqWorkDone");
    const [reqUseMaterials, setUseMaterials] =
      useGlobalState("reqUseMaterials");

    const [maintenanceForm, setMaintenanceForm] =
      useGlobalState("maintenanceForm");
    const [supportForm, setSupportForm] = useGlobalState("supportForm");
    const [external_supplier, setExternal_supplier] =
      useGlobalState("external_supplier");
    const [eq_description, seteq_description] =
      useGlobalState("eq_description");

    const getImages = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/getImages",
        data: { token: usertoken, id: props.info.m_id },
      })
        .then((response) => {
          sestallimages(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const handleDownloadClick = (base_url) => {
      allimages.forEach((imagePath, index) => {
        console.log(base_url + imagePath.image_path);
        fetch(base_url + imagePath.image_path)
          .then((response) => response.blob())
          .then((blob) => {
            // Générez un nom de fichier pour l'image
            const fileName = `image_${index + 1}.jpg`;
            // Utilisez downloadjs pour télécharger l'image
            download(blob, fileName);
          });
      });
    };

    useEffect(() => {
      loadPermissionData();
      getImages();
      console.log(props);
    }, []);

    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("ListMaintenance.ViewMaintenance")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row d-print-none">
              <div className="col-lg-4 border-right">
                <div
                  className="border-bottom fs-14 font-weight-bolder mb-5 pb-2 d-flex justify-content-between"
                  id="print-btn"
                >
                  <div className="pt-2">
                    {t("ListMaintenance.MAINTENANCEDETAILS")}
                  </div>
                  {permissions.permission.per8 ? (
                    <button
                      onClick={printMaintenance}
                      className="btn btn-light border btn-sm font-weight-bold rounded-0"
                    >
                      <i aria-hidden="true" className="la la-print"></i>{" "}
                      {t("ListMaintenance.print")}
                    </button>
                  ) : null}
                </div>
                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.MAINTENANCEID")} :{" "}
                  </label>
                  <p id="ticketid-v" className="pl-2">
                    {props.info.m_id}
                  </p>
                  <input
                    type="text"
                    id="ticketid-print"
                    className="form-control"
                    hidden
                  />
                </div>
                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.SUBJECT")} :{" "}
                  </label>
                  <p id="ticketsubject-v" className="pb-2">
                    {props.info.nature_name}
                  </p>
                </div>
                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.DESCRIPTION")} :{" "}
                  </label>
                  <p id="ticketdesc-v" className="pb-2">
                    {props.info.m_description}
                  </p>
                </div>
                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.PRIORITY")} :{" "}
                  </label>
                  <p id="ticketpriority-v" className="pl-2">
                    {props.info.mprio_description}
                  </p>
                </div>
                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {props.info.m_type === "1"
                      ? t("ListMaintenance.rooms")
                      : "Equipment Code"}{" "}
                    :{" "}
                  </label>
                  <p id="equipementcode-v" className="pb-2">
                    {props.info.m_type === "1"
                      ? props.info.room_number
                      : props.info.eq_code}
                  </p>
                </div>
                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.Sites")} :{" "}
                  </label>
                  <p id="equipementsite-v" className="pb-2">
                    {props.info.site_name}
                  </p>
                </div>
                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.STATUS")} :{" "}
                  </label>
                  <p id="status-v" className="pl-2">
                    {props.info.mst_name}
                  </p>
                </div>
                <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.CreatedDate")} :{" "}
                  </label>
                  <p id="createddate-v" className="pb-2">
                    {props.info.m_created_date}
                  </p>
                </div>
                <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                  <Row className="mt-2">
                    {allimages.map((image, index) => (
                      <Col key={index} sm={6} md={6} lg={6}>
                        <a href={base_url + image.image_path}>
                          <img
                            src={base_url + image.image_path}
                            alt={`Image ${index}`}
                            width="100%"
                            height="80px"
                          />
                        </a>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className="p-2">
                  {" "}
                  <div
                    className=""
                    onClick={() => {
                      handleDownloadClick(base_url);
                    }}
                  >
                    <span
                      className="p-2 text-info fs-14 bg-white w-100% hover-effect"
                      style={{ cursor: "pointer", hover: "italic" }}
                    >
                      Telecharger
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-4 pt-2 text-center">
                  {t("ListMaintenance.USERINFORMATIONS")}
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.CreatedDate")} :{" "}
                  </label>
                  <p id="createdby-v" className="pl-2">
                    {props.info.SubmittedBy}
                  </p>
                </div>

                <div className="form-group d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">ROLE : </label>
                  <p id="role-v" className="pl-2">
                    {props.info.r_user_role}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 border-left">
                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-2 text-center">
                  <div className="pt-2 pb-2">
                    SUPPORT DETAILS{" "}
                    <button
                      onClick={printSupport}
                      className="btn btn-light border btn-sm font-weight-bold rounded-0"
                    >
                      <i aria-hidden="true" className="la la-print"></i>{" "}
                      {t("ListMaintenance.print")}
                    </button>
                  </div>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.ASSIGNEDTO")} :{" "}
                  </label>
                  <p id="assignedto-v" className="pl-2">
                    {props.info.Assigned_to}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.TypeOfsupport")} :{" "}
                  </label>
                  <p id="supportType-v" className="pl-2">
                    {props.info.msup_support_type}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.ASSIGNEDBY")} :{" "}
                  </label>
                  <p id="assignedby-v" className="pl-2">
                    {props.info.Assigned_by}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.ASSIGNEDDATE")} :{" "}
                  </label>
                  <p id="assignationdate-v" className="pb-2">
                    {props.info.msup_assignation_date}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.WorkStartDate")} :{" "}
                  </label>
                  <p id="assignationdate-v" className="pb-2">
                    {props.info.msup_intervention_date}
                  </p>
                </div>

                {/* <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.ExpectedDateOfEndOfWork")} :{" "}
                  </label>
                  <p id="assignationdate-v" className="pb-2">
                    {props.info.msup_expected_ended_date}
                  </p>
                </div> */}

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.RealStartDate")} :{" "}
                  </label>
                  <p id="starteddate-v" className="pb-2">
                    {props.info.msup_real_started_time}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DiagnosisResult")} :{" "}
                  </label>
                  <p id="diagnosisdesc-v" className="pb-2">
                    {props.info.msup_diagnosis_description}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DescriptionWorkToBeDone")}:{" "}
                  </label>
                  <p id="workdesc-v" className="pb-2">
                    {props.info.msup_work_to_be_done}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Follow_up.DescriptionOfTheWorkDone")}:{" "}
                  </label>
                  <p id="workdesc-v" className="pb-2">
                    {props.info.msup_work_done}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.ResolvedBy")} :{" "}
                  </label>
                  <p id="resolvedby-v" className="pl-2">
                    {props.info.Resolved_by}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.ResolvedDate")} :{" "}
                  </label>
                  <p id="resolveddate-v" className="pb-2">
                    {props.info.msup_resolved_date}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">Observations : </label>
                  <p id="ticketremark-v" className="pb-2">
                    {props.info.msup_remark}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={printRefname} onClick={props.onHide} variant="default">
              {t("Follow_up.Close")}{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function CloseMaintenance(props) {
    const { t, i18n } = useTranslation();

    const myRefnameU = useRef(null);
    const myRefname = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///
    const [supportdata, setSupportData] = useState([]);

    const [realstarttime, setRealStartTime] = useState("");
    const [endtime, setEndTime] = useState("");
    const [cost, setCost] = useState("");
    const [workdone, setWorkDone] = useState("");
    const [remark, setRemark] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [work, setWork] = useState("");
    const [messageStartTime, setMessageStartTime] = useState("");
    const [messageEndDate, setMessageEndDate] = useState("");
    const [messageCost, setMessageCost] = useState("");
    const [messageWorkDone, setMessageWorkDone] = useState("");
    const [messageRemark, setMessageRemark] = useState("");
    const [messageDiagnosis, setMessageDiagnosis] = useState("");
    const [messageWork, setMessageWork] = useState("");
    const [messageA, setMessageA] = useState("");

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitCAMaintenance = (e) => {
      e.preventDefault();
      const userid = ReactSession.get("userid");

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/close_maintenance",
        data: {
          token: usertoken,
          mid: props.info.m_id,
          userid: userid,
          realstarttime: realstarttime,
          enddate: endtime,
          material_used: cost,
          workdone: workdone,
          remark: remark,
        },
      })
        .then((response) => {
          if (response.data.Validation == "TRUE") {
            setMessageStartTime("");
            setMessageEndDate("");
            setMessageWorkDone("");
            setMessageRemark("");
            setMessageCost("");
            setMessageDiagnosis("");
            setMessageWork("");
            myRefnameU.current.click();
            loadData();

            if (response.data.Inserted == "TRUE") {
              //setSnackbarType("success");
              notifySuccess(response.data.Result);
            } else {
              //setSnackbarType("fail");
              notifyError(response.data.Result);
            }

            //setSnackbarMessage(response.data.Result);
            //setShowsnackbar(true);
            //setTimeout(() => {
            //    setShowsnackbar(false);
            //}, 3000);
          } else {
            setMessageStartTime(response.data.real_start);
            setMessageEndDate(response.data.end_date);
            setMessageWorkDone(response.data.workdone);
            setMessageRemark(response.data.remark);
            setMessageCost(response.data.msup_material_used);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    ///

    useEffect(() => {
      setWork(props.info.msup_work_to_be_done);
      setDiagnosis(props.info.msup_diagnosis_description);
    }, []);

    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Support.CloseMaintenance")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitCAMaintenance}>
              <div className="form-group row pt-5">
                <div className="col-md-6">
                  <label className="font-weight-bolder">
                    {t("Support.InterventionRealStartedTime")}{" "}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={(e) => setRealStartTime(e.target.value)}
                    id="supp-startdate"
                    className="form-control rounded-0"
                  />
                  <div id="startdate-error" className="text-danger pt-2">
                    {messageStartTime}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="font-weight-bolder">
                    {t("Support.InterventionEndedTime")}{" "}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={(e) => setEndTime(e.target.value)}
                    id="supp-startdate_1"
                    className="form-control rounded-0"
                  />
                  <div id="supporttype-error" className="text-danger pt-2">
                    {messageEndDate}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("Support.EnterRemark")}
                  </label>
                  <textarea
                    onChange={(e) => setWorkDone(e.target.value)}
                    id="diagnostic-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="diagnosis-error" className="text-danger pt-2">
                    {messageWorkDone}
                  </div>
                </div>

                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("Support.remarqueclose")}
                  </label>
                  <textarea
                    onChange={(e) => setRemark(e.target.value)}
                    id="task-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="task-error" className="text-danger pt-2">
                    {messageRemark}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-12">
                  <label className="font-weight-bolder">
                    {t("Support.material_used")}
                  </label>
                  <textarea
                    onChange={(e) => setCost(e.target.value)}
                    id="supp-startdate_1"
                    className="form-control rounded-0"
                    rows="4"
                  ></textarea>
                  <div id="supporttype-error" className="text-danger pt-2">
                    {messageCost}
                  </div>
                </div>
              </div>
              <button type="submit" ref={myRefname} hidden className="btn">
                {t("Support.Submit")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("Support.Cancel")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("Support.CloseMaintenance")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const Maintenances = ({ data, loading }) => {
    const [showModal, setShowModal] = useGlobalState("show");
    const [showModalCancel, setShowModalCancel] = useGlobalState("showcancel");
    const [showModalEditAssign, setShowModalEditAssign] =
      useGlobalState("showeditassign");

    const [showModalView, setShowModalView] = useGlobalState("showview");
    const [showModalSeen, setShowModalSeen] = useGlobalState("showseen");
    const [showModalAssign, setShowModalAssign] = useGlobalState("showassign");
    const [showModalSign, setShowModalSign] = useGlobalState("showModalSign");
    const [showModalClose, setShowModalclose] = useGlobalState("showclose");

    const [getmaintenancedata, setMaintenanceData] =
      useGlobalState("maintenancedata");

    const handleSeenModal = (d) => {
      setShowModalSeen(true);
      setMaintenanceData(d);
    };

    const handleAssignModal = (d) => {
      setShowModalAssign(true);
      setMaintenanceData(d);
    };

    const handleCloseModal = (d) => {
      setShowModalclose(true);
      setMaintenanceData(d);
    };
    const handleSignModal = (d) => {
      setShowModalSign(true);
      setMaintenanceData(d);
    };

    const [permissions, setPermissions] = useState({
      permission: {
        per1: false,
        per2: false,
        per3: false,
        per4: false,
        per5: false,
        per6: false,
        per7: false,
        per8: false,
        per9: false,
        per10: false,
        per11: false,
        per12: false,
        per13: false,
        per14: false,
        per15: false,
        per16: false,
        per17: false,
        per18: false,
        per19: false,
        per20: false,
        per21: false,
        per22: false,
        per23: false,
        per24: false,
        per25: false,
        per26: false,
        per27: false,
        per28: false,
        per29: false,
        per30: false,
        per31: false,
        per32: false,
        per33: false,
        per34: false,
        per35: false,
        per36: false,
      },
    });

    const [permissiondata, setPermissiondata] = useState("");
    const userid = ReactSession.get("userid");
    const usertoken = ReactSession.get("usertoken");

    const loadPermissionData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/UserPermissions",
        data: { token: usertoken, userid: userid },
      })
        .then((response) => {
          setPermissiondata(response.data);

          var prevPerm = permissions;

          var json = response.data;

          Object.keys(json).forEach(function (key) {
            if (json[key].usrper_per_id == 1) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per1 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 2) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per2 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 3) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per3 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 4) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per4 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 5) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per5 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 6) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per6 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 7) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per7 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 8) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per8 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 9) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per9 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 10) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per10 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 11) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per11 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 12) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per12 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 13) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per13 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 14) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per14 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 15) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per15 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 16) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per16 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 17) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per17 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 18) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per18 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 19) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per19 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 20) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per20 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 21) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per21 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 22) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per22 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 23) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per23 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 24) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per24 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 25) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per25 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 26) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per26 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 27) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per27 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 28) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per28 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 29) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per29 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 30) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per30 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 31) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per31 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 32) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per32 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 33) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per33 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 34) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per34 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 35) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per35 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 36) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per36 = true;
                setPermissions(prevPerm);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      loadPermissionData();
    }, []);

    if (loading) {
      return <h2>Loading...</h2>;
    }

    return (
      <>
        <tbody>
          {currentPosts.length ? (
            currentPosts.map((d, index) => (
              <tr key={index} className="data">
                <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                <td>
                  <DropdownMenu d={d} />
                </td>
                <td id="tb-maintenanceid" className="text-left">
                  {d.m_id}
                </td>
                <td id="tb-subject" className="text-left">
                  {d.nature_name}
                </td>
                <td
                  id="tb-description"
                  className="text-left"
                  style={{ minWidth: "150px" }}
                >
                  {d.m_description != null && d.m_description.length > 100
                    ? d.m_description.slice(0, 97) + "..."
                    : d.m_description}
                </td>

                <td id="tb-priority" className="text-left">
                  {cookies.i18next === "en"
                    ? d.mprio_description
                    : d.mptio_description_fr}
                </td>
                <td
                  id="tb-description"
                  className="text-left"
                  style={{ minWidth: "150px" }}
                >
                  {d.hightcomment != null && d.hightcomment.length > 100
                    ? d.hightcomment.slice(0, 97) + "..."
                    : d.hightcomment}
                </td>
                <td id="tb-status" className="text-left">
                  {d.m_status == 1 || d.m_status == 4 ? (
                    <span className="available py-1 px-2 text-white rounded">
                      {cookies.i18next === "en" ? d.mst_name : d.mst_name_fr}
                    </span>
                  ) : null}

                  {d.m_status == 2 || d.m_status == 5 ? (
                    <span className="on-job py-1 px-2 text-white rounded">
                      {cookies.i18next === "en" ? d.mst_name : d.mst_name_fr}
                    </span>
                  ) : null}

                  {d.m_status == 3 || d.m_status == 7 ? (
                    <span className="done py-1 px-2 text-white rounded">
                      {cookies.i18next === "en" ? d.mst_name : d.mst_name_fr}
                    </span>
                  ) : null}

                  {d.m_status == 6 ? (
                    <span className="assigned py-1 px-2 text-white rounded">
                      {cookies.i18next === "en" ? d.mst_name : d.mst_name_fr}
                    </span>
                  ) : null}

                  {d.m_status == 8 ? (
                    <span className="canceled py-1 px-2 text-white rounded">
                      {cookies.i18next === "en" ? d.mst_name : d.mst_name_fr}
                    </span>
                  ) : null}

                  {d.m_status == 11 ? (
                    <span className="suspended py-1 px-2 text-white rounded">
                      {cookies.i18next === "en" ? d.mst_name : d.mst_name_fr}
                    </span>
                  ) : null}
                </td>
                <td id="tb-created-by" className="text-left">
                  {d.Created_by}
                </td>
                <td id="tb-assigned-to" className="text-left">
                  {d.Assigned_to
                    ? d.Assigned_to
                    : d.msup_external_supplier_name}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.m_created_date}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.msup_signature == 1
                    ? t("ListMaintenance.Confirm_Y")
                    : t("ListMaintenance.Confirm_N")}
                </td>
                <td id="tb-created-date" className="text-left">
                  <div className="d-flex">
                    {d.m_status == 1 && permissions.permission.per6 == true ? (
                      <button
                        onClick={() => {
                          handleSeenModal(d);
                        }}
                        className="btn btn-sm bg-red text-white"
                      >
                        {t("ListMaintenance.Seen")}
                      </button>
                    ) : null}

                    {(d.m_status == 1 ||
                      d.m_status == 2 ||
                      d.m_status == 4 ||
                      d.m_status == 5) &&
                    permissions.permission.per4 == true ? (
                      <button
                        onClick={() => {
                          handleAssignModal(d);
                        }}
                        className="btn btn-sm bg-red text-white ml-3"
                      >
                        {t("ListMaintenance.Assign")}
                      </button>
                    ) : d.m_status != 3 &&
                      permissions.permission.per13 &&
                      d.m_status == 6 ? (
                      <button
                        onClick={() => {
                          handleCloseModal(d);
                        }}
                        className="btn btn-sm bg-red text-white"
                      >
                        {t("ListMaintenance.Close")}
                      </button>
                    ) : null}
                  </div>
                </td>
                <td id="tb-created-date" className="text-left">
                  <div className="d-flex">
                    {d.m_status == 3 &&
                    d.msup_signature == 0 &&
                    permissions.permission.per24 ? (
                      <button
                        onClick={() => {
                          handleSignModal(d);
                        }}
                        className="btn btn-sm btn-info text-white"
                      >
                        {t("ListMaintenance.Sign")}
                      </button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={11} className="text-center pt-20 fs-16">
                No data found
              </td>
            </tr>
          )}
        </tbody>

        {showModal ? (
          <UpdateData
            show={showModal}
            onHide={() => setShowModal(false)}
            info={getmaintenancedata}
          />
        ) : null}
        {showModalCancel ? (
          <CancelMaintenance
            show={showModalCancel}
            onHide={() => setShowModalCancel(false)}
            info={getmaintenancedata}
          />
        ) : null}
        {showModalSeen ? (
          <SeenMaintenance
            show={showModalSeen}
            onHide={() => setShowModalSeen(false)}
            info={getmaintenancedata}
          />
        ) : null}
        {showModalAssign ? (
          <AssignMaintenance
            show={showModalAssign}
            onHide={() => setShowModalAssign(false)}
            info={getmaintenancedata}
            showModal
          />
        ) : null}
        {showModalClose ? (
          <CloseMaintenance
            show={showModalClose}
            onHide={() => setShowModalclose(false)}
            info={getmaintenancedata}
          />
        ) : null}
        {showModalView ? (
          <ViewMaintenance
            show={showModalView}
            onHide={() => setShowModalView(false)}
            info={getmaintenancedata}
          />
        ) : null}
        {showModalEditAssign ? (
          <EditAssignMaintenance
            show={showModalEditAssign}
            onHide={() => setShowModalEditAssign(false)}
            info={getmaintenancedata}
          />
        ) : null}
        {showModalSign ? (
          <SignMaintenance
            show={showModalSign}
            onHide={() => setShowModalSign(false)}
            info={getmaintenancedata}
          />
        ) : null}
      </>
    );
  };

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage == totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginate(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage == number ? (
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a onClick={() => paginate(number)} href="#" className="">
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a onClick={() => paginate(totalPages)} href="#" className="">
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //////////////////////

  const [roomsdata, setRoomsdata] = useState("");
  const [equipmentData, setEquipmentData] = useState("");
  const [equipment, setEquipment] = useState("");
  const [roomdata, setRoomdata] = useState("");
  const [roomsitedata, setRoomSitedata] = useState("");
  const [Equipmentsitedata, setEquipmentSitedata] = useState("");
  const [subjectData, setSubjectData] = useState("");

  ///

  const [sitedata, setSitedata] = useState([]);
  const [prioritydata, setPrioritydata] = useState([]);
  const [maintenace_Type, setMaintenace_Type] = useState([]);

  const loadSiteData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetSites",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setSitedata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const loadSubject = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetSubject",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setSubjectData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadPriorityData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetPriorities",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setPrioritydata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const loadTypeData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetType",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setMaintenace_Type(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadRoomsData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetRooms",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setRoomsdata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadEquipmentData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/GetEquipment",
      data: { token: usertoken },
    })
      .then((response) => {
        if (response.data.error) return null;
        setEquipmentData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadRoomSiteData = (e) => {
    e.preventDefault();

    setRoomdata(e.target.value);
    setRoom(e.target.value);

    if (e.target.value.length > 0) {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetRoomData",
        data: { token: usertoken, roomid: e.target.value },
      })
        .then((response) => {
          setRoomSitedata(response.data.room_site);
          setSite(response.data.room_site);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setRoomSitedata("");
    }
  };

  const loadEquipmentSiteData_eq = (e) => {
    e.preventDefault();

    setEquipment(e.target.value);
    //setRoom(e.target.value);123456

    if (e.target.value.length > 0) {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/GetEquipmentData",
        data: { token: usertoken, eq_id: e.target.value },
      })
        .then((response) => {
          //setEquipmentData(response.data.eq_affectation_site);
          setSite(response.data.eq_affectation_site);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setEquipmentData("");
    }
  };

  const [images, setImages] = useState([]);
  const [imagesS, setImagesS] = useState([]);

  const handleDrop = (e) => {
    e.preventDefault();
    const newImages = [...images];
    const newImagesS = [...imagesS];

    if (e.dataTransfer.files) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        newImages.push(URL.createObjectURL(e.dataTransfer.files[i]));
        newImagesS.push(e.dataTransfer.files[i]);
      }
    }

    setImages(newImages);
    setImagesS(newImagesS);
  };

  // const handleClick1 = (e) => {
  //   const newImages = [...images];
  //   newImages.push(URL.createObjectURL(e.target.files[0]));
  //   setImages(newImages);
  // };

  useEffect(() => {
    loadSubject();
    loadSiteData();
    loadPriorityData();
    loadTypeData();
    loadRoomsData();
    loadEquipmentData();
  }, []);

  ///////////////////////////////

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("ListMaintenance.createMaintenance")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitMaintenance}>
            <div className="form-group mb-4 mt-3">
              <label className="font-weight-bold">
                {t("ListMaintenance.Maintenance_type")}
                <span className="text-danger pl-3">*</span>
              </label>
              <select
                id="ticket-type"
                onChange={(e) => {
                  setMt_type(e.target.value);
                  setRoom("");
                  setEquipment("");
                  setSite("");
                }}
                name="priority"
                className="form-control rounded-0"
              >
                {}
                <option value="-1"> </option>
                {maintenace_Type.length
                  ? maintenace_Type.map((d) => (
                      <option key={d.mt_id} value={d.mt_id}>
                        {" "}
                        {cookies.i18next === "fr"
                          ? d.mt_value
                          : d.mt_value_eng}{" "}
                      </option>
                    ))
                  : null}
              </select>
              <div id="ticketprio-error" className="text-danger pt-2">
                {messageType}
              </div>
            </div>

            {mt_type > -1 ? (
              <div className="form-group mb-4 mt-3">
                <label className="font-weight-bold">
                  {t("ListMaintenance.priority")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <select
                  id="ticket-priority"
                  onChange={(e) => {
                    setPriority(e.target.value);
                  }}
                  name="priority"
                  className="form-control rounded-0"
                >
                  {}
                  <option> </option>
                  {prioritydata.length
                    ? prioritydata.map((d) => (
                        <option key={d.mprio_id} value={d.mprio_id}>
                          {" "}
                          {cookies.i18next === "en"
                            ? d.mprio_description
                            : d.mptio_description_fr}{" "}
                        </option>
                      ))
                    : null}
                </select>
                <div id="ticketprio-error" className="text-danger pt-2">
                  {messagePriority}
                </div>
              </div>
            ) : null}
            {priority === "3" ? (
              <div
                className={"form-group mb-4 " + (mt_type < 0 ? "d-none" : "")}
              >
                <label className="font-weight-bold">
                  {"Comment"}
                  <span className="text-danger pl-3">*</span>
                </label>
                <textarea
                  id="ticketdesc"
                  onChange={(e) => {
                    setcommentpriority(e.target.value);
                  }}
                  name="description"
                  className="form-control rounded-0"
                  rows={4}
                ></textarea>
                <div id="ticketdesc-error" className="text-danger pt-2">
                  {}
                </div>
              </div>
            ) : null}

            {mt_type == 1 ? (
              <div className="form-group mb-4">
                <label className="font-weight-bold">
                  {t("ListMaintenance.rooms")}
                  <span className="text-danger pl-3"></span>
                </label>
                <select
                  id="room"
                  onChange={loadRoomSiteData}
                  name="room"
                  className="form-control bg-white rounded-0"
                >
                  <option> </option>
                  {roomsdata.length
                    ? roomsdata.map((d) => (
                        <option key={d.room_id} value={d.room_id}>
                          {" "}
                          {d.room_number}{" "}
                        </option>
                      ))
                    : null}
                </select>
                <div id="site-error" className="text-danger pt-2">
                  {messageRoom}
                </div>
              </div>
            ) : mt_type == 2 ? (
              <div className="form-group mb-4">
                <label className="font-weight-bold">
                  {t("Equipment.EquipmentCode")}
                  <span className="text-danger pl-3"></span>
                </label>
                <select
                  id="room"
                  onChange={loadEquipmentSiteData_eq}
                  name="room"
                  className="form-control bg-white rounded-0"
                  value={equipment}
                >
                  <option></option>
                  {equipmentData.length
                    ? equipmentData.map((d) => (
                        <option key={d.eq_id} value={d.eq_id}>
                          {" "}
                          {d.eq_description}{" "}
                        </option>
                      ))
                    : null}
                </select>
                <div id="site-error" className="text-danger pt-2">
                  {messageRoom}
                </div>
              </div>
            ) : null}

            {mt_type > -1 ? (
              <div className="form-group mb-4">
                <label className="font-weight-bold">
                  {t("ListMaintenance.Sites")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <select
                  id="site"
                  name="site"
                  onChange={(e) => {
                    setSite(e.target.value);
                  }}
                  value={site}
                  className="form-control bg-white rounded-0"
                >
                  <option> </option>
                  {sitedata.length
                    ? sitedata.map((d) => (
                        <option key={d.site_id} value={d.site_id}>
                          {" "}
                          {d.site_name}{" "}
                        </option>
                      ))
                    : null}
                </select>
                <div id="site-error" className="text-danger pt-2">
                  {messageSite}
                </div>
              </div>
            ) : null}

            <div className={"form-group mb-4" + (mt_type < 0 ? " d-none" : "")}>
              <label className="font-weight-bold">
                {t("ListMaintenance.Subject")}
                <span className="text-danger pl-3">*</span>
              </label>

              <select
                id="subjest"
                name="subjest"
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                value={subject}
                className="form-control bg-white rounded-0"
              >
                <option> </option>
                {subjectData.length
                  ? subjectData.map((d) =>
                      mt_type === "1" ? (
                        d.nature_groupe === "1" ? (
                          <option key={d.nature_id} value={d.nature_id}>
                            {" "}
                            {d.nature_name}{" "}
                          </option>
                        ) : null
                      ) : mt_type === "2" ? (
                        d.nature_groupe === "2" ? (
                          <option key={d.nature_id} value={d.nature_id}>
                            {" "}
                            {d.nature_name}{" "}
                          </option>
                        ) : null
                      ) : null
                    )
                  : null}
              </select>

              <div id="ticketsubject-error" className="text-danger pt-2">
                {messageSubject}
              </div>
            </div>

            <div className={"form-group mb-4 " + (mt_type < 0 ? "d-none" : "")}>
              <label className="font-weight-bold">
                {t("ListMaintenance.MaintenanceDescription")}
                <span className="text-danger pl-3">*</span>
              </label>
              <textarea
                id="ticketdesc"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                name="description"
                className="form-control rounded-0"
                rows={4}
              ></textarea>
              <div id="ticketdesc-error" className="text-danger pt-2">
                {messageDescription}
              </div>
            </div>
            <div className={"form-group mb-4 " + (mt_type < 0 ? "d-none" : "")}>
              <label className="font-weight-bold">
                Images
                <span className="text-danger pl-3 mt-1"></span>
              </label>
              <input
                type="checkbox"
                onChange={() => {
                  setViewImages(!viewImages);
                }}
              />
              {/* <Toggle
                label={"Image"}
                onChange={() => setViewImages(!viewImages)}
                checked={null}
              /> */}
            </div>
            {viewImages ? (
              <Container>
                <Row>
                  <Col>
                    <div
                      onDrop={handleDrop}
                      onDragOver={(e) => e.preventDefault()}
                      style={{
                        border: "2px dashed #ccc",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p>Glissez et déposez des images ici</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  {images.map((image, index) => (
                    <Col key={index} sm={6} md={4} lg={3}>
                      <img
                        src={image}
                        alt={`Image ${index}`}
                        width="100%"
                        height="80px"
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            ) : null}

            <button ref={myRefname} hidden className="btn">
              {t("ListMaintenance.submit")}
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button ref={myRefnameU} onClick={props.onHide} variant="default">
            {t("ListMaintenance.cancel")}
          </Button>
          <Button type="submit" onClick={handleClick} variant="danger">
            {t("ListMaintenance.createMaintenance")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card-body">
        <div className="table-responsive">
          <table id="result" className="table min-h-350px">
            <thead>
              <tr>
                <th scope="col" className="w-25px">
                  #
                </th>
                <th scope="col" className="w-100px"></th>
                <th scope="col" className="text-left">
                  {t("ListMaintenance.MAINTENANCEID")}
                  <a href="#" data-toggle="modal" data-target="#ticketidModal">
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("ListMaintenance.SUBJECT")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketsubjectModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("ListMaintenance.DESCRIPTION")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketdescModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("ListMaintenance.PRIORITY")}
                  <a href="#" data-toggle="modal" data-target="#priorityModal">
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {cookies.i18next === "en"
                    ? "High priority"
                    : "Haute priorité"}
                  <a href="#" data-toggle="modal" data-target="#priorityModal">
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("ListMaintenance.STATUS")}
                  <a href="#" data-toggle="modal" data-target="#statusModal">
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("ListMaintenance.SENTBY")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filtercreatedModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("ListMaintenance.ASSIGNEDTO")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterassignedtoModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  {t("ListMaintenance.SENTDATE")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left">
                  SIGNATURE
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#filterdateModal"
                  >
                    <i className="la la-filter"></i>
                  </a>
                </th>
                <th scope="col" className="text-left"></th>
              </tr>
            </thead>

            <Maintenances data={maintenances} loading={loading} />
          </table>
        </div>
      </div>

      <div className="card-footer pt-2 pb-2">
        <div className="d-flex justify-content-center">
          <Pagination
            postsPerPage={maintenancesPerPage}
            totalPosts={maintenances.length}
            paginate={paginate}
          />
        </div>
      </div>
    </>
  );
}

function List_maintenance() {
  const { t, i18n } = useTranslation();
  //const { posts, errorMsg } = this.state;
  const [modalShow, setModalShow] = React.useState(false);

  const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
  const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
  const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

  const snackbarRef = useRef(null);

  const [permissions, setPermissions] = useState({
    permission: {
      per1: false,
      per2: false,
      per3: false,
      per4: false,
      per5: false,
      per6: false,
      per7: false,
      per8: false,
      per9: false,
      per10: false,
      per11: false,
      per12: false,
      per13: false,
      per14: false,
      per15: false,
      per16: false,
      per17: false,
      per18: false,
      per19: false,
      per20: false,
      per21: false,
      per22: false,
      per23: false,
      per24: false,
      per25: false,
      per26: false,
      per27: false,
      per28: false,
      per29: false,
      per30: false,
      per31: false,
      per32: false,
      per33: false,
      per34: false,
      per35: false,
      per36: false,
    },
  });

  const [permissiondata, setPermissiondata] = useState("");
  const userid = ReactSession.get("userid");
  const usertoken = ReactSession.get("usertoken");

  const loadPermissionData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/UserPermissions",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        setPermissiondata(response.data);

        var prevPerm = permissions;

        var json = response.data;

        Object.keys(json).forEach(function (key) {
          if (json[key].usrper_per_id == 1) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per1 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 2) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per2 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 3) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per3 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 4) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per4 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 5) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per5 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 6) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per6 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 7) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per7 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 8) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per8 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 9) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per9 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 10) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per10 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 11) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per11 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 12) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per12 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 13) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per13 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 14) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per14 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 15) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per15 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 16) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per16 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 17) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per17 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 18) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per18 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 19) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per19 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 20) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per20 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 21) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per21 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 22) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per22 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 23) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per23 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 24) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per24 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 25) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per25 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 26) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per26 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 27) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per27 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 28) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per28 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 29) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per29 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 30) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per30 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 31) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per31 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 32) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per32 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 33) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per33 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 34) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per34 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 35) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per35 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 36) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per36 = true;
              setPermissions(prevPerm);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [reqRef, setReqRef] = useGlobalState("reqRef");
  const [roomCode, setRoomCode] = useGlobalState("roomCode");
  const [roomresident, setRoomresident] = useGlobalState("roomresident");
  const [eq_description, seteq_description] = useGlobalState("eq_description");
  const [duration, SetDuration] = useGlobalState("duration");
  const [observation, setObservation] = useGlobalState("observation");

  const [formRef, setFormRef] = useGlobalState("formRef");
  const [reqDate, setReqDate] = useGlobalState("reqDate");
  const [reqBy, setReqBy] = useGlobalState("reqBy");
  const [roomNum, setRoomNum] = useGlobalState("roomNum");
  const [roomSite, setRoomSite] = useGlobalState("roomSite");
  const [reqDesc, setReqDesc] = useGlobalState("reqDesc");
  const [reqSubject, setReqSubject] = useGlobalState("reqSubject");
  const [reqPrio, setReqPrio] = useGlobalState("reqPrio");
  const [reqStartDate, setReqStartDate] = useGlobalState("reqStartDate");
  const [reqSupportType, setReqSupportType] = useGlobalState("reqSupportType");
  const [reqSupport, setSupport] = useGlobalState("reqSupport");
  const [reqDiagnosis, setDiagnosis] = useGlobalState("reqDiagnosis");
  const [reqWorkToBeDone, setWorkToBeDone] = useGlobalState("reqWorkToBeDone");
  const [reqEndDate, setEndDate] = useGlobalState("reqEndDate");
  const [reqWorkDone, setWorkDone] = useGlobalState("reqWorkDone");
  const [reqUseMaterials, setUseMaterials] = useGlobalState("reqUseMaterials");
  const [reqDep, setReqDep] = useGlobalState("reqDep");
  const [external_supplier, setExternal_supplier] =
    useGlobalState("external_supplier");

  const [reqRole, setReqRole] = useGlobalState("reqRole");
  const [reqtype, setReqtype] = useGlobalState("reqtype");
  const [highlevel, sethighlevel] = useGlobalState("highlevel");

  const [eq_code, setEq_code] = useGlobalState("eq_code");
  const [maintenanceForm, setMaintenanceForm] =
    useGlobalState("maintenanceForm");
  const [supportForm, setSupportForm] = useGlobalState("supportForm");

  useEffect(() => {
    loadPermissionData();
    setSupportForm("print-container2");
    setMaintenanceForm("print-container2");
  }, []);

  return (
    <>
      <Header_mobile />
      <Header />

      <div
        className="d-flex flex-column flex-column-fluid "
        style={{ minHeight: "85vh" }}
      >
        {showsnackbar == true ? (
          <Snackbar
            ref={snackbarRef}
            message={snackbarmessage}
            type={snackbartype}
            show={showsnackbar}
          />
        ) : null}

        <div className="d-flex flex-column-fluid">
          <div className=" container" id="pageBlocking">
            <div className="d-print-none mt-20"></div>

            <div className="row">
              <div className="col-xl-12">
                <div
                  className="card card-custom d-print-none"
                  id="kt_blockui_card"
                >
                  <div className="card-header">
                    <div className="card-title">
                      <h3 className="card-label">
                        {t("ListMaintenance.ListOfMaintenances")}
                      </h3>
                      <button
                        id="btn-refrech"
                        className="btn btn-sm bg-light2 ml-4 w-45px pl-4"
                      >
                        <i className="la la-sync fs-17"></i>
                      </button>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="dropdown dropdown-inline mr-4"
                        data-toggle="tooltip"
                      >
                        {permissions.permission.per33 ? (
                          <button
                            className="btn btn-light font-weight-bolder border"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {t("ListMaintenance.Export")}
                          </button>
                        ) : null}
                        <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right w-150px">
                          <ul className="navi navi-hover py-5">
                            <li className="navi-item">
                              <a
                                href="#"
                                id="export-excel"
                                className="navi-link"
                              >
                                <span className="navi-icon">
                                  <i className="la la-file-excel-o"></i>
                                </span>
                                <span className="navi-text">Excel</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <button
                        id="clearfilter"
                        className="btn btn-light font-weight-bolder border mr-3"
                      >
                        {t("ListMaintenance.Clear")}
                      </button>
                      {permissions.permission.per1 == true ? (
                        <button
                          onClick={() => {
                            setModalShow(true);
                          }}
                          id="crticket-btn"
                          className="btn btn-defaut text-white bg-red font-weight-bold"
                          data-toggle="modal"
                          data-target="#addticketModal"
                        >
                          {t("ListMaintenance.createMaintenance")}
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <Example
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              </div>
            </div>

            <div className="mt-20 d-print-none"></div>
            {/* 123456 */}
            <div className="row">
              <div id="printticketrep" className={maintenanceForm}>
                <div className="card-body">
                  <div className="table-responsive">
                    <table id="" className="table" style={{ width: "1200px" }}>
                      <tbody id="" className="border">
                        <tr>
                          <td rowSpan={3} colSpan={2} className="border">
                            <div className="d-flex">
                              <div className="w-150px">
                                <img
                                  alt="Logo"
                                  src={logo}
                                  className="logo-default max-h-80px"
                                />
                              </div>
                              <div className="text-center font-weight-bolder fs-24 pt-7 border-left-0 pl-10">
                                Formulaire de demande de la maintenance curative
                              </div>
                            </div>
                          </td>
                          <td className="text-center border fs-16">
                            Code : <span id="trep-code">FR11-S2</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center border fs-16">
                            Version : 001
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center border fs-16">
                            Date de mise en application: 13 Sept 2021
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-right-0"></td>
                          <td className="border border-left-0 fs-16 text-right">
                            Référence de la fiche : <span></span>
                            <br />
                            <span className="text-danger fs-11">
                              (à remplir par le département Maintenance)
                            </span>
                          </td>
                          <td
                            id="trep-ref"
                            className="border border-left-0 text-center fs-16"
                            style={{ verticalAlign: "middle" }}
                          >
                            MC-{formRef}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-right-0"></td>
                          <td className="border border-left-0 fs-16 text-right">
                            Date et heure de la demande :
                          </td>
                          <td
                            id="trep-date"
                            className="border text-center fs-16"
                          >
                            {reqDate}
                          </td>
                        </tr>
                        <tr className="text-center bg-light border">
                          <td
                            colSpan={3}
                            className="font-weight-bolder border fs-16"
                          >
                            Information sur le demandeur
                          </td>
                        </tr>
                        <tr>
                          <td className="border font-weight-bolder text-left w-400px fs-16">
                            Nom
                          </td>
                          <td
                            colSpan={2}
                            id="trep-user"
                            className="border text-left fs-16"
                          >
                            {reqBy}
                          </td>
                        </tr>
                        <tr>
                          <td className="border font-weight-bolder text-left fs-16">
                            Position
                          </td>
                          <td
                            colSpan={2}
                            id="trep-eqcode"
                            className="border text-left fs-16"
                          >
                            {reqRole}
                          </td>
                        </tr>
                        <tr>
                          <td className="border font-weight-bolder text-left fs-16">
                            Processus et département concerné
                          </td>
                          <td
                            colSpan={2}
                            id="trep-eqsite"
                            className="border text-left fs-16"
                          >
                            {reqDep}
                          </td>
                        </tr>
                        <tr className="text-center bg-light border">
                          <td
                            colSpan={3}
                            className="font-weight-bolder border fs-16"
                          >
                            Informations sur la demande
                          </td>
                        </tr>

                        <tr>
                          <td className="border font-weight-bolder text-left fs-16">
                            Code de l'infrastructure / Équipement
                          </td>
                          <td
                            colSpan={2}
                            id="trep-priority"
                            className="border text-left fs-16"
                          >
                            {reqtype === "1" ? roomCode : eq_code}
                          </td>
                        </tr>
                        <tr>
                          <td className="border font-weight-bolder text-left fs-16">
                            Utilisateur
                          </td>
                          <td
                            colSpan={2}
                            id="trep-priority"
                            className="border text-left fs-16"
                          >
                            {reqtype === "1" ? roomresident : eq_description}
                          </td>
                        </tr>

                        <tr>
                          <td className="border font-weight-bolder text-left fs-16">
                            Lieu d'affectation
                          </td>
                          <td
                            colSpan={2}
                            id="trep-priority"
                            className="border text-left fs-16"
                          >
                            {roomSite}
                          </td>
                        </tr>

                        <tr className="h-110px">
                          <td className="border font-weight-bolder text-left w-400px fs-16">
                            Description de la demande
                          </td>
                          <td
                            colSpan={2}
                            id="trep-user"
                            className="border text-left fs-16"
                          >
                            <span className="font-weight-bolder">
                              {reqSubject} :
                            </span>
                            {reqDesc}
                          </td>
                        </tr>
                        <tr className="border">
                          <td
                            rowSpan={3}
                            className="border font-weight-bolder text-left fs-16"
                            style={{ verticalAlign: "middle" }}
                          >
                            Niveau de priorité
                          </td>
                          <td
                            colSpan={3}
                            className="border font-weight-bolder text-left fs-16 font-weight-bolder"
                          >
                            <div className="row m-0 p-0">
                              <div className="col">
                                <label
                                  className="form-check-label pr-2"
                                  htmlFor="flexCheckChecked"
                                >
                                  Haute
                                </label>
                                {reqPrio === "High" ? (
                                  <input
                                    className=""
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className=""
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked"
                                  />
                                )}
                              </div>
                              <div className="col">
                                <label
                                  className="form-check-label pr-2"
                                  htmlFor="flexCheckChecked1"
                                >
                                  Moyenne
                                </label>
                                {reqPrio === "Medium" ? (
                                  <input
                                    className=""
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked1"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className=""
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked1"
                                  />
                                )}
                              </div>
                              <div className="col">
                                <label
                                  className="form-check-label pr-2"
                                  htmlFor="flexCheckChecked2"
                                >
                                  Base
                                </label>
                                {reqPrio === "Low" ? (
                                  <input
                                    className=""
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked2"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className=""
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked2"
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            className="border font-weight-bolder text-left font-italic fs-16"
                          >
                            Commentaires
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            className="h-90px border text-left fs-16"
                          >
                            {highlevel}
                          </td>
                        </tr>
                        <tr className="border">
                          <td
                            rowSpan={2}
                            className="border font-weight-bolder text-left fs-16"
                            style={{ verticalAlign: "middle" }}
                          >
                            Signature
                          </td>
                          <td
                            colSpan={2}
                            id="trep-user"
                            className="border text-left fs-16 font-weight-bolder font-italic"
                          >
                            Nom et Signature du demandeur
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            className="border h-110px font-weight-bolder text-left font-italic fs-16"
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row">
                <div id="printticketrep" className={supportForm}>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        id=""
                        className="table"
                        style={{ width: "1200px" }}
                      >
                        <tbody id="" className="border">
                          <tr>
                            <td rowSpan={3} colSpan={2} className="border">
                              <div className="d-flex">
                                <div className="w-150px">
                                  <img
                                    alt="Logo"
                                    src={logo}
                                    className="logo-default max-h-80px"
                                  />
                                </div>
                                <div className="font-weight-bolder fs-24 pt-7 border-left-0">
                                  <div style={{ paddingLeft: "150px" }}>
                                    FICHE DE REALISATION DE LA MAINTENANCE
                                    CURATIVE
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="text-center border w-300px fs-16">
                              Code :{" "}
                              <span id="trep-code-supp">Code: FR12-S2</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center border fs-16">
                              Version : 001
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center border fs-16">
                              Date de mise en application: 13 Sept 2021
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="border fs-16 text-right">
                              Référence de la fiche
                            </td>
                            <td
                              id="trep-ref-supp"
                              className="border text-center fs-16"
                              style={{ verticalAlign: "middle" }}
                            >
                              MC-{formRef}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="border fs-16 text-right">
                              Référence de la demande
                            </td>
                            <td
                              id="trep-ref-supp"
                              className="border text-center fs-16"
                              style={{ verticalAlign: "middle" }}
                            >
                              MC-{reqRef}
                            </td>
                          </tr>
                          <tr className="bg-light text-center">
                            <td
                              colSpan={3}
                              className="font-weight-bolder border fs-16"
                            >
                              Informations sur l'infrastructure
                            </td>
                          </tr>
                          <tr>
                            <td className="border w-400px text-left font-weight-bolder fs-15">
                              {reqtype === "1"
                                ? "Code de l'équipement / infrastructure"
                                : "Code de l'équipement / infrastructure"}
                            </td>
                            <td
                              colSpan={2}
                              id="trep-code-infra"
                              className="border text-left fs-15"
                            >
                              {reqtype === "1" ? roomCode : eq_code}
                            </td>
                          </tr>
                          <tr>
                            <td className="border w-400px text-left font-weight-bolder fs-15">
                              {reqtype === "1" ? "Utilisateur" : "Utilisateur"}
                            </td>
                            <td
                              colSpan={2}
                              id="trep-code-infra"
                              className="border text-left fs-15"
                            >
                              {reqtype === "1" ? roomresident : eq_description}
                            </td>
                          </tr>
                          <tr>
                            <td className="border w-400px text-left font-weight-bolder fs-15">
                              Site d'affectation
                            </td>
                            <td
                              colSpan={2}
                              id="trep-code-infra"
                              className="border text-left fs-15"
                            >
                              {roomSite}
                            </td>
                          </tr>
                          <tr>
                            <td
                              rowSpan={2}
                              className="border w-400px text-left font-weight-bolder fs-15"
                              style={{ verticalAlign: "middle" }}
                            >
                              Prélèvement des mesures de l'infrastructure
                            </td>
                            <td
                              id="trep-code-infra"
                              className="border text-left fs-15 font-weight-bolder"
                            >
                              Kilométrage :
                            </td>
                            <td className="border text-left fs-15"></td>
                          </tr>
                          <tr>
                            <td
                              colSpan={1}
                              className="border text-left fs-15 font-weight-bolder"
                            >
                              Heures de fonctionnement :
                            </td>
                            <td className="border text-left fs-15"></td>
                          </tr>
                          <tr>
                            <td className="border font-weight-bolder text-left fs-15">
                              Demandeur
                            </td>
                            <td
                              colSpan={2}
                              id="trep-desc-supp"
                              className="border text-left fs-15"
                            >
                              {reqBy}
                            </td>
                          </tr>
                          <tr className="bg-light text-center">
                            <td
                              colSpan={3}
                              className="font-weight-bolder border fs-16"
                            >
                              Informations sur la plainte/panne
                            </td>
                          </tr>
                          <tr>
                            <td className="border w-400px h-110px text-left font-weight-bolder fs-15">
                              Description de la panne
                            </td>
                            <td
                              colSpan={2}
                              id="trep-code-infra"
                              className="border text-left fs-15"
                            >
                              <span className="font-weight-bolder">
                                {reqSubject} :
                              </span>
                              {reqDesc}
                            </td>
                          </tr>
                          <tr>
                            <td className="border w-400px h-110px text-left font-weight-bolder fs-15">
                              Description du travail à effectuer
                            </td>
                            <td
                              colSpan={2}
                              id="trep-code-infra"
                              className="border text-left fs-15"
                            >
                              {reqDiagnosis}
                            </td>
                          </tr>

                          <tr className="bg-light text-center fs-16">
                            <td
                              colSpan={3}
                              className="font-weight-bolder border"
                            >
                              Traitement de la panne
                            </td>
                          </tr>
                          <tr>
                            <td className="border font-weight-bolder text-left fs-15">
                              Durée estimée pour l'intervention
                            </td>
                            <td
                              colSpan={2}
                              id="trep-interventiondate"
                              className="border text-left fs-15"
                            >
                              {duration ? duration : null}
                            </td>
                          </tr>
                          <tr>
                            <td className="border font-weight-bolder text-left fs-15">
                              Date et heure de début de l'intervention
                            </td>
                            <td
                              colSpan={2}
                              id="trep-typeintervenant"
                              className="border text-left fs-15"
                            >
                              {reqStartDate}
                            </td>
                          </tr>
                          <tr>
                            <td className="border font-weight-bolder text-left fs-15">
                              Type d'intervenant (Interne/externe)
                            </td>
                            <td
                              colSpan={2}
                              id="trep-support"
                              className="border text-left fs-15"
                            >
                              {reqSupportType}
                            </td>
                          </tr>

                          <tr>
                            <td className="border font-weight-bolder text-left fs-15">
                              {reqSupportType === "External"
                                ? "nom du fournisseur"
                                : "Nom de l'intervenant"}
                            </td>
                            <td
                              colSpan={2}
                              id="trep-diagnosisdesc"
                              className="border text-left fs-15"
                            >
                              {reqSupportType === "Internal"
                                ? reqSupport
                                : external_supplier}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{ verticalAlign: "middle" }}
                              className="border h-110px font-weight-bolder text-left fs-15"
                            >
                              Description des travaux effectués
                            </td>
                            <td
                              colSpan={2}
                              id="trep-workdesc"
                              className="border text-left fs-15"
                            >
                              {reqWorkDone}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ verticalAlign: "middle" }}
                              className="border  font-weight-bolder border-bottom-0 text-left fs-15"
                            >
                              Pièces et Matériels utilisés
                            </td>
                            <td
                              colSpan={2}
                              id="trep-workdesc"
                              className=" text-left fs-15"
                            >
                              <pre className="border-0">{reqUseMaterials}</pre>
                            </td>
                          </tr>

                          {/* <tr>
                            <td className="border pt-5 font-weight-bolder text-left fs-15"></td>
                          </tr> */}

                          <tr className="bg-light text-center fs-16">
                            <td
                              colSpan={3}
                              className="font-weight-bolder border"
                            >
                              Cloture de l'intervention
                            </td>
                          </tr>
                          <tr>
                            <td className="border h-90px font-weight-bolder border-top-0 text-left fs-15">
                              Observations du technicien
                            </td>
                            <td
                              colSpan={2}
                              id="trep-diagnosisdesc"
                              className="border text-left fs-15"
                            >
                              {observation}
                            </td>
                          </tr>
                          <tr>
                            <td
                              rowSpan={2}
                              className="border font-weight-bolder text-left fs-15"
                              style={{ verticalAlign: "middle" }}
                            >
                              Intervenant/Technicien
                            </td>
                            <td
                              colSpan={2}
                              className="text-center border font-weight-bolder text-left fs-15"
                            >
                              Nom et Signature
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              className="h-90px border text-left fs-15"
                            ></td>
                          </tr>
                          <tr>
                            <td
                              rowSpan={2}
                              className="border font-weight-bolder text-left fs-15"
                              style={{ verticalAlign: "middle" }}
                            >
                              Utilisateur/Demandeur
                            </td>
                            <td
                              colSpan={2}
                              className="text-center border font-weight-bolder text-left fs-15"
                            >
                              Nom et Signature
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              className="h-90px border text-left fs-15"
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default List_maintenance;
