import React, { Component, useState, useEffect, useRef } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { useCookies } from "react-cookie";
import enFlag from "../assets/img/united-states.png";
import frFlag from "../assets/img/france.png";

const { setGlobalState, useGlobalState } = createGlobalState({
  showpassword: false,
  snackbarmsg: "",
  snackbartype: "success",
});

function Language() {
  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  if (cookies.i18next == "en") {
    return <img src={enFlag} width="20" height="20" />;
  } else {
    return <img src={frFlag} width="20" height="20" />;
  }
}

export default Language;
