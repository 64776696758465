//import logo from './logo.svg';
import "./App.css";
import "./assets/custom/css/style.bundle.css";
import "./assets/custom/plugins/global/plugins.bundle.css";
import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./components/Home";
import Login from "./components/Login";
import List_maintenance from "./components/List_maintenance";
import Follow_up from "./components/Follow-up";
import Sites from "./components/Sites";
import Equipment from "./components/Equipment";
import Rooms from "./components/Rooms";
import Support from "./components/Support";
import Users from "./components/Users";
import { ReactSession } from "react-client-session";
import ProtectedRoutes from "./protectedRoutes";
import { useCookies } from "react-cookie";

function App() {
  ReactSession.setStoreType("localStorage");

  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  const username = ReactSession.get("username");
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("Cache cleared");
  };

  useEffect(() => {
    if (cookies.i18next == null)
      setCookie("i18next", "fr", {
        expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
      });
    else
      setCookie("i18next", cookies.i18next, {
        expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
      });

    if (cookies.tkn === null || cookies.tkn === undefined) {
      setCookie("tkn", process.env.REACT_APP_TOKEN);
      clearCacheData();
    }
    if (cookies.tkn != process.env.REACT_APP_TOKEN) {
      setCookie("tkn", process.env.REACT_APP_TOKEN, {
        expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
      });
      clearCacheData();
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />

                <Route element={<ProtectedRoutes />}>
                  <Route exact path="/home" element={<Home />} />
                  <Route
                    exact
                    path="/maintenance"
                    element={<List_maintenance />}
                  />
                  <Route exact path="/follow-up" element={<Follow_up />} />
                  <Route exact path="/sites" element={<Sites />} />
                  <Route exact path="/equipment" element={<Equipment />} />
                  <Route exact path="/rooms" element={<Rooms />} />
                  <Route exact path="/support" element={<Support />} />
                  <Route exact path="/users" element={<Users />} />
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
