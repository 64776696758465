import React, { Component } from "react";
import { ReactSession } from 'react-client-session';

function Footer() {
    const username = ReactSession.get("username");

    if(username != undefined) {
        return(
            <div className="footer d-print-none bg-white py-4 d-flex flex-lg-column py-6" id="kt_footer">
                <div className=" container  d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-muted font-weight-bold mr-2">2022&copy;</span>
                        <a href={"/"} className="text-dark-75 text-hover-primary">Autolubumbashi</a> 
                    </div>

                    <div className="nav nav-dark order-1 order-md-2">
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default Footer;