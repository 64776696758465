import React, { useState, useEffect, useRef } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Footer from "./footer";
import Header from "./header";
import Header_mobile from "./header_mobile";
import base_url from "../constants/global";
import { createGlobalState } from "react-hooks-global-state";
import Snackbar from "./Snackbar";
import toast from "react-hot-toast";
import logo from "../assets/img/auto-logo.jpg";
import { useTranslation } from "react-i18next";

const { setGlobalState, useGlobalState } = createGlobalState({
  show: false,
  showview: false,
  showassign: false,
  showcancel: false,
  maintenancedata: [],
  snackbarmsg: "",
  snackbartype: "success",
  showsnackbar: false,

  reqRef: "",
  roomCode: "",
  duration: "",
  observation: "",
  formRef: "",
  reqDate: "",
  reqBy: "",
  roomNum: "",
  roomSite: "",
  reqDesc: "",
  reqPrio: "",
  reqStartDate: "",
  reqSupportType: "",
  reqSupport: "",
  reqDiagnosis: "",
  reqWorkToBeDone: "",
  reqEndDate: "",
  reqWorkDone: "",
  maintenanceForm: "",
  supportForm: "",
});

const notifySuccess = (msg) =>
  toast.success(msg, {
    style: {
      padding: "16px",
      backgroundColor: "rgba(124, 140, 135, 0.70)",
      color: "#FFF",
      maxWidth: "280px",
      textAlign: "left",
      fontSize: "13px",
    },
  });

const notifyError = (msg) =>
  toast.error(msg, {
    style: {
      padding: "16px",
      backgroundColor: "rgba(124, 140, 135, 0.70)",
      color: "#FFF",
      maxWidth: "280px",
      textAlign: "left",
      fontSize: "13px",
    },
  });

function Support() {
  const { t, i18n } = useTranslation();

  const [maintenances, setMaintenances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maintenancesPerPage, setMaintenancesPerPage] = useState(10);

  var userid = ReactSession.get("userid");
  var userrole = ReactSession.get("userrole");
  const usertoken = ReactSession.get("usertoken");

  const loadData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/tickets_support",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        if (response.data.error) return null;
        setMaintenances(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    loadData();
    setLoading(false);
  }, []);

  const indexOfLastPost = currentPage * maintenancesPerPage;
  const indexOfFirstPost = indexOfLastPost - maintenancesPerPage;
  const currentPosts = maintenances.slice(indexOfFirstPost, indexOfLastPost);

  const startPrinting = () => {
    setTimeout(() => {
      window.print();
    }, 100);
  };

  function ViewMaintenance(props) {
    ///

    const [permissions, setPermissions] = useState({
      permission: {
        per1: false,
        per2: false,
        per3: false,
        per4: false,
        per5: false,
        per6: false,
        per7: false,
        per8: false,
        per9: false,
        per10: false,
        per11: false,
        per12: false,
        per13: false,
        per14: false,
        per15: false,
        per16: false,
        per17: false,
        per18: false,
        per19: false,
        per20: false,
        per21: false,
        per22: false,
        per23: false,
        per24: false,
        per25: false,
        per26: false,
        per27: false,
        per28: false,
        per29: false,
        per30: false,
        per31: false,
        per32: false,
        per33: false,
        per34: false,
        per35: false,
        per36: false,
      },
    });

    const [permissiondata, setPermissiondata] = useState("");
    const userid = ReactSession.get("userid");
    const usertoken = ReactSession.get("usertoken");

    const loadPermissionData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/UserPermissions",
        data: { token: usertoken, userid: userid },
      })
        .then((response) => {
          setPermissiondata(response.data);

          var prevPerm = permissions;

          var json = response.data;

          Object.keys(json).forEach(function (key) {
            if (json[key].usrper_per_id == 1) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per1 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 2) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per2 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 3) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per3 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 4) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per4 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 5) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per5 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 6) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per6 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 7) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per7 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 8) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per8 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 9) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per9 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 10) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per10 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 11) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per11 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 12) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per12 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 13) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per13 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 14) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per14 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 15) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per15 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 16) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per16 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 17) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per17 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 18) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per18 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 19) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per19 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 20) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per20 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 21) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per21 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 22) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per22 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 23) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per23 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 24) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per24 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 25) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per25 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 26) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per26 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 27) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per27 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 28) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per28 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 29) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per29 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 30) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per30 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 31) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per31 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 32) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per32 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 33) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per33 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 34) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per34 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 35) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per35 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 36) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per36 = true;
                setPermissions(prevPerm);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const printRefname = useRef(null);

    const printMaintenance = () => {
      printRefname.current.click();

      setReqRef(props.info.msup_id);
      setRoomCode(props.info.room_id);
      SetDuration(
        props.info.msup_intervention_date - props.info.msup_expected_ended_date
      );
      setObservation(props.info.msup_remark);

      setFormRef(props.info.m_id);
      setReqDate(props.info.m_created_date);
      setReqBy(props.info.Created_by);
      setRoomNum(props.info.room_number);
      setRoomSite(props.info.site_name);
      setReqDesc(props.info.m_description);
      setReqPrio(props.info.mprio_description);

      setReqStartDate(props.info.msup_intervention_date);
      setReqSupportType(props.info.msup_support_type);
      setSupport(props.info.Assigned_to);
      setDiagnosis(props.info.msup_diagnosis_description);
      setWorkToBeDone(props.info.msup_work_to_be_done);
      setEndDate(props.info.msup_resolved_date);
      setWorkDone(props.info.msup_work_done);

      setSupportForm("print-container2");
      setMaintenanceForm("print-container");

      startPrinting();
    };

    const printSupport = () => {
      printRefname.current.click();

      setReqRef(props.info.msup_id);
      setRoomCode(props.info.room_id);
      SetDuration(
        Date(props.info.msup_intervention_date) -
          Date(props.info.msup_expected_ended_date)
      );
      setObservation(props.info.msup_remark);

      setRoomNum(props.info.room_number);

      setFormRef(props.info.m_id);
      setReqDate(props.info.m_created_date);
      setReqBy(props.info.Created_by);
      setReqDesc(props.info.m_description);
      setDiagnosis(props.info.msup_diagnosis_description);
      setReqSupportType(props.info.msup_support_type);
      setReqStartDate(props.info.msup_intervention_date);
      setSupport(props.info.Assigned_to);
      setWorkDone(props.info.msup_work_done);

      setRoomSite(props.info.site_name);
      setReqPrio(props.info.mprio_description);

      setWorkToBeDone(props.info.msup_work_to_be_done);
      setEndDate(props.info.msup_resolved_date);

      setSupportForm("print-container");
      setMaintenanceForm("print-container2");

      startPrinting();
    };

    const [reqRef, setReqRef] = useGlobalState("reqRef");
    const [roomCode, setRoomCode] = useGlobalState("roomCode");
    const [duration, SetDuration] = useGlobalState("duration");
    const [observation, setObservation] = useGlobalState("observation");

    const [formRef, setFormRef] = useGlobalState("formRef");
    const [reqDate, setReqDate] = useGlobalState("reqDate");
    const [reqBy, setReqBy] = useGlobalState("reqBy");
    const [roomNum, setRoomNum] = useGlobalState("roomNum");
    const [roomSite, setRoomSite] = useGlobalState("roomSite");
    const [reqDesc, setReqDesc] = useGlobalState("reqDesc");
    const [reqPrio, setReqPrio] = useGlobalState("reqPrio");

    const [reqStartDate, setReqStartDate] = useGlobalState("reqStartDate");
    const [reqSupportType, setReqSupportType] =
      useGlobalState("reqSupportType");
    const [reqSupport, setSupport] = useGlobalState("reqSupport");
    const [reqDiagnosis, setDiagnosis] = useGlobalState("reqDiagnosis");
    const [reqWorkToBeDone, setWorkToBeDone] =
      useGlobalState("reqWorkToBeDone");
    const [reqEndDate, setEndDate] = useGlobalState("reqEndDate");
    const [reqWorkDone, setWorkDone] = useGlobalState("reqWorkDone");

    const [maintenanceForm, setMaintenanceForm] =
      useGlobalState("maintenanceForm");
    const [supportForm, setSupportForm] = useGlobalState("supportForm");

    useEffect(() => {
      loadPermissionData();
    }, []);

    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("ListMaintenance.ViewMaintenance")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-4 border-right">
                <div
                  className="border-bottom fs-14 font-weight-bolder mb-5 pb-2 d-flex justify-content-between"
                  id="print-btn"
                >
                  <div className="pt-2">{t("Support.SUPPORTDETAILS")}</div>
                  {permissions.permission.per8 ? (
                    <button
                      onClick={printMaintenance}
                      id="printTicket"
                      className="btn btn-light border btn-sm font-weight-bold rounded-0"
                    >
                      <i aria-hidden="true" className="la la-print"></i> Print1
                    </button>
                  ) : null}
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.MAINTENANCEID")} :{" "}
                  </label>
                  <p id="ticketid-v" className="pl-2">
                    {props.info.m_id}
                  </p>
                  <input
                    type="text"
                    id="ticketid-print"
                    className="form-control"
                    hidden
                  />
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.SUBJECT")} :{" "}
                  </label>
                  <p id="ticketsubject-v" className="pb-2">
                    {props.info.m_subject}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.DESCRIPTION")} :{" "}
                  </label>
                  <p id="ticketdesc-v" className="pb-2">
                    {props.info.m_description}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.PRIORITY")} :{" "}
                  </label>
                  <p id="ticketpriority-v" className="pl-2">
                    {props.info.mprio_description}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.rooms")} :{" "}
                  </label>
                  <p id="equipementcode-v" className="pb-2">
                    {props.info.room_number}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.Sites")} :{" "}
                  </label>
                  <p id="equipementsite-v" className="pb-2">
                    {props.info.site_name}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.STATUS")} :{" "}
                  </label>
                  <p id="status-v" className="pl-2">
                    {props.info.mst_name}
                  </p>
                </div>

                <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.SENTDATE")}:{" "}
                  </label>
                  <p id="createddate-v" className="pb-2">
                    {props.info.m_created_date}
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-4 pt-2 text-center">
                  USER INFORMATIONS
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("ListMaintenance.SENTBY")}:{" "}
                  </label>
                  <p id="createdby-v" className="pl-2">
                    {props.info.Created_by}
                  </p>
                </div>

                <div className="form-group d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Users.Role")} :{" "}
                  </label>
                  <p id="role-v" className="pl-2">
                    {props.info.r_user_role}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 border-left">
                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-2 text-center d-flex justify-content-between">
                  <div className="pt-2 pb-2">{t("Support.SUPPORTDETAILS")}</div>
                  {permissions.permission.per9 ? (
                    <button
                      onClick={printSupport}
                      id="printTicket"
                      className="btn btn-light border btn-sm font-weight-bold rounded-0"
                    >
                      <i aria-hidden="true" className="la la-print"></i> Print2
                    </button>
                  ) : null}
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.AssignedTo")}:{" "}
                  </label>
                  <p id="assignedto-v" className="pl-2">
                    {props.info.Assigned_to}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.TypeOfSupport")}:{" "}
                  </label>
                  <p id="supportType-v" className="pl-2">
                    {props.info.msup_support_type}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.AssignedBy")} :{" "}
                  </label>
                  <p id="assignedby-v" className="pl-2">
                    {props.info.Assigned_by}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.AssigningTime")} :{" "}
                  </label>
                  <p id="assignationdate-v" className="pb-2">
                    {props.info.msup_assignation_date}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.StartTime")}:{" "}
                  </label>
                  <p id="assignationdate-v" className="pb-2">
                    {props.info.msup_intervention_date}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.ExpextedEndDate")} :{" "}
                  </label>
                  <p id="assignationdate-v" className="pb-2">
                    {props.info.msup_expected_ended_date}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.RealStartTime")}:{" "}
                  </label>
                  <p id="starteddate-v" className="pb-2">
                    {props.info.msup_real_started_time}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.DiagnosisDscription")}:{" "}
                  </label>
                  <p id="diagnosisdesc-v" className="pb-2">
                    {props.info.msup_diagnosis_description}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.DescriptionOfWorkToBeDone")}:{" "}
                  </label>
                  <p id="workdesc-v" className="pb-2">
                    {props.info.msup_work_to_be_done}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.DescriptionOfTheWorkDone")} :{" "}
                  </label>
                  <p id="workdesc-v" className="pb-2">
                    {props.info.msup_work_done}
                  </p>
                </div>

                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.ResolvedBy")} :{" "}
                  </label>
                  <p id="resolvedby-v" className="pl-2">
                    {props.info.Resolved_by}
                  </p>
                </div>

                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                  <label className="font-weight-bolder">
                    {t("Support.Observations")} :{" "}
                  </label>
                  <p id="ticketremark-v" className="pb-2">
                    {props.info.msup_remark}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={printRefname} onClick={props.onHide} variant="default">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function SignMaintenance(props) {
    const { t, i18n } = useTranslation();

    const myRefname = useRef(null);
    const myRefnameU = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///

    const [mid, setMid] = useState("");
    const [messageU, setMessageU] = useState("");

    useEffect(() => {
      setMid(props.info.m_id);
    }, []);

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitSignMaintenance = (e) => {
      e.preventDefault();

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/sign_maintenance",
        data: { token: usertoken, mid: mid },
      })
        .then((response) => {
          if (response.data.Inserted == "TRUE") {
            //setMessageU(response.data.Result);
            myRefnameU.current.click();
            //setSnackbarType("success");
            loadData();
            notifySuccess(response.data.Result);
          } else {
            //setSnackbarType("fail");
            //setMessageU(response.data.Result);
            notifyError(response.data.Result);
          }
          //setSnackbarMessage(response.data.Result);
          //setShowsnackbar(true);
          //setTimeout(() => {
          //    setShowsnackbar(false);
          //}, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Sign Maintenance
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitSignMaintenance}>
              <div className="form-group mb-4 mt-3">
                <div className="text-center font-weight-bold fs-16">
                  {t("Support.confirmSign")} {props.info.m_id} ?
                </div>
              </div>
              <button type="submit" ref={myRefname} hidden className="btn">
                {t("Support.Submit")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("Support.Cancel")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("Support.confimr")}{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function CloseMaintenance(props) {
    const { t, i18n } = useTranslation();

    const myRefnameU = useRef(null);
    const myRefname = useRef(null);

    const handleClick = () => {
      myRefname.current.click();
    };

    ///
    const [supportdata, setSupportData] = useState([]);

    const [realstarttime, setRealStartTime] = useState("");
    const [endtime, setEndTime] = useState("");
    const [cost, setCost] = useState("0");
    const [workdone, setWorkDone] = useState("");
    const [remark, setRemark] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [work, setWork] = useState("");
    const [messageStartTime, setMessageStartTime] = useState("");
    const [messageEndDate, setMessageEndDate] = useState("");
    const [messageCost, setMessageCost] = useState("");
    const [messageWorkDone, setMessageWorkDone] = useState("");
    const [messageRemark, setMessageRemark] = useState("");
    const [messageDiagnosis, setMessageDiagnosis] = useState("");
    const [messageWork, setMessageWork] = useState("");
    const [messageA, setMessageA] = useState("");

    const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
    const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
    const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

    const usertoken = ReactSession.get("usertoken");

    const handleSubmitCAMaintenance = (e) => {
      e.preventDefault();
      const userid = ReactSession.get("userid");

      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/close_maintenance",
        data: {
          token: usertoken,
          mid: props.info.m_id,
          userid: userid,
          realstarttime: realstarttime,
          enddate: endtime,
          cost: cost,
          workdone: workdone,
          remark: remark,
          diagnosis: diagnosis,
          work: work,
        },
      })
        .then((response) => {
          if (response.data.Validation == "TRUE") {
            setMessageStartTime("");
            setMessageEndDate("");
            setMessageWorkDone("");
            setMessageRemark("");
            setMessageCost("");
            setMessageDiagnosis("");
            setMessageWork("");
            myRefnameU.current.click();
            loadData();

            if (response.data.Inserted == "TRUE") {
              //setSnackbarType("success");
              notifySuccess(response.data.Result);
            } else {
              //setSnackbarType("fail");
              notifyError(response.data.Result);
            }

            //setSnackbarMessage(response.data.Result);
            //setShowsnackbar(true);
            //setTimeout(() => {
            //    setShowsnackbar(false);
            //}, 3000);
          } else {
            setMessageStartTime(response.data.real_start);
            setMessageEndDate(response.data.end_date);
            setMessageWorkDone(response.data.workdone);
            setMessageRemark(response.data.remark);
            setMessageCost(response.data.cost);
            setMessageDiagnosis(response.data.diagnosis);
            setMessageWork(response.data.worktobedone);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    ///

    useEffect(() => {
      setWork(props.info.msup_work_to_be_done);
      setDiagnosis(props.info.msup_diagnosis_description);
    }, []);

    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Support.CloseMaintenance")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitCAMaintenance}>
              <div className="form-group row pt-5">
                <div className="col-md-6">
                  <label className="font-weight-bolder">
                    {t("Support.InterventionRealStartedTime")}{" "}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={(e) => setRealStartTime(e.target.value)}
                    id="supp-startdate"
                    className="form-control rounded-0"
                  />
                  <div id="startdate-error" className="text-danger pt-2">
                    {messageStartTime}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="font-weight-bolder">
                    {t("Support.InterventionEndedTime")}{" "}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={(e) => setEndTime(e.target.value)}
                    id="supp-startdate_1"
                    className="form-control rounded-0"
                  />
                  <div id="supporttype-error" className="text-danger pt-2">
                    {messageEndDate}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("Support.InterventionCost")}
                    <span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    type="number"
                    min="0"
                    step={".01"}
                    value={"0"}
                    onChange={(e) => setCost(e.target.value)}
                    id="supp-startdate_1"
                    className="form-control rounded-0"
                  />
                  <div id="supporttype-error" className="text-danger pt-2">
                    {messageCost}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("Support.DescriptionOfTheWorkDone")}
                  </label>
                  <textarea
                    onChange={(e) => setWorkDone(e.target.value)}
                    id="diagnostic-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="diagnosis-error" className="text-danger pt-2">
                    {messageWorkDone}
                  </div>
                </div>

                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("Support.EnterRemark")}
                  </label>
                  <textarea
                    onChange={(e) => setRemark(e.target.value)}
                    id="task-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="task-error" className="text-danger pt-2">
                    {messageRemark}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-12">
                  <div className="bg-light fs-15 font-weight-bolder py-2 px-4">
                    {t("Support.RemarkWorkDone")}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("Support.DiagnosisDescription")}
                  </label>
                  <textarea
                    onChange={(e) => setDiagnosis(e.target.value)}
                    value={diagnosis}
                    id="diagnostic-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="diagnosis-error" className="text-danger pt-2">
                    {messageDiagnosis}
                  </div>
                </div>

                <div className="col-lg-6">
                  <label className="font-weight-bolder">
                    {t("Support.DescriptionOfWorkToBeDone")}
                  </label>
                  <textarea
                    onChange={(e) => setWork(e.target.value)}
                    value={work}
                    id="task-desc"
                    className="form-control rounded-0"
                    rows="3"
                  ></textarea>
                  <div id="task-error" className="text-danger pt-2">
                    {messageWork}
                  </div>
                </div>
              </div>

              <button type="submit" ref={myRefname} hidden className="btn">
                {t("Support.Submit")}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button ref={myRefnameU} onClick={props.onHide} variant="default">
              {t("Support.Cancel")}
            </Button>
            <Button type="submit" onClick={handleClick} variant="danger">
              {t("Support.CloseMaintenance")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const Maintenances = ({ data, loading }) => {
    const [showModal, setShowModal] = useGlobalState("show");
    const [showModalView, setShowModalView] = useGlobalState("showview");
    const [showModalSign, setShowModalSign] = useGlobalState("showcancel");
    const [showModalAssign, setShowModalAssign] = useGlobalState("showassign");
    const [getmaintenancedata, setMaintenanceData] =
      useGlobalState("maintenancedata");

    const handleViewModal = (d) => {
      setShowModalView(true);
      setMaintenanceData(d);
    };

    const handleAssignModal = (d) => {
      setShowModalAssign(true);
      setMaintenanceData(d);
    };

    const handleSignModal = (d) => {
      setShowModalSign(true);
      setMaintenanceData(d);
    };

    const [permissions, setPermissions] = useState({
      permission: {
        per1: false,
        per2: false,
        per3: false,
        per4: false,
        per5: false,
        per6: false,
        per7: false,
        per8: false,
        per9: false,
        per10: false,
        per11: false,
        per12: false,
        per13: false,
        per14: false,
        per15: false,
        per16: false,
        per17: false,
        per18: false,
        per19: false,
        per20: false,
        per21: false,
        per22: false,
        per23: false,
        per24: false,
        per25: false,
        per26: false,
        per27: false,
        per28: false,
        per29: false,
        per30: false,
        per31: false,
        per32: false,
        per33: false,
        per34: false,
        per35: false,
        per36: false,
      },
    });

    const [permissiondata, setPermissiondata] = useState("");
    const userid = ReactSession.get("userid");
    const usertoken = ReactSession.get("usertoken");

    const loadPermissionData = () => {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: base_url + "Users/UserPermissions",
        data: { token: usertoken, userid: userid },
      })
        .then((response) => {
          setPermissiondata(response.data);

          var prevPerm = permissions;

          var json = response.data;

          Object.keys(json).forEach(function (key) {
            if (json[key].usrper_per_id == 1) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per1 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 2) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per2 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 3) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per3 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 4) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per4 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 5) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per5 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 6) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per6 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 7) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per7 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 8) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per8 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 9) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per9 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 10) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per10 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 11) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per11 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 12) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per12 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 13) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per13 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 14) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per14 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 15) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per15 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 16) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per16 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 17) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per17 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 18) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per18 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 19) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per19 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 20) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per20 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 21) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per21 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 22) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per22 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 23) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per23 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 24) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per24 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 25) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per25 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 26) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per26 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 27) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per27 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 28) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per28 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 29) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per29 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 30) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per30 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 31) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per31 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 32) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per32 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 33) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per33 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 34) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per34 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 35) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per35 = true;
                setPermissions(prevPerm);
              }
            }

            if (json[key].usrper_per_id == 36) {
              if (json[key].usrper_val == 1) {
                prevPerm.permission.per36 = true;
                setPermissions(prevPerm);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      loadPermissionData();
    }, []);

    if (loading) {
      return <h2>Loading...</h2>;
    }

    return (
      <>
        <tbody id="resultdata">
          {currentPosts.length ? (
            currentPosts.map((d, index) => (
              <tr key={index} id='td"+ d[i].m_id +"' className="data">
                <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                <td>
                  <button
                    onClick={() => {
                      handleViewModal(d);
                    }}
                    className="btn pt-0 pb-0 w-15px mr-3 ml-1"
                  >
                    <i className="far fa-list-alt"></i>
                  </button>
                </td>
                <td id="tb-maintenanceid" className="text-left">
                  {d.m_id}
                </td>
                <td id="tb-subject" className="text-left">
                  {d.m_subject}
                </td>
                <td id="tb-description" className="text-left">
                  {d.m_description}
                </td>
                <td id="tb-priority" className="text-left">
                  {d.mprio_description}
                </td>
                <td id="tb-status" className="text-left">
                  {d.m_status == 1 || d.m_status == 4 ? (
                    <span className="available py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 2 || d.m_status == 5 ? (
                    <span className="on-job py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 3 || d.m_status == 7 ? (
                    <span className="done py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 6 ? (
                    <span className="assigned py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 8 ? (
                    <span className="canceled py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}

                  {d.m_status == 11 ? (
                    <span className="suspended py-1 px-2 text-white rounded">
                      {d.mst_name}
                    </span>
                  ) : null}
                </td>
                <td id="tb-furid" className="text-left">
                  {d.fur_id}
                </td>
                <td id="tb-sentby" className="text-left">
                  {d.Created_by}
                </td>
                <td id="tb-assigned-to" className="text-left">
                  {d.Assigned_to}
                </td>
                <td id="tb-created-date" className="text-left">
                  {d.m_created_date}
                </td>
                <td id="tb-created-by" className="text-left">
                  {d.msup_signature == 1 ? "YES" : "NO"}
                </td>
                <td id="tb-created-date" className="text-left">
                  <div className="d-flex">
                    {d.m_status != 3 && permissions.permission.per13 ? (
                      <button
                        onClick={() => {
                          handleAssignModal(d);
                        }}
                        className="btn btn-sm bg-red text-white"
                      >
                        Close
                      </button>
                    ) : null}

                    {d.m_status == 3 &&
                    d.msup_signature == 0 &&
                    permissions.permission.per24 ? (
                      <button
                        onClick={() => {
                          handleSignModal(d);
                        }}
                        className="btn btn-sm btn-info text-white"
                      >
                        Sign
                      </button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={13} className="text-center pt-20 fs-16">
                No data found
              </td>
            </tr>
          )}
        </tbody>

        {showModalSign ? (
          <SignMaintenance
            show={showModalSign}
            onHide={() => setShowModalSign(false)}
            info={getmaintenancedata}
          />
        ) : null}
        {showModalView ? (
          <ViewMaintenance
            show={showModalView}
            onHide={() => setShowModalView(false)}
            info={getmaintenancedata}
          />
        ) : null}
        {showModalAssign ? (
          <CloseMaintenance
            show={showModalAssign}
            onHide={() => setShowModalAssign(false)}
            info={getmaintenancedata}
          />
        ) : null}
      </>
    );
  };

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage == totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginate(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage == number ? (
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a onClick={() => paginate(number)} href="#" className="">
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a onClick={() => paginate(totalPages)} href="#" className="">
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //////////////////////

  const [snackbarmessage, setSnackbarMessage] = useGlobalState("snackbarmsg");
  const [snackbartype, setSnackbarType] = useGlobalState("snackbartype");
  const [showsnackbar, setShowsnackbar] = useGlobalState("showsnackbar");

  const snackbarRef = useRef(null);

  const [permissions, setPermissions] = useState({
    permission: {
      per1: false,
      per2: false,
      per3: false,
      per4: false,
      per5: false,
      per6: false,
      per7: false,
      per8: false,
      per9: false,
      per10: false,
      per11: false,
      per12: false,
      per13: false,
      per14: false,
      per15: false,
      per16: false,
      per17: false,
      per18: false,
      per19: false,
      per20: false,
      per21: false,
      per22: false,
      per23: false,
      per24: false,
      per25: false,
      per26: false,
      per27: false,
      per28: false,
      per29: false,
      per30: false,
      per31: false,
      per32: false,
      per33: false,
      per34: false,
      per35: false,
      per36: false,
    },
  });

  const [permissiondata, setPermissiondata] = useState("");

  const loadPermissionData = () => {
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: base_url + "Users/UserPermissions",
      data: { token: usertoken, userid: userid },
    })
      .then((response) => {
        setPermissiondata(response.data);

        var prevPerm = permissions;

        var json = response.data;

        Object.keys(json).forEach(function (key) {
          if (json[key].usrper_per_id == 1) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per1 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 2) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per2 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 3) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per3 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 4) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per4 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 5) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per5 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 6) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per6 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 7) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per7 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 8) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per8 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 9) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per9 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 10) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per10 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 11) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per11 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 12) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per12 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 13) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per13 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 14) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per14 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 15) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per15 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 16) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per16 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 17) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per17 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 18) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per18 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 19) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per19 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 20) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per20 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 21) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per21 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 22) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per22 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 23) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per23 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 24) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per24 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 25) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per25 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 26) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per26 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 27) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per27 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 28) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per28 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 29) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per29 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 30) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per30 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 31) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per31 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 32) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per32 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 33) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per33 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 34) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per34 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 35) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per35 = true;
              setPermissions(prevPerm);
            }
          }

          if (json[key].usrper_per_id == 36) {
            if (json[key].usrper_val == 1) {
              prevPerm.permission.per36 = true;
              setPermissions(prevPerm);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //setReqRef(props.info.msup_id);
  //setRoomCode(props.info.room_id);
  //SetDuration(props.info.msup_intervention_date - props.info.msup_expected_ended_date);
  //setObservation(props.info.msup_remark);

  const [reqRef, setReqRef] = useGlobalState("reqRef");
  const [roomCode, setRoomCode] = useGlobalState("roomCode");
  const [duration, SetDuration] = useGlobalState("duration");
  const [observation, setObservation] = useGlobalState("observation");

  const [formRef, setFormRef] = useGlobalState("formRef");
  const [reqDate, setReqDate] = useGlobalState("reqDate");
  const [reqBy, setReqBy] = useGlobalState("reqBy");
  const [roomNum, setRoomNum] = useGlobalState("roomNum");
  const [roomSite, setRoomSite] = useGlobalState("roomSite");
  const [reqDesc, setReqDesc] = useGlobalState("reqDesc");
  const [reqPrio, setReqPrio] = useGlobalState("reqPrio");
  const [reqStartDate, setReqStartDate] = useGlobalState("reqStartDate");
  const [reqSupportType, setReqSupportType] = useGlobalState("reqSupportType");
  const [reqSupport, setSupport] = useGlobalState("reqSupport");
  const [reqDiagnosis, setDiagnosis] = useGlobalState("reqDiagnosis");
  const [reqWorkToBeDone, setWorkToBeDone] = useGlobalState("reqWorkToBeDone");
  const [reqEndDate, setEndDate] = useGlobalState("reqEndDate");
  const [reqWorkDone, setWorkDone] = useGlobalState("reqWorkDone");

  const [maintenanceForm, setMaintenanceForm] =
    useGlobalState("maintenanceForm");
  const [supportForm, setSupportForm] = useGlobalState("supportForm");

  useEffect(() => {
    loadPermissionData();
    setSupportForm("print-container2");
    setMaintenanceForm("print-container2");
  }, []);

  return (
    <>
      <Header_mobile />
      <Header />

      <div
        className="d-flex flex-column flex-column-fluid"
        style={{ minHeight: "85vh" }}
      >
        {showsnackbar == true ? (
          <Snackbar
            ref={snackbarRef}
            message={snackbarmessage}
            type={snackbartype}
            show={showsnackbar}
          />
        ) : null}

        <div className="d-flex flex-column-fluid">
          <div className=" container" id="pageBlocking">
            <div className="d-print-none mt-20"></div>

            <div className="row">
              <div className="col-xl-12">
                <div
                  className="card card-custom d-print-none"
                  id="kt_blockui_card"
                >
                  <div className="card-header">
                    <div className="card-title">
                      <h3 className="card-label">
                        {t("Support.ListSupports")}
                      </h3>
                      <button
                        id="btn-refrech"
                        className="btn btn-sm bg-light2 ml-4 w-45px pl-4"
                      >
                        <i className="la la-sync fs-17"></i>
                      </button>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="dropdown dropdown-inline mr-4"
                        data-toggle="tooltip"
                      >
                        {permissions.permission.per33 ? (
                          <button
                            className="btn btn-light font-weight-bolder border"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {t("ListMaintenance.Export")}
                          </button>
                        ) : null}
                        <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right w-150px">
                          <ul className="navi navi-hover py-5">
                            <li className="navi-item">
                              <a
                                href="#"
                                id="export-excel"
                                className="navi-link"
                              >
                                <span className="navi-icon">
                                  <i className="la la-file-excel-o"></i>
                                </span>
                                <span className="navi-text">
                                  {t("ListMaintenance.Export")}
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <button
                        id="clearfilter"
                        className="btn btn-light font-weight-bolder border mr-3"
                      >
                        {t("ListMaintenance.Clear")}
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table id="result" className="table min-h-300px">
                        <thead>
                          <tr>
                            <th scope="col" className="w-25px">
                              #
                            </th>
                            <th scope="col" className="w-20px"></th>
                            <th scope="col" className="text-left">
                              {t("ListMaintenance.MAINTENANCEID")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketidModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("ListMaintenance.SUBJECT")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketsubjectModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("ListMaintenance.DESCRIPTION")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketdescModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("ListMaintenance.PRIORITY")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#priorityModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("ListMaintenance.STATUS")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#statusModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              FOLLOW UP ID
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#statusModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              {t("ListMaintenance.SENTBY")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filtercreatedModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>

                            <th scope="col" className="text-left">
                              {t("ListMaintenance.ASSIGNEDTO")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterassignedtoModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>

                            <th scope="col" className="text-left">
                              {t("ListMaintenance.ASSIGNEDDATE")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterdateModal"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="text-left">
                              SIGNATURE
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#filterdateModal1"
                              >
                                <i className="la la-filter"></i>
                              </a>
                            </th>
                            <th scope="col" className="w-25px">
                              {" "}
                            </th>
                          </tr>
                        </thead>

                        <Maintenances data={maintenances} loading={loading} />
                      </table>
                    </div>
                  </div>

                  <div className="card-footer pt-2 pb-2">
                    <div className="d-flex justify-content-center">
                      <Pagination
                        postsPerPage={maintenancesPerPage}
                        totalPosts={maintenances.length}
                        paginate={paginate}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10 d-print-none"></div>

                <div className="row">
                  <div id="printticketrep" className={maintenanceForm}>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          id=""
                          className="table"
                          style={{ width: "1200px" }}
                        >
                          <tbody id="" className="border">
                            <tr>
                              <td rowSpan={2} colSpan={2} className="border">
                                <div className="d-flex">
                                  <div className="w-150px">
                                    <img
                                      alt="Logo"
                                      src={logo}
                                      className="logo-default max-h-80px"
                                    />
                                  </div>
                                  <div className="text-center font-weight-bolder fs-24 pt-7 border-left-0 pl-10">
                                    Fiche de demande d'intervention curative
                                  </div>
                                </div>
                              </td>
                              <td className="text-center border fs-16">
                                Code : <span id="trep-code">FR14-S3</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center border fs-16">
                                Version : 001
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-right-0"></td>
                              <td className="border border-left-0 fs-16 text-right">
                                Référence de la fiche : <span></span>
                                <br />
                                <span className="text-danger fs-11">
                                  (à remplir par le département IT)
                                </span>
                              </td>
                              <td
                                id="trep-ref"
                                className="border border-left-0 text-center fs-16"
                                style={{ verticalAlign: "middle" }}
                              >
                                MC-{formRef}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-right-0"></td>
                              <td className="border border-left-0 fs-16 text-right">
                                Date et heure de la demande :
                              </td>
                              <td
                                id="trep-date"
                                className="border text-center fs-16"
                              >
                                {reqDate}
                              </td>
                            </tr>
                            <tr className="text-center bg-light border">
                              <td
                                colSpan={3}
                                className="font-weight-bolder border fs-16"
                              >
                                Informations sur la demande
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left w-400px fs-16">
                                Utilisateur/Demandeur
                              </td>
                              <td
                                colSpan={2}
                                id="trep-user"
                                className="border text-left fs-16"
                              >
                                {reqBy}
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-16">
                                Numéro de la chambre
                              </td>
                              <td
                                colSpan={2}
                                id="trep-eqcode"
                                className="border text-left fs-16"
                              >
                                {roomNum}
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-16">
                                Site de localisation de la chambre
                              </td>
                              <td
                                colSpan={2}
                                id="trep-eqsite"
                                className="border text-left fs-16"
                              >
                                {roomSite}
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-16">
                                Description de la demande/plainte
                              </td>
                              <td
                                colSpan={2}
                                id="trep-descplainte"
                                className="border text-left fs-16"
                              >
                                {reqDesc}
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-16">
                                Niveau de priorité de la demande
                              </td>
                              <td
                                colSpan={2}
                                id="trep-priority"
                                className="border text-left fs-16"
                              >
                                {reqPrio}
                              </td>
                            </tr>
                            <tr>
                              <td
                                rowSpan={2}
                                className="border text-left font-weight-bolder fs-16"
                                style={{ verticalAlign: "middle" }}
                              >
                                Signature
                              </td>
                              <td
                                colSpan={2}
                                className="border text-left font-weight-bolder fs-16"
                              >
                                Nom et Signature du demandeur :
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="h-110px border text-left fs-16"
                              ></td>
                            </tr>
                            <tr className="border">
                              <td
                                rowSpan={3}
                                className="border font-weight-bolder text-left fs-16"
                                style={{ verticalAlign: "middle" }}
                              >
                                Avis et commentaires du responsable IT
                              </td>
                              <td
                                colSpan={2}
                                className="border font-weight-bolder text-left fs-16"
                              >
                                Avis et commentaires :
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="h-90px border text-left fs-16"
                              ></td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="border font-weight-bolder text-left fs-16"
                              >
                                Date et signature :
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div id="printticketrep" className={supportForm}>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          id=""
                          className="table"
                          style={{ width: "1200px" }}
                        >
                          <tbody id="" className="border">
                            <tr>
                              <td rowSpan={3} colSpan={2} className="border">
                                <div className="d-flex">
                                  <div className="w-150px">
                                    <img
                                      alt="Logo"
                                      src={logo}
                                      className="logo-default max-h-80px"
                                    />
                                  </div>
                                  <div className="font-weight-bolder fs-24 pt-7 border-left-0">
                                    <div style={{ paddingLeft: "150px" }}>
                                      FICHE DE REALISATION DE LA MAINTENANCE
                                      CURATIVE
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center border w-300px fs-16">
                                Code :{" "}
                                <span id="trep-code-supp">Code: FR12-S2</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center border fs-16">
                                Version : 001
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center border fs-16">
                                Date de mise en application: 13 Sept 2021
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="border fs-16 text-right"
                              >
                                Référence de la fiche
                              </td>
                              <td
                                id="trep-ref-supp"
                                className="border text-center fs-16"
                                style={{ verticalAlign: "middle" }}
                              >
                                MC-{formRef}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="border fs-16 text-right"
                              >
                                Référence de la demande
                              </td>
                              <td
                                id="trep-ref-supp"
                                className="border text-center fs-16"
                                style={{ verticalAlign: "middle" }}
                              >
                                MC-{reqRef}
                              </td>
                            </tr>
                            <tr className="bg-light text-center">
                              <td
                                colSpan={3}
                                className="font-weight-bolder border fs-16"
                              >
                                Informations sur l'infrastructure
                              </td>
                            </tr>
                            <tr>
                              <td className="border w-400px text-left font-weight-bolder fs-15">
                                Code de l'infrastructure
                              </td>
                              <td
                                colSpan={2}
                                id="trep-code-infra"
                                className="border text-left fs-15"
                              >
                                {roomCode}
                              </td>
                            </tr>
                            <tr>
                              <td className="border w-400px text-left font-weight-bolder fs-15">
                                Désignation de l'infrastructure
                              </td>
                              <td
                                colSpan={2}
                                id="trep-code-infra"
                                className="border text-left fs-15"
                              >
                                {roomNum}
                              </td>
                            </tr>
                            <tr>
                              <td className="border w-400px text-left font-weight-bolder fs-15">
                                Site/lieu/bureau d'affectation de
                                l'infrastructure
                              </td>
                              <td
                                colSpan={2}
                                id="trep-code-infra"
                                className="border text-left fs-15"
                              >
                                {roomSite}
                              </td>
                            </tr>
                            <tr>
                              <td
                                rowSpan={2}
                                className="border w-400px text-left font-weight-bolder fs-15"
                                style={{ verticalAlign: "middle" }}
                              >
                                Prélèvement des mesures de l'infrastructure
                              </td>
                              <td
                                id="trep-code-infra"
                                className="border text-left fs-15 font-weight-bolder"
                              >
                                Kilométrage :
                              </td>
                              <td className="border text-left fs-15"></td>
                            </tr>
                            <tr>
                              <td
                                colSpan={1}
                                className="border text-left fs-15 font-weight-bolder"
                              >
                                Heures de fonctionnement :
                              </td>
                              <td className="border text-left fs-15"></td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-15">
                                Utilisateur/Demandeur
                              </td>
                              <td
                                colSpan={2}
                                id="trep-desc-supp"
                                className="border text-left fs-15"
                              >
                                {reqBy}
                              </td>
                            </tr>
                            <tr className="bg-light text-center">
                              <td
                                colSpan={3}
                                className="font-weight-bolder border fs-16"
                              >
                                Informations sur la plainte/panne
                              </td>
                            </tr>
                            <tr>
                              <td className="border w-400px h-110px text-center font-weight-bolder fs-15">
                                Description de la plainte/panne
                              </td>
                              <td
                                colSpan={2}
                                id="trep-code-infra"
                                className="border text-left fs-15"
                              >
                                {reqDesc}
                              </td>
                            </tr>
                            <tr>
                              <td className="border w-400px h-110px text-center font-weight-bolder fs-15">
                                Description du diagnostic
                              </td>
                              <td
                                colSpan={2}
                                id="trep-code-infra"
                                className="border text-left fs-15"
                              >
                                {reqDiagnosis}
                              </td>
                            </tr>

                            <tr className="bg-light text-center fs-16">
                              <td
                                colSpan={3}
                                className="font-weight-bolder border"
                              >
                                Traitement de la panne
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-15">
                                Durée estimée pour l'intervention
                              </td>
                              <td
                                colSpan={2}
                                id="trep-interventiondate"
                                className="border text-left fs-15"
                              >
                                {duration}
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-15">
                                Date et heure de début de l'intervention
                              </td>
                              <td
                                colSpan={2}
                                id="trep-typeintervenant"
                                className="border text-left fs-15"
                              >
                                {reqStartDate}
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-15">
                                Type d'intervenant (Interne/externe)
                              </td>
                              <td
                                colSpan={2}
                                id="trep-support"
                                className="border text-left fs-15"
                              >
                                {reqSupportType}
                              </td>
                            </tr>

                            <tr>
                              <td className="border font-weight-bolder text-left fs-15">
                                Nom de l'intervenant
                              </td>
                              <td
                                colSpan={2}
                                id="trep-diagnosisdesc"
                                className="border text-left fs-15"
                              >
                                {reqSupportType === "Internal"
                                  ? reqSupport
                                  : "Danny"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border font-weight-bolder text-left fs-15">
                                Référence des modes opératoires
                              </td>
                              <td
                                colSpan={2}
                                id="trep-diagnosisdesc"
                                className="border text-left fs-15"
                              ></td>
                            </tr>
                            <tr>
                              <td
                                style={{ verticalAlign: "middle" }}
                                className="border h-110px font-weight-bolder text-center fs-15"
                              >
                                Description des travaux à effectué
                              </td>
                              <td
                                colSpan={2}
                                id="trep-workdesc"
                                className="border text-left fs-15"
                              >
                                {reqWorkDone}
                              </td>
                            </tr>

                            <tr>
                              <td
                                rowSpan={5}
                                className="border font-weight-bolder text-center fs-15"
                                style={{ verticalAlign: "middle" }}
                              >
                                Pièces et Matériels utilisés
                              </td>
                            </tr>

                            <tr>
                              <td className="border pt-5 font-weight-bolder text-center fs-15 font-italic">
                                Référence
                              </td>
                              <td
                                colSpan={2}
                                id="trep-observations"
                                className="border pt-5 font-weight-bolder text-center fs-15 font-italic"
                              >
                                Quantité
                              </td>
                            </tr>
                            <tr>
                              <td className="border pt-5 font-weight-bolder text-left fs-15"></td>
                              <td
                                colSpan={2}
                                id="trep-observations"
                                className="border h-40px pt-5 font-weight-bolder text-left fs-15"
                              ></td>
                            </tr>
                            <tr>
                              <td className="border pt-5 font-weight-bolder text-left fs-15"></td>
                              <td
                                colSpan={2}
                                id="trep-observations"
                                className="border h-40px pt-5 font-weight-bolder text-left fs-15"
                              ></td>
                            </tr>
                            <tr>
                              <td className="border pt-5 font-weight-bolder text-left fs-15"></td>
                              <td
                                colSpan={2}
                                id="trep-observations"
                                className="border h-40px pt-5 font-weight-bolder text-left fs-15"
                              ></td>
                            </tr>
                            <tr className="bg-light text-center fs-16">
                              <td
                                colSpan={3}
                                className="font-weight-bolder border"
                              >
                                Cloture de l'intervention
                              </td>
                            </tr>
                            <tr>
                              <td className="border h-90px font-weight-bolder text-center fs-15">
                                Observations du technicien
                              </td>
                              <td
                                colSpan={2}
                                id="trep-diagnosisdesc"
                                className="border text-left fs-15"
                              >
                                {observation}
                              </td>
                            </tr>
                            <tr>
                              <td
                                rowSpan={2}
                                className="border font-weight-bolder text-center fs-15"
                                style={{ verticalAlign: "middle" }}
                              >
                                Intervenant/Technicien
                              </td>
                              <td
                                colSpan={2}
                                className="text-center border font-weight-bolder text-left fs-15"
                              >
                                Nom et Signature
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="h-90px border text-left fs-15"
                              ></td>
                            </tr>
                            <tr>
                              <td
                                rowSpan={2}
                                className="border font-weight-bolder text-center fs-15"
                                style={{ verticalAlign: "middle" }}
                              >
                                Utilisateur/Demandeur
                              </td>
                              <td
                                colSpan={2}
                                className="text-center border font-weight-bolder text-left fs-15"
                              >
                                Nom et Signature
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="h-90px border text-left fs-15"
                              ></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Support;
